import React, { useMemo, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
// import SignUp from "./Pages/SignUp/SignUp.jsx";
import Login from "./Pages/Login/Login.jsx";
import Confirmation from "./Pages/Confirmation/Confirmation.jsx";
import EmailVerification from "./Pages/EmailVerification/EmailVerification.jsx";
import authProtectedRoutes from "./Routes/routes";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword.jsx";
import ForgotPasswordConfirmation from "./Pages/ForgotPasswordConfirmation/ForgotPasswordConfirmation.jsx";
import CreateNewPassword from "./Pages/CreateNewPassword/CreateNewPassword.jsx";
import LoginBack from "./Pages/LoginBack/LoginBack.jsx";
import DashboardWrapper from "./Components/DashboardWrapper/DashboardWrapper.jsx";
import Onboarding from "./Pages/Onboarding/Onboarding.jsx";
import PaymentTesting from "./Pages/PaymentTesting/PaymentTesting.jsx";
import DeepFakes from "./Pages/DeepFakes/DeepFakes.jsx";
import { logoutAction } from "./Store/Auth/actions";
// import OnboardingNew from "./Pages/OnboardingNew/OnboardingNew.jsx";
import OnboardingNew from "./Pages/OnboardingNew/OnboardingNew.jsx";

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  // const handleBeforeUnload = (event) => {
  //   event.preventDefault();
  // };

  // const isTabSwitch = () =>  document.visibilityState === "visible" && !document.hasFocus();

  // const handleTabChange = () => {
  //   if (document.visibilityState === "visible") {
  //     if (isTabSwitch()) {
  //       const queryParams = new URLSearchParams(location.search);

  //       const userId = queryParams.get("userid");
  //       if (userId||localStorage.getItem('loti_user_id')) dispatch(logoutAction());
  //     } 
  //   } 
  // };

  useEffect(() => {
    // document.addEventListener("visibilitychange", handleTabChange);
    // window.addEventListener("beforeunload", handleBeforeUnload);

    // return () => {
    //   document.removeEventListener("visibilitychange", handleTabChange);
    //   window.removeEventListener("beforeunload", handleBeforeUnload);
    // };
  }, []);

  useEffect(() => {
    // if redirected and already token then logout from system
    const queryParams = new URLSearchParams(location.search);

    const userId = queryParams.get("userid");

    setTimeout(() => {
      if (userId && localStorage.getItem("token")) {
        dispatch(logoutAction());
      }
    }, 300000);
  }, []);
  const privateRoutes = useMemo(
    () =>
      authProtectedRoutes.map(
        ({ component: Component, path, noSideMenu, noMobileHead }, key) => (
          <Route
            path={path}
            key={key}
            element={
              !localStorage.getItem("token") ? (
                <Navigate to={{ pathname: "/login" }} />
              ) : (
                <DashboardWrapper
                  noSideMenu={noSideMenu}
                  noMobileHead={noMobileHead}
                >
                  <Component />
                </DashboardWrapper>
              )
            }
          />
        )
      ),
    []
  );

  if (
    window.location.href.includes("password-reset") &&
    localStorage.getItem("token")
  ) {
    localStorage.clear();
    window.location.reload();
  }
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      {localStorage.getItem("token") &&
      !new URLSearchParams(location.search).get("userid") ? (
        <Routes>
          {privateRoutes}
          <Route
            path="*"
            element={<Navigate to={{ pathname: "/uploaded-media" }} />}
          />
        </Routes>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* <Route path="/signup" element={<SignUp />} /> */}
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="/verify/:token" element={<EmailVerification />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/forgot-password/confirmation"
            element={<ForgotPasswordConfirmation />}
          />
          <Route
            path="/password-reset/:token"
            element={<CreateNewPassword />}
          />
          <Route path="/onboarding-new-mobile-ui" element={<Onboarding />} />
          <Route path="/onboarding" element={<OnboardingNew />} />

          
          <Route path="/login-back" element={<LoginBack />} />
          <Route path="/payment-testing" element={<PaymentTesting />} />
          <Route path="/deepfake/verified/:id" element={<DeepFakes />} />

          <Route path="*" element={<Navigate to={{ pathname: "/login" }} />} />

        </Routes>
      )}
    </div>
  );
};

export default App;
