/* eslint-disable */

import React, { useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import AlertCard from "../../Components/Alerts/AlertCard.jsx";
import MailIcon from "../../Assets/Images/alertMail_icon.png";
import { useDispatch, useSelector } from "react-redux";
import getAlerts from "../../Store/Alert/actions.js";
import CustomSpinner from "../../Components/UI/CustomSpinner.jsx";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  alertsContainer: {
    height: "100%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "10px",
    },
  },

  link: {
    textDecoration: "none",
    color: theme.palette.lightGreen,
  },
  noAlertsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    flexDirection: "column",
  },
  mailIcon: {
    height: "100px",
    width: "100px",
  },
  noAlertsHeading: {
    textAlign: "center",
    padding: "5px 20px 5px 20px",
  },
  noAlertsBody: {
    textAlign: "center",
    padding: "0 20px",
  },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  noSearchContianer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& h2": {
      ...theme.typography.heading36,
      fontWeight: 500,
      margin: 0,
      marginBottom: "20px",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
    },
  },
}));
const Alerts = () => {
  const { alerts, error, errorMessage, loading, dataLength } = useSelector(
    (state) => state.alerts
  );
  const myDivRef = useRef();
  let [page, setPage] = React.useState(1);


  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    alerts.length === 0 && dispatch(getAlerts(page));
  }, []);

  // function to handle   when scroll is at the bottom.
  const handleScroll = () => {
    if (dataLength !== 0) {
      if (myDivRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = myDivRef.current;

        if (
          scrollHeight - Math.round(scrollTop + clientHeight) === 0 ||
          scrollHeight - Math.round(scrollTop + clientHeight) === -1
        ) {
          // setCurrPage(currPage + 1);
          dispatch(getAlerts(page + 1));
          return setPage(page + 1);
        }
      }
    }
  };

  const getTimeAgoFuc = (time) => {
    var dateObj = new Date(time);
    const elapsedTime = formatDistanceToNow(dateObj, { addSuffix: true });
    return elapsedTime.replace("about", "");
  };
  return (
    <div
      className={classes.alertsContainer}
      onScroll={handleScroll}
      ref={myDivRef}
    >
      {!loading ? (
        error ? (
          <div className={classes.noSearchContianer}>
            <h2>{errorMessage}</h2>
          </div>
        ) : alerts.length > 0 ? (
          alerts?.map(
            (data, key) =>
              data?.results?.length > 0 ? (
                <Link
                  to="/search"
                  style={{ textDecoration: "none", color: "#000000" }}
                >
                  <AlertCard
                    key={key}
                    heading="Concerning Image Detected"
                    content={<div>We found <b>{data?.results?.length }</b>  concerning {data?.results?.length>1?'images':'image'} . Please click on this link  to see if {data?.results?.length>1?'these images need':'this image needs'}  to be removed.</div>}
                   
                    timeAgo={getTimeAgoFuc(data?.updatedAt)}
                    type="imageDetected"
                    />
                  {dataLength !== 0 && alerts.length>=10&&
                    (key === alerts.length - 1 ? "Loading..." : "")}
                </Link>
              ) : (
                <>
                  <AlertCard
                    key={key}
                    heading="Good News!"
                    content="We ran a search and found no matching images. We will update you again if we do."
                    timeAgo={getTimeAgoFuc(data?.updatedAt)}
                    type="noImageDetected"
                  />

                  {dataLength !== 0 && alerts.length>=10&&
                    (key === alerts.length - 1 ? <CustomSpinner /> : "")}
                </>
              )
            // "alerts length is more than 0"
          )
        ) : (
          <div className={classes.noAlertsContainer}>
            <img
              src={MailIcon}
              alt="mail_alert"
              className={classes.mailIcon}
              referrerPolicy="no-referrer"
            />
            <Typography variant="heading32" className={classes.noAlertsHeading}>
              Good news!
            </Typography>
            <Typography variant="body20" className={classes.noAlertsBody}>
              You have no alerts currently. We will email you if new images or
              videos appear online.
            </Typography>
          </div>

          // "alerts length =0"
        )
      ) : (
        <div className={classes.loaderContainer}>
          <CustomSpinner />
        </div>
      )}
    </div>
  );
};

export default Alerts;
