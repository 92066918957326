import { Alert, Box, Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledEngineProvider } from "@mui/material/styles";
import { isEmailValid } from "../../Helpers/functions";
import { onboardUserAction } from "../../Store/Auth/actions";
import {
  getDashBoardSearch,
  searchAssetAction,
  startProgressLoader,
  setProgressValue,
} from "../../Store/Matches/actions";
import { saveAssetAction } from "../../Store/Uploads/actions";
import CustomEmailInput from "../UI/CustomEmailInput.jsx";
import CustomSpinner from "../UI/CustomSpinner.jsx";
import CirculerProgressBar from "../UI/CirculerProgressBar.jsx";
import RadarGif from "../../Assets/Images/radar_gif.gif";
import placeholder from "../../Assets/Images/loti-placeholder.jpg";


const useStyles = makeStyles((theme) => ({
  headTitle: {
    ...theme.typography.heading28,
    margin: 0,
    marginBottom: "10px",
    // marginTop: "5%",
    textAlign: "center",
    width: "100%",
    fontFamily:
      "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji !important",
    [theme.breakpoints.down(1300)]: {
      fontSize: "23px",
      lineHeight: "30px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "21px",
    },
    [theme.breakpoints.down("md")]: {
      // marginTop: "20px",
      fontSize: "18px",
      lineHeight: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginBottom: "20px",
      fontSize: "27px",
      lineHeight: "30px",
    },
  },
  para: {
    ...theme.typography.body20,
    fontFamily:
      "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji !important",
    margin: 0,
    fontSize: "14px",
    textAlign: "center",
    marginBottom: "25px",
    color: "#737373",
    lineHeight: "20px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "11x",
      lineHeight: 1.3,
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginBottom: "15px 0px",
      fontSize: "14px",
      lineHeight: "18px",
    },
  },
  nextButton: {
    fontSize: "14px !important",
    marginTop: "0px !important",
    boxShadow: "none !important",
    color: "white !important",
    height: "40px !important",
    backgroundColor: "#528068 !important",
    width: "100% !important",
    borderRadius: "6px !important",
    fontWeight: "500 !important",
    textTransform: "none !important",
    "&:hover": {
      background: theme.palette.darkGreen,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "13px !important",

      height: "auto !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
      marginRight: "0px !important",
      // maxWidth: "240px !important",
      height: "40px !important",
    },
  },

  image: {
    maxWidth: "90%",
    maxHeight: "500px",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "5%",
    marginBottom: "50px",
    width: "100%",
    fontFamily:
      "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji !important",
    "& h2": {
      textAlign: "center",
      ...theme.typography.heading28,
      margin: 0,
      marginBottom: "10px",
      lineHeight: "34px",
      [theme.breakpoints.down("lg")]: {
        fontSize: "22px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "22px",
        lineHeight: "30px",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "10px",
        fontSize: "27px",
        // lineHeight: "26px",
      },
      // [theme.breakpoints.down(400)]: {
      //   marginBottom: "10px",
      //   fontSize: "27px",
      //   lineHeight: "25px",
      // },
    },
    "& h3": {
      textAlign: "center",
      ...theme.typography.body24,
      lineHeight: "22px",
      color: "#737373",
      fontSize: "16px",
      margin: 0,
      marginBottom: "5px",
      padding: "5px",
      [theme.breakpoints.down("lg")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "10px",
        fontSize: "16px",
        // lineHeight: "20px",
      },
    },
  },
  animatedImage: {
    animation: "$resize 2s infinite alternate",
    position: "absolute",
    // left: "28%",
    // right: "30%",
    // top: "28%",
    width: "40%",
    height: "40%",
  },
  "@keyframes resize": {
    "0%, 100%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(1.2)",
    },
  },
  label: {
    ...theme.typography.body14,
    // fontWeight: 500,
    // color: theme.palette.grey2,
    fontWeight: 500,
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
    "& span": {
      ...theme.typography.body16,
      color: theme.palette.grey2,
    },

    [theme.breakpoints.down("sm")]: {
      ...theme.typography.body18,
      fontSize: "15px",
      "& span": {
        fontSize: "12px",
      },
    },
  },
}));

const randomNumbers = () => {
  const num1 = Math.floor(Math.random() * 25) + 1;
  const num2 = Math.floor(Math.random() * 25) + 1;
  const num3 = Math.floor(Math.random() * 25) + 1;
  const num4 = 100 - num1 - num2 - num3;
  return [num2, num4, num1, num3];
};

const StepEmail = ({ data, faces, faceId, setProgress, handleInputChange }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { loading, error: apiError } = useSelector(
    (state) => state.authReducer
  );
  const { loading: matchLoading, progressLoader } = useSelector(
    (state) => state.matches
  );
  const { loading: uploadsLoading } = useSelector((state) => state.Uploads);
  const [text, setText] = useState("Searching the web...");
  const [error, setError] = useState("");
  const [live, setLive] = useState(false);
  const [onboardError, setOnboardError] = useState("");

  const triggerScript = () => {
    // Your script

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': "user_info",
      'user_data': {
        "email": data.email
          }
    })
    

  }    

  const setDefaultURL = (ev) => {
    // ev.currentTarget.src = faceSignedUrl;
    // ev.currentTarget.onError = null;
      ev.currentTarget.src = placeholder;
  };

  const randomeValues = randomNumbers();
  function updateProgress(progressData) {
    setLive(progressData);
  }
  useEffect(() => {
    if (progressLoader === false && live) {
      setProgress(live);
      setLive(false);
    }
  }, [progressLoader]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setText("Searching adult sites...");
    }, 4000);

    // Clear the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);

  const handleNext = () => {
    if (data.email && isEmailValid(data.email)) {
      triggerScript()              // script for user email
      setLive(false);
      dispatch(startProgressLoader());
      dispatch(setProgressValue(0));
      dispatch(
        onboardUserAction(
          data.email,
          (token) => {
            if (faces[faceId]) {
              dispatch(setProgressValue(randomeValues[0]));
              dispatch(
                saveAssetAction(
                  faces[faceId].asset_id,
                  true,
                  token,
                  () => {
                    dispatch(setProgressValue(randomeValues[1]));
                    dispatch(
                      searchAssetAction(
                        faces[faceId].asset_id,
                        () => {
                          dispatch(setProgressValue(randomeValues[2]));
                          dispatch(
                            getDashBoardSearch(true, token, (successData) => {
                              dispatch(setProgressValue(randomeValues[3]));
                              if (successData.exact_matches > 0) {
                                updateProgress((current) => ({
                                  ...current,
                                  key: 5,
                                  matchFound: true,
                                  needToShowGreatNews: false,
                                  exactMatchNumber: successData.exact_matches,
                                }));
                                setProgress((current) => ({
                                  ...current,
                                  exactMatchNumber: successData.exact_matches,
                                }));
                              } else if (
                                successData.exact_matches < 1 &&
                                successData.finalResultSorted.length < 1
                              ) {
                                updateProgress({
                                  key: 5,
                                  matchFound: false,
                                  noExactMatch: true,
                                  needToShowGreatNews: true,
                                  exactMatchNumber: successData.exact_matches,
                                });
                                setProgress((current) => ({
                                  ...current,
                                  exactMatchNumber: successData.exact_matches,
                                }));
                              } else {
                                updateProgress({
                                  key: 5,
                                  matchFound: true,
                                  noExactMatch: true,
                                  needToShowGreatNews: false,
                                  exactMatchNumber: successData.exact_matches,
                                });
                                setProgress((current) => ({
                                  ...current,
                                  exactMatchNumber: successData.exact_matches,
                                }));
                              }
                            })
                          );
                        },
                        () => {
                          setProgress((current) => ({ ...current, key: 4 }));
                        },
                        true,
                        token
                      )
                    );
                  }
                  // () => {
                  //
                  // }
                )
              );
            } else {
              setProgress((current) => ({
                ...current,
                key: 7,
                matchFound: false,
              }));
            }
          },
          (err) => setOnboardError(err)
        )
      );

      return;
    }
    setError("Please enter an email address.");
  };


  return (
    <Box
      sx={{
        width: {
          xs: "80%",
          sm: "45%",
          md: "38%",
          lg: "30%",
          xl: "30%",
        },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* (matchLoading || uploadsLoading || progressLoader) && !apiError */}
      {(matchLoading || uploadsLoading || progressLoader) && !apiError ? (
        <div className={classes.loadingContainer}>
          <h2
            style={{
              fontFamily:
                "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji",
            }}
          >
            We are searching the web for your image...
          </h2>
          <h3
            style={{
              fontFamily:
                "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji",
            }}
          >
            Please wait, while we scour the web for matches on your image...
          </h3>
          <div style={{ display: "none" }}>
            <StyledEngineProvider injectFirst>
              <CirculerProgressBar seconds={0} style={{ display: "none" }} />
            </StyledEngineProvider>
          </div>
          <Box
            sx={{
              background: "transparent",
              width: {
                xs: "100%",
                sm: "85%",
              },
              height: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: {
                xs: "",
                sm: "",
                md: "200px",
                lg: "250px",     // 220px
              },
              padding: "10px",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 3px",
              borderRadius: "12px",
              position: "relative",
              backgroundColor: "white",
            }}
          >
            <img
              src={RadarGif}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "8px",
                background: "#F5F5F5",
              }}
            />
            <div
              className={classes.animatedImage}
              style={{
                padding: "4px",
                background: "white",
                borderRadius: "12px",
              }}
             
            >
              <img
                src={faces[faceId]?.face_url}
                alt="Uploaded Image"
                style={{ width: "100%", height: "100%", borderRadius: "8px" }}
                 onError={setDefaultURL}
              />
            </div>
            <Box
              className={classes.para}
              sx={{
                position: "absolute",
                bottom: {
                  xs: "0px",
                  sm: "-10px",
                  md: "0px",
                },
                // left: {
                //   xs:"30%",
                //   md:"30%",
                //   lg:"25%"
                // },
                textAlign: "center",
              }}
            >
              {text}
            </Box>
          </Box>
        </div>
      ) : (
        <>
          <Grid
            item
            sm={6}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h2
              className={clsx(
                classes.headTitle,
                faces[faceId] ? "" : classes.textCenter
              )}
              style={{
                fontFamily:
                  "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji",
              }}
            >
              Before we secure your identity, we need your email address.
            </h2>
            <p
              className={clsx(
                classes.para,
                faces[faceId] ? "" : classes.textCenter
              )}
            >
              We need this so we can send you future matches that are picked up
              by Loti.
            </p>
            <Box
              sx={{
                background: "white",
                padding: "16px",
                borderRadius: "12px",
                width: "90%",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 3px",
              }}
            >
              {onboardError && <Alert severity="error">{onboardError}</Alert>}
              <label className={classes.label}>Email address</label>

              <CustomEmailInput
                type="text"
                placeholder="Email"
                error={Boolean(error)}
                helperText={error}
                name="email"
                value={data.email}
                onChange={handleInputChange}
              />

              {loading ? (
                <CustomSpinner center />
              ) : (
                <Button
                  variant="contained"
                  className={classes.nextButton}
                  onClick={() => handleNext()}
                  style={{ marginTop: "0px", color: "white" }}
                >
                  Start my scan
                </Button>
              )}
            </Box>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default StepEmail;
