import { makeStyles } from "@mui/styles";
import { MdOutlineLanguage, MdAdd } from "react-icons/md";
import React, { useState } from "react";
// import Tooltip from "@mui/material/Tooltip";
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import DeleteConfirmationDialog from "../Common/ConfirmationDialog.jsx";
import CustomButton from "../UI/CustomButton.jsx";
import placeholder from "../../Assets/Images/loti-placeholder.jpg";

const useStyles = makeStyles((theme) => ({
  searchCardContainer: {
    background: theme.palette.white,
    boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    padding: "14px 20px",
  },
  headContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  head: {
    ...theme.typography.heading18,
    fontWeight: 700,
    margin: 0,
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
  },
  body: {
    position: "relative",
  },
  helpIcon: {
    fontSize: "20px",
    color: theme.palette.lightGreen,
  },
  moreIcon: {
    fontSize: "30px",
    color: theme.palette.black,
  },
  userImg: {
    height: "300px",
    [theme.breakpoints.up(1700)]: {
      height: "400px",
    },
    width: "100%",
    objectFit: "cover",
    objectPosition: "top",
  },
  websiteContainer: {
    position: "absolute",
    bottom: "0px",
    padding: "10px",
    width: "-webkit-fill-available",
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)",
    display: "flex",
    justifyContent: "space-between",  // Added to distribute contents horizontally
    alignItems: "center",
  },
  website: {
    ...theme.typography.body16,
    color: theme.palette.white,
    cursor: "pointer",
  },
  sourceType: {
    ...theme.typography.body16,
    color: theme.palette.white,
    marginLeft: "10px",  // Added to create spacing between website and sourceType
  },
  globe: {
    marginRight: "3px",
    fontSize: "20px ",
    verticalAlign: "bottom",
  },
  buttonsContainer: {
    marginTop: "20px",
    "& button": {
      width: "100%",
      background: theme.palette.white,
      color: theme.palette.black,
      textTransform: "none",
      padding: "12px",
      border: `1px solid ${theme.palette.grey3}`,
      boxShadow: "none",
      "&:hover": {
        background: theme.palette.white,
      },
    },
  },
  btnRow1: {
    display: "flex",
    marginBottom: "10px",
    "& button:first-child": {
      marginRight: "10px",
    },
  },
  selectedCard: {
    border: `3px solid ${theme.palette.lightGreen}`,
  },
  card: {
    border: `3px solid ${theme.palette.white}`,
  },
  checkbox: {
    color: theme.palette.lightGreen,
    "& .MuiSvgIcon-root": {
      fontSize: "1.8rem",
      color: theme.palette.yellow, 
    },
    "&.Mui-checked": {
      color: theme.palette.yellow, 
    },
  },
}));

const SearchCard = ({
  image,
  faceSignedUrl,
  website,
  openModal,
  distance,
  handleNotMe,
  onSave,
  sourceType,
  isSelecting,
  setIsSelecting,
  onSelect,
  isSelected,
}) => {
  const classes = useStyles();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [totalImageRetries, settotalImageRetries] = useState(0);

  const toggleConfirmation = () => {
    setShowConfirmation((current) => !current);
  };

  const handleDeleteConfirmation = () => {
    toggleConfirmation();
    handleNotMe();
  };

  const setDefaultURL = (ev) => {
    if (totalImageRetries === 0) {
      ev.currentTarget.src = faceSignedUrl;
      ev.currentTarget.onError = null;
      settotalImageRetries(1);
    }
    else ev.currentTarget.src = placeholder;
  };

  const getMatchType = () => {
    if (distance <= 100) return "100% confident";
    if (distance <= 200) return "95% confident";
    if (distance <= 300) return "90% confident";
    if (distance <= 400) return "80% confident";
    if (distance <= 500) return "75% confident";
    if (distance <= 600) return "70% confident";
    if (distance <= 700) return "65% confident";

    return "60% confident";
  };


  const handleCheckboxChange = () => {
    onSelect();
  };

  function handleCardClick() {
    if (!isSelecting) {
      setIsSelecting(true)
    }
    onSelect();
  }

  return (

    <div className={`${classes.searchCardContainer} ${isSelected ? classes.selectedCard : classes.card
      }`}
    >
      <DeleteConfirmationDialog
        open={showConfirmation}
        onClose={toggleConfirmation}
        handleConfirmation={handleDeleteConfirmation}
        heading="Are you sure this isn't you ?"
        body="We will not show you this result again."
        confirmButtonName="Yes"
        cancelButtonName="No"
      />
      <div className={classes.headContainer}
        onClick={handleCardClick}
      >
        <h3 className={classes.head}>{getMatchType()}</h3>
        <div className={classes.head}>
          <Checkbox
            onClick={handleCardClick}
            checked={isSelected}
            onChange={handleCheckboxChange}
            className={classes.checkbox}
          />
        </div>
      </div>
      <div className={classes.body}
        onClick={handleCardClick}
      >
        <img
          referrerPolicy="no-referrer"
          src={image || placeholder}
          alt="user-img"
          className={classes.userImg}
          onError={setDefaultURL}
          loading="lazy"
        />
        <div className={classes.websiteContainer}>
          <div className={classes.website} onClick={() => openModal()}>
            <MdOutlineLanguage className={classes.globe} /> {website}
          </div>
          <div className={classes.sourceType}>
            {sourceType}
          </div>
        </div>
      </div>

      {isSelecting ? null : (<div className={classes.buttonsContainer}>
        <div className={classes.btnRow1}>
          <CustomButton yellow onClick={onSave}>
            <MdAdd />
            Save
          </CustomButton>
          <Button variant="contained" onClick={toggleConfirmation}>
            Not me
          </Button>
        </div>
      </div>
      )}

      {/* {isSelecting ? (
        <Checkbox
          onClick={handleCardClick}
          checked={isSelected}
          onChange={handleCheckboxChange}
          className={classes.checkbox}
        />
      ) : null} */}
    </div>
  );
};

export default SearchCard;
