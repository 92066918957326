import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { addOwnUrlsAction, getOwnUrlsAction } from "../../Store/OwnUrl/actions";
import AddNewUrlCard from "../../Components/AddOwnUrl/AddNewUrlCard.jsx";
import AddOwnUrlCard from "../../Components/AddOwnUrl/AddOwnUrlCard.jsx";

import CustomPopup from "../../Components/UI/CustomPopup.jsx";
import CustomButton from "../../Components/UI/CustomButton.jsx";
import CustomFormInput from "../../Components/UI/CustomFormInput.jsx";
import CustomSpinner from "../../Components/UI/CustomSpinner.jsx";
import WebsiteDialog from "../../Components/Common/WebsiteDialog.jsx";
import { validateURL } from "../../Helpers/functions";
import CheckIcon from "../../Assets/Images/bi_check_icon.png";

const useStyles = makeStyles((theme) => ({
  addYourUrlContainer: {
    height: "100%",
  },
  bodyContainer: {
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },

  gridWrapper: {
    height: "100%",
    width: "100%",
  },
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  formPopupContainer: {
    "& .MuiPaper-root": {
      position: "relative",
      width: "100% ",
      padding: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiPaper-root": {
        margin: "10px",
      },
      "& .MuiBackdrop-root": {
        backdropFilter: "blur(45px)",
      },
    },
  },
  headingContainer: {
    marginBottom: "20px",
    borderBottom: `1px solid ${theme.palette.grey4}`,
    display: "flex",
    "& h2": {
      marginBottom: "10px",
      margin: 0,
      ...theme.typography.heading20,
      fontWeight: 500,
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 15px",
    },
  },
  buttonContainer: {
    marginTop: "20px",
  },
  errorContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& h2": {
      ...theme.typography.heading32,
    },
  },

  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  },
  submitContainer: {
    padding: "5px 30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& h2": {
      margin: 0,
      marginBottom: "13px",
      ...theme.typography.heading24,
      fontWeight: 700,
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },

    "& p": {
      margin: 0,
      marginBottom: "25px",
      ...theme.typography.body18,
      lineHeight: "34px",
      maxWidth: "420px",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
    "& button": {
      maxWidth: "250px !important",
      fontSize: "18px !important",
      fontWeight: "400 !important",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: "40px 5px",
    },
  },
  modalContainer: {
    "& .MuiPaper-root": {
      padding: "30px 20px",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiPaper-root": {
        padding: "20px 10px",
        margin: "10px",
      },
    },
  },
}));

const AddYourOwnUrl = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { ownUrls, loading, error, errorMessage } = useSelector(
    (state) => state.Ownurls
  );

  const [showAddUrlModal, setShowAddUrlModal] = useState(false);
  const [websiteModal, setwebsiteModal] = useState(false);
  const initialState = {
    imageUrl: "",
    pageUrl: "",
    domain: "",
    videoUrl: "",
  };
  const [data, setData] = useState(initialState);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e, name) => {
    if (name === "pageUrl") {
      const validate = validateURL(e.target.value);

      if (validate) {
        const url = new URL(e.target.value);
        setData((current) => ({
          ...current,
          [e.target.name]: e.target.value,
          domain: url.hostname,
        }));
      } else {
        setData((current) => ({
          ...current,
          [e.target.name]: e.target.value,
        }));
      }
    } else {
      setData((current) => ({
        ...current,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const onSubmit = () => {
    dispatch(
      addOwnUrlsAction({
        domain: data.domain,
        page_url: data.pageUrl,
        image_url: data.imageUrl,
        video_url: data.videoUrl,
      })
    );
    setShowAddUrlModal(false);
    setData({ imageUrl: "", pageUrl: "", domain: "", videoUrl: "" });
  };

  const handleAddUrl = () => {
    setShowAddUrlModal(true);
  };

  const handleClose = () => {
    setShowAddUrlModal(false);
    setData(initialState); // for cleaning the form feilds on closing the form
  };

  const openWebsiteModal = (e) => {
    setwebsiteModal(e);
  };
  const closeWebsiteModal = () => {
    setwebsiteModal(false);
  };

  useEffect(() => {
    dispatch(getOwnUrlsAction());
  }, [dispatch]);

  return (
    <div className={classes.addYourUrlContainer}>
      <WebsiteDialog open={websiteModal} onClose={closeWebsiteModal} />
      <FormPopup
        open={showAddUrlModal}
        onClose={handleClose}
        data={data}
        handleChange={handleChange}
        onSubmit={onSubmit}
      />
      <CustomPopup
        open={isSubmit}
        onClose={() => setIsSubmit(false)}
        className={classes.modalContainer}
        noPadding
      >
        {loading ? (
          <CustomSpinner />
        ) : (
          <>
            <div className={classes.submitContainer}>
              {error ? (
                <p> {errorMessage}</p>
              ) : (
                <>
                  <img
                    src={CheckIcon}
                    alt="checkIcon"
                    referrerPolicy="no-referrer"
                    className={classes.checkIcon}
                  />
                  <h2>Your request has been submitted.</h2>
                  <p>
                    Please give 7-14 days to process. If any additional
                    information is needed you will receive a message in the DMCA
                    Inbox.
                  </p>
                </>
              )}

              <CustomButton yellow onClick={() => setIsSubmit(false)}>
                {error ? "Close" : "Thank you"}
              </CustomButton>
            </div>
          </>
        )}
      </CustomPopup>
      {loading ? (
        <div className={classes.loader}>
          <CustomSpinner />
        </div>
      ) : (
        <div className={classes.gridWrapper}>
          {error && (
            <div className={classes.errorContainer}>
              <h2>{errorMessage}</h2>
            </div>
          )}
          <Grid container spacing={2} className={classes.bodyContainer}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <AddNewUrlCard handleAddUrl={handleAddUrl} />
            </Grid>
            {ownUrls.map((url, key) => (
              <Grid item xs={12} sm={6} md={3} lg={3} key={key}>
                <AddOwnUrlCard
                  image={url.image_url}
                  domainData={url.domain}
                  status={url.status}
                  takedownStatus={url.takedown_status}
                  id={url._id}
                  handleWebsiteOpen={() => openWebsiteModal(url)}
                  getOwnUrlsAction={getOwnUrlsAction}
                  isSubmit={isSubmit}
                  setIsSubmit={setIsSubmit}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </div>
  );
};

const FormPopup = ({ open, onClose, data, handleChange, onSubmit }) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({
    domain: "",
    pageUrl: "",
    imageUrl: "",
    videoUrl: "",
  });

  const inputs = [
    {
      name: "pageUrl",
      label: "Page Url",
      value: data.pageUrl,
      error: Boolean(errors.pageUrl),
      helperText: errors.pageUrl,
    },
    {
      name: "domain",
      label: "Domain",
      value: data.domain,
      error: Boolean(errors.domain),
      helperText: errors.domain,
    },

    {
      name: "imageUrl",
      label: "Image Url",
      value: data.imageUrl,
      optional: true,
      error: Boolean(errors.imageUrl),
      helperText: errors.imageUrl,
    },

    {
      name: "videoUrl",
      label: "Video Url",
      value: data.videoUrl,
      optional: true,
      error: Boolean(errors.videoUrl),
      helperText: errors.videoUrl,
    },
  ];

  const handleSubmit = () => {
    const isValid = () => {
      let valid = true;
      const err = { domain: "", pageUrl: "" };

      if (!data.domain) {
        valid = false;
        err.domain = "Domain is required.";
      }
      if (!data.pageUrl) {
        valid = false;
        err.pageUrl = "Page Url is required.";
      }

      Object.keys(data).forEach((key) => {
        if (key !== "domain")
          if (data[key] !== "" && !validateURL(data[key])) {
            valid = false;
            err[key] = "Please Enter Proper URL. (Ex: https://example.com)";
          }
      });

      setErrors(err);
      return valid;
    };

    if (isValid()) {
      onSubmit();
    }
  };

  return (
    <CustomPopup
      open={Boolean(open)}
      onClose={onClose}
      className={classes.formPopupContainer}
    >
      <AiOutlineCloseSquare
        size={30}
        className={classes.closeButton}
        onClick={onClose}
      />
      <div className={classes.headingContainer}>
        <h2>Add Url</h2>
      </div>
      <div className={classes.inputContainer}>
        {inputs.map((input, key) => (
          <CustomFormInput
            onChange={(e) => handleChange(e, input.name)}
            type="text"
            // handleWebsiteOpen={()=>}
            {...input}
            full
            key={key}
          />
        ))}
      </div>
      <Grid container className={classes.buttonContainer}>
        <Grid item xs={4} sm={4} md={3} lg={3}>
          <CustomButton yellowFilled onClick={handleSubmit}>
            Submit
          </CustomButton>
        </Grid>
      </Grid>
    </CustomPopup>
  );
};

export default AddYourOwnUrl;
