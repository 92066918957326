import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";
import { Grid } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomButton from "../UI/CustomButton.jsx";

const useStyles = makeStyles((theme) => ({
  step1Container: {
    width: "34%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    [theme.breakpoints.down("lg")]: {
      width: "40%",
    },
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    "& h2": {
      textAlign: "center",
      ...theme.typography.heading28,
      margin: 0,
      marginBottom: "15px",
      [theme.breakpoints.down(1300)]: {
        fontSize: "23px",
        lineHeight: "30px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "23px",
        lineHeight: "1.2",
        textAlign: "center",
        marginBottom: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "27px",
        lineHeight: "1.2",
        textAlign: "center",
        marginBottom: "30px",
      },
    },
    "& p": {
      textAlign: "center",
      // ...theme.typography.body24,
      // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "20px",
      color: "#737373",
      margin: 0,
      marginBottom: "10px",
      [theme.breakpoints.down(1300)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "14px",
        lineHeight: "20px",
        textAlign: "center",
        marginBottom: "20px",
      },

      [theme.breakpoints.down("sm")]: {
        marginBottom: "15px",
        fontSize: "14px",
        lineHeight: "24px",
      },
    },
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    gap: 10,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  yesButton: {
    fontSize: "14px !important",
    fontFamily:
      "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji !important",
    marginTop: "20px !important",
    color: "white !important",
    height: "40px !important",
    backgroundColor: "#528068 !important",
    width: "100% !important",
    borderRadius: "6px !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    fontWeight: "500 !important",
    "&:hover": {
      background: theme.palette.darkGreen,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "13px !important",

      height: "auto !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
      marginRight: "0px !important",
      // maxWidth: "240px !important",
      height: "40px !important",
    },
  },
  noButton: {
    fontSize: "14px !important",
    width: "100% ",
    fontFamily:
    "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    fontWeight: "500 !important",
    [theme.breakpoints.down("md")]: {
      fontSize: "13px !important",

      height: "auto !important",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px !important",
      fontSize: "16px !important",
      marginLeft: "0px !important",
      height: "40px !important",
    },
  },

  weight400: {
    fontWeight: "400 !important",
  },
}));

const NCMEC = ({
  onClickBack,
  onClickYes,
  heading,
  subText,
  inverseButton,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classes.step1Container}
      style={{
        fontFamily:
          "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji",
      }}
    >
      <Grid
        container
        flexDirection="column"
        // justifyContent="space-between"
        alignItems="center"
        marginBottom="30px"
      >
        <img
          src="./logo.svg"
          style={{ marginBottom: 30, height: "22px", width: "45px" }}
        />
        <svg
          width="81"
          height="6"
          viewBox="0 0 81 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M79.5 0C77.3975 0 76.3291 1.31348 75.4707 2.36914C74.623 3.41064 74.0898 4 72.9922 4C71.8945 4 71.3623 3.41064 70.5146 2.36914C69.6563 1.31348 68.5879 0 66.4854 0C64.3838 0 63.3154 1.31396 62.457 2.36963C61.6113 3.41064 61.0781 4 59.9824 4C58.8877 4 58.3555 3.41064 57.5098 2.36963C56.6523 1.31396 55.585 0 53.4844 0C51.3838 0 50.3164 1.31348 49.458 2.36914C48.6123 3.41064 48.0801 4 46.9834 4C45.8887 4 45.3564 3.41113 44.5107 2.36963C43.6533 1.31396 42.5859 0 40.4844 0C38.3838 0 37.3164 1.31348 36.459 2.36914C35.6133 3.41064 35.0811 4 33.9854 4C32.8906 4 32.3584 3.41113 31.5127 2.36963C30.6553 1.31396 29.5879 0 27.4863 0C25.3857 0 24.3193 1.31396 23.4619 2.36963C22.6162 3.41064 22.085 4 20.9902 4C19.8965 4 19.3643 3.41064 18.5195 2.36963C17.6631 1.31396 16.5967 0 14.4971 0C12.3955 0 11.3281 1.31396 10.4707 2.36963C9.625 3.41113 9.09277 4 7.99805 4C6.90332 4 6.37109 3.41064 5.52539 2.36963C4.66797 1.31396 3.60059 0 1.5 0C0.947266 0 0.5 0.447754 0.5 1C0.5 1.55225 0.947266 2 1.5 2C2.59473 2 3.12695 2.58936 3.97266 3.63037C4.83008 4.68604 5.89746 6 7.99805 6C10.0986 6 11.166 4.68652 12.0234 3.63086C12.8691 2.58936 13.4014 2 14.4971 2C15.5908 2 16.1221 2.58887 16.9668 3.62988C17.8232 4.68604 18.8906 6 20.9902 6C23.0908 6 24.1572 4.68604 25.0146 3.63037C25.8604 2.58936 26.3916 2 27.4863 2C28.582 2 29.1143 2.58936 29.96 3.63086C30.8174 4.68652 31.8848 6 33.9854 6C36.0869 6 37.1543 4.68604 38.0117 3.63037C38.8574 2.58887 39.3896 2 40.4844 2C41.5801 2 42.1123 2.58936 42.958 3.63086C43.8154 4.68652 44.8828 6 46.9834 6C49.085 6 50.1523 4.68604 51.0107 3.63037C51.8564 2.58936 52.3887 2 53.4844 2C54.5791 2 55.1113 2.58936 55.957 3.63037C56.8145 4.68604 57.8818 6 59.9824 6C62.084 6 63.1514 4.68652 64.0098 3.63086C64.8564 2.58936 65.3887 2 66.4854 2C67.583 2 68.1162 2.58936 68.9639 3.63135C69.8223 4.68652 70.8906 6 72.9922 6C75.0947 6 76.1631 4.68652 77.0215 3.63086C77.8691 2.58936 78.4023 2 79.5 2C80.0527 2 80.5 1.55225 80.5 1C80.5 0.447754 80.0527 0 79.5 0Z"
            fill="black"
            fillOpacity={0.1}
          ></path>
        </svg>
      </Grid>
      <h2
        style={{
          fontFamily:
            "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji",
        }}
      >
        {heading}
      </h2>
      <p
        style={{
          fontFamily:
            "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji",
        }}
      >
        {subText}
      </p>
      <div className={classes.buttonsContainer}>
        <CustomButton
          onClick={onClickYes}
          className={clsx(
            classes.yesButton,
            inverseButton ? classes.weight400 : ""
          )}
        >
          Go to NCMEC
        </CustomButton>
        <CustomButton
          onClick={onClickBack}
          className={clsx(
            classes.noButton,
            inverseButton ? classes.weight400 : ""
          )}
        >
          <ArrowBackIcon sx={{ fontSize: 17 }} /> Back
        </CustomButton>
      </div>
    </div>
  );
};

export default NCMEC;
