import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { MdErrorOutline } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: "5px",
    width: "100%",
    "& .MuiFormLabel-root": {
      color: theme.palette.grey2,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.grey1} !important`,
    },
    "& .Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.red} !important`,
    },
    "& .MuiButtonBase-root": {
      color: theme.palette.lightGreen,
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0",
    },
  },
  errorText: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: "5px",
      fontSize: "14px",
    },
  },
}));

const CustomTextField = ({ errorText, ...rest }) => {
  const classes = useStyles();

  return (
    <TextField
      className={classes.textField}
      helperText={
        errorText ? (
          <div className={classes.errorText}>
            <MdErrorOutline /> <div>{errorText}</div>
          </div>
        ) : (
          rest?.helperText
        )
      }
      {...rest}
    />
  );
};

export default CustomTextField;
