import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import CustomButton from "../UI/CustomButton.jsx";

const useStyles = makeStyles((theme) => ({
  greenBox: {
    // background: theme.palette.lightGreen2,
    width: "100%",
    // padding: "35px",
    "& h1": {
      width: "100%",
      textAlign: "center",
      ...theme.typography.heading20,
      fontFamily:
        "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji !important",
      margin: 0,
      marginBottom: "15px",

      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "15px 0px",
        fontSize: "27px",
      },
    },
    "& p": {
      width: "100%",
      textAlign: "center",
      ...theme.typography.subHead14,
      fontFamily:
        "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji !important",
      marginTop: 20,
      [theme.breakpoints.down("sm")]: {
        marginTop: "20px",
        fontSize: "16px",
        lineHeight: "22px",
        marginBottom: "10px",
      },
    },
  },
  question: {
    margin: 0,
    marginTop: "25px",
    textAlign: "center",
    width: "100%",
    ...theme.typography.heading20,
       fontFamily:
      "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji !important",

    [theme.breakpoints.down("lg")]: {
      fontSize: "17px",
      lineHeight: 1.3,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
      lineHeight: 1.3,
      marginTop: "15px",
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
    gap: "10px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  nextButton: {
    fontSize: "14px !important",
    marginTop: "20px !important",
    color: "white !important",
    height: "40px !important",
    backgroundColor: "#528068 !important",
    width: "100% !important",
    borderRadius: "6px !important",
    fontWeight: "500 !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    fontFamily:
    "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji !important",
    "&:hover": {
      background: theme.palette.darkGreen,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "13px !important",

      height: "auto !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
      marginRight: "0px !important",
      height: "40px !important",
    },
  },

  noButton2: {
    width: "100% !important",
    marginTop: "10px !important",
    fontFamily:
    "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji !important",
    fontSize: "14px !important",
    height: "40px !important",
    fontWeight: "500 !important",
    background: "white !important",
    lineHeight: "1.2 !important",
    textAlign: "center !important",
    borderRadius: "6px !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    [theme.breakpoints.down("md")]: {
      fontSize: "13px !important",
      height: "auto !important",
    },

    [theme.breakpoints.down("sm")]: {
      marginTop: "10px !important",
      height: "40px !important",
      fontSize: "16px !important",
      marginLeft: "0px !important",
    },
  },
  lastInfo: {
    margin: 0,
    marginTop: "20px",
    ...theme.typography.body14,
    fontFamily:
    "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji !important",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
}));

const Step6 = ({ onClickNo, imageFound, setProgress }) => {
  const classes = useStyles();
  const handleNext = () => {
    setProgress((current) => ({
      ...current,
      key: 6,
      needToShowGreatNews: false,
    }));
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: {
          xs: "80%",
          sm: "43%",
          md: "35%",
          lg: "28%",
        },
      }}
    >
      <div className={classes.greenBox}>
        <h1>
          {imageFound ? (
            "We've got your back."
          ) : (
            <>
              Great news!
              <br /> We didn&apos;t find any current matches.
            </>
          )}
        </h1>
        <p>
          {imageFound
            ? "More than 33% of people have been subjected to non-consensual sharing of images and videos. Loti is here to help you monitor and takedown unwanted images and videos from the internet."
            : "Did you know? 33% of people will be subjected to non-consensual image and video sharing at some point in their life."}
        </p>
      </div>
      <h3 className={classes.question}>
        {" "}
        {imageFound
          ? "Would you like to take action now?"
          : "Are you interested in being notified if we find any media of you online?"}
      </h3>
      <div className={classes.buttonContainer}>
        {/* <CustomButton yellow className={classes.yesButton} onClick={onNext}>
          Yes
        </CustomButton> */}
        <Button
          variant="contained"
          className={classes.nextButton}
          onClick={handleNext}
          // style={{ marginTop: "20px" }}
        >
          Yes
        </Button>
        <CustomButton className={classes.noButton2} onClick={onClickNo}>
          No
        </CustomButton>
      </div>
      {imageFound && (
        <p className={classes.lastInfo}>
          It&apos;s okay if you need a break, you can return at anytime.
        </p>
      )}
    </Box>
  );
};

export default Step6;
