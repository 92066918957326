import { makeStyles, useTheme } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LotiVerified from "../../Assets/Images/loti-verified.svg";
// import MeBeast from "../../Assets/Images/mr_beast.png";
import { getSingleVerifiedImageAction } from "../../Store/Uploads/actions";

const useStyles = makeStyles(() => ({
	center: {
		display: "flex",
		justifyContent: "center",
		position: "relative",
	},
}));

const DeepFakes = () => {
	const theme = useTheme();

	const classes = useStyles();
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const uuid = pathname.split("/")[3];
	const dispatch = useDispatch();
	const { loading, single_verified_asset: verifiedAsset } = useSelector((state) => state.Uploads);
	const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

	useEffect(() => {
		dispatch(getSingleVerifiedImageAction(uuid));
	}, []);

	return (
		<div className={classes.center}>
			{!loading ? (
				<Box
					sx={{
						width: {
							xs: "300px",
							sm: "350px",
							md: "400px",
							lg: "400px",
							xl: "400px",
						},
						// width: "27%",
						marginTop: "4%",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					{window.localStorage.getItem("token") && !isMobileScreen && (
						<div
							style={{
								position: "absolute",
								display: "flex",
								alignItems: "center",
								gap: "5px",
								top: "10px",
								left: "10px",
								cursor: "pointer",
								color: "#2F4F4F",
								fontWeight: 600,
							}}
						>
							<ArrowBackIcon fontSize="20px" sx={{ fontWeight: 600 }} />
							<span style={{ fontSize: "17px" }} onClick={() => navigate("/deep-fakes")}>
								{" "}
								Back
							</span>{" "}
						</div>
					)}
					<img src={LotiVerified} style={{ width: "30px" }} />
					<span style={{ marginTop: "20px", fontWeight: 600, fontSize: "20px" }}>Photo verified by Loti™</span>
					<span style={{ marginTop: "10px", fontSize: "16px", textAlign: "center" }}>
						This photo does not contain a deepfake.
					</span>
					<div
						className="card"
						style={{
							width: "100%",
							border: "1px solid ",
							borderColor: "#d3d3d3",
							padding: "10px 20px",
							borderRadius: "10px",
							display: "flex",
							flexDirection: "column",
							color: "grey",
							marginTop: "20px",
						}}
					>
						<div
							className="user-profile"
							style={{ display: "flex", gap: "5%", borderRadius: "10px 10px 0px 0px", marginTop: "15px" ,alignItems:"center"}}
						>
							<div
								className="avatar"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									width: "50px",
									height: "50px",
									borderRadius: "50%",
									border: "1px solid",
									borderColor: "#d3d3d3",
								}}
							>
								<span
									style={{
										fontSize: "25px",
										color: "black",
									}}
								>
									{verifiedAsset?.user?.full_name[0]}
								</span>
							</div>
							<div style={{ display: "flex", flexDirection: "column" }}>
								<span style={{ fontWeight: 600, fontSize: "18px", color: "black" }}>{verifiedAsset?.user?.full_name}</span>
								{/* <span style={{ color: "grey" }}>@mr_beast</span> */}
							</div>
						</div>
						<img src={verifiedAsset.protected_file_url} style={{ margin: "10px 0px", borderRadius: "10px" }} />
						<div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
							<span>Author</span>
							<span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
								{verifiedAsset.author_verified && <img src={LotiVerified} style={{ width: "20px" }} />}
								<span>{verifiedAsset?.user?.full_name}</span>
							</span>
						</div>
						<div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
							<span>Verified User</span>
							<span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
								{verifiedAsset?.user?.is_verified && <img src={LotiVerified} style={{ width: "20px" }} />}
								<span>{verifiedAsset?.user?.is_verified ? "Yes" : "No"}</span>
							</span>
						</div>
						<div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
							<span>Location</span>
							<span style={{ display: "flex", alignItems: "center", gap: "10px" }}>{verifiedAsset?.user?.country}</span>
						</div>
					</div>
				</Box>
			) : (
				<div>
					<CircularProgress color="success" />
				</div>
			)}
		</div>
	);
};

export default DeepFakes;
