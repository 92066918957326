import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Grid,
  // IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  useMediaQuery,
  // Drawer,
  // Box,
  Button,
} from "@mui/material";
import {
  MdOutlineKeyboardArrowDown,
  MdLogout,
  MdOutlineSettings,
  MdOutlineArrowBackIos,
} from "react-icons/md";
import clsx from "clsx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import MenuIcon from "../../Assets/Images/menu.png";
import Inbox from "../../Assets/Images/ic_dmca_inbox.svg";
// eslint-disable-next-line
import Script from "../Script/Script.jsx";
// import Options from "./Options.jsx";
import { getInitials } from "../../Helpers/functions";
import { logoutAction } from "../../Store/Auth/actions";

const useStyles = makeStyles((theme) => ({
  greenBar: {
    background: theme.palette.darkGreen,
    height: "18px",
    [theme.breakpoints.down("sm")]: {
      height: "5px",
    },
    width: "100vw",
  },
  wrapper: {
    padding: "20px",
    [theme.breakpoints.down("md")]: {
      padding: "20px 20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
    },
  },
  logo: {
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: "25px",
      width: "50px",
    },
  },
  detailsWrapper: {
    display: "flex",
    alignItems: "center",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  circle: {
    height: "45px",
    width: "45px",
    borderRadius: "50%",
    background: theme.palette.lightGreen,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.white,
    ...theme.typography.subHead14,
  },
  nameTypography: {
    marginLeft: "20px !important",
  },
  iconBlack: {
    color: theme.palette.black,
    fontSize: "20px",
  },
  header: {
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
  },
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.black,
    marginRight: "20px",
  },
  optionName: {
    marginLeft: "10px !Important",
  },
  icon: {
    height: "25px",
    width: "25px",
  },
  menuIcon: {
    marginLeft: "10px",
  },
  menuButton: {
    background: "transparent !important",
    padding: "0 !important",
    color: `${theme.palette.black} !important`,
    textTransform: "none !important",
  },
  mobileHeader: {
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
    padding: "10px",
    height: "56px",

    "& h2": {
      ...theme.typography.body20,
      margin: 0,
      lineHeight: "36px",
      fontWeight: 500,
    },
  },
  backIcon: {
    fontSize: "18px",
  },
}));

const options = [
  {
    name: "DMCA Inbox",
    link: "/dmca-inbox",
  },
  {
    name: "Saved Matches",
    link: "/matches",
  },
  {
    name: "Search",
    link: "/search",
  },
  {
    name: "Upload Media",
    link: "/uploaded-media",
  },
  {
    name: "Takedown Requests",
    link: "/takedown-requests",
  },

  {
    name: "Alerts",
    link: "/alerts",
  },
  {
    name: "Dashboard",
    link: "/alerts ",
  },
  {
    name: "Profile",
    link: "/account/profile",
  },
  {
    name: "Security",
    link: "/account/security",
  },
  {
    name: "Billing",
    link: "/account/billing",
  },
];

const Header = ({ userData }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [drawerOpen, setDrawerOpen] = React.useState(false);

  const open = Boolean(anchorEl);
  const activeLink = `/${
    pathname.split("/").length > 2
      ? `${pathname.split("/")[1]}/${pathname.split("/")[2]}`
      : pathname.split("/")[1]
  }`;
  const { name } = options.filter((e) => e.link === activeLink)[0] || "";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    dispatch(logoutAction());
  };
  // const toggleDrawer = (openval) => (event) => {
  //   if (
  //     event.type === "keydown" &&
  //     (event.key === "Tab" || event.key === "Shift")
  //   ) {
  //     return;
  //   }

  //   setDrawerOpen(openval);
  // };

  // const closeDrawer = () => {
  //   setDrawerOpen(false);
  // };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className={classes.header}>
      <Script />
      {isMobileScreen ? (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.mobileHeader}
        >
          <Grid item xs={1}>
            <MdOutlineArrowBackIos
              onClick={() => handleBack()}
              className={classes.backIcon}
            />
          </Grid>
          <Grid item>
            <h2>{name}</h2>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      ) : (
        <>
          <div className={classes.greenBar} />
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.wrapper}
          >
            <Grid item sm={3} md={4}>
              <img
                src="/logo.svg"
                alt="logo"
                className={classes.logo}
                onClick={() => navigate("/alerts")}
                referrerPolicy="no-referrer"
              />
            </Grid>
            <Grid>
              <div className={classes.detailsWrapper}>
                <div className={classes.flexContainer}>
                  <Link to="/dmca-inbox" className={classes.link}>
                    <img
                      src={Inbox}
                      alt={"DMCA Inbox"}
                      className={classes.icon}
                      referrerPolicy="no-referrer"
                    />
                    <Typography variant="body16" className={classes.optionName}>
                      DMCA Inbox
                    </Typography>
                  </Link>
                </div>
                <div className={classes.flexContainer}>
                  <Button
                    onClick={handleClick}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    className={classes.menuButton}
                    disableRipple={true}
                  >
                    <div className={classes.circle}>
                      {userData?.full_name
                        ? getInitials(userData.full_name)
                        : getInitials(userData?.email_address)}
                    </div>
                    <Typography
                      variant="body16"
                      className={classes.nameTypography}
                    >
                      {userData?.full_name
                        ? userData.full_name
                        : userData?.email_address}
                    </Typography>
                    <MdOutlineKeyboardArrowDown
                      className={clsx(classes.iconBlack, classes.menuIcon)}
                    />
                  </Button>
                </div>{" "}
              </div>
              {/* )} */}
            </Grid>
          </Grid>
        </>
      )}

      {/* {isMobileScreen ? (
            <div className={classes.mobileHeader}>
              <h2>Takedown</h2>
              <IconButton>
                <img
                  onClick={toggleDrawer(true)}
                  src={MenuIcon}
                  alt="menu icon"
                />
              </IconButton>
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
              >
                <Box onKeyDown={toggleDrawer(false)}>
                  <Options
                    closeDrawer={() => closeDrawer()}
                    userData={userData}
                  />
                </Box>
              </Drawer>
            </div>
          ) : ( */}

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {!userData?.agency_id && (
          <MenuItem onClick={() => navigate("/account/profile")}>
            <ListItemIcon>
              <MdOutlineSettings className={classes.iconBlack} />
            </ListItemIcon>
            Account Settings
          </MenuItem>
        )}
        <MenuItem onClick={() => logout()}>
          <ListItemIcon>
            <MdLogout className={classes.iconBlack} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Header;
