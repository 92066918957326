import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";
import { MdHelpOutline } from "react-icons/md";

const useStyle = makeStyles((theme) => ({
  container: {
    margin: "10px",
    background: theme.palette.white,
    borderRadius: "25px",
    boxShadow: "0px 3px 50px rgba(0, 0, 0, 0.05)",
    padding: "20px",
  },
  headSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  heading: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    fontSize: "22px",
    marginRight: "15px",
  },
  redColor: {
    color: theme.palette.red,
  },
  greenColor: {
    color: theme.palette.lightGreen,
  },
  contentContainer: {
    marginTop: "15px !important",
  },
  content: {
    color: theme.palette.grey2,
  },
  timeLeft: {
    ...theme.typography.body14,
    margin: "0 0 0 5px ",
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.body16,
      alignSelf: "flex-end",
    },
  },
}));

const AlertCard = ({ heading, content, timeAgo,type }) => {
  const classes = useStyle();
  return (
    <div className={classes.container} style={{boxShadow: "0px 3px 50px rgba(0, 0, 0, 0.07)"}}>
      <div className={classes.headSection}>
        <div className={classes.heading}>
         
            <MdHelpOutline className={clsx(classes.icon, type==="imageDetected"?classes.redColor: classes.greenColor)} />
          <Typography variant="heading20" className={type==="imageDetected"?classes.redColor: classes.greenColor}>{heading}</Typography>
        </div>
        <p className={classes.timeLeft}>{timeAgo}</p>
      </div>
      <div className={classes.contentContainer}>
        <Typography variant="body16" className={classes.content}>
          {content}
        </Typography>
      </div>
    </div>
  );
};

export default AlertCard;
