import { makeStyles } from "@mui/styles";
import React from "react";
import { MdOutlineFileUpload } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
  content: {
    ...theme.typography.heading36,
    textAlign: "center",
    padding: "0 10px",
    margin: 0,
    [theme.breakpoints.down("lg")]: {
      fontSize: "32px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
  },

  uploadContainer: {
    background: theme.palette.lightGreen2,
    marginTop: "60px",
    width: "65vw",
    [theme.breakpoints.down("md")]: {
      marginTop: "45px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
      width: "90vw",
    },
  },
  uploadWrap: {
    padding: "15px",
    height: "250px",
    cursor: "pointer",
  },
  uploadBorder: {
    border: "1px dashed #799995",
    borderRadius: "5px",
    height: "100%",
  },
  input: {
    display: "none",
  },
  text: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",

    "& h3": {
      ...theme.typography.body24,
      color: theme.palette.lightGreen,
    },
    "& svg": {
      fontSize: "30px",
      color: theme.palette.lightGreen,
      marginRight: "10px",
    },
  },
}));

const UploadBox = ({ handleUploadImage }) => {
  const classes = useStyles();

  const handleChange = (event) => {
    handleUploadImage(event.target.files[0]);
  };
  return (
    <>
      <h2 className={classes.content}>
        Upload an image or video to get started
      </h2>
      <div className={classes.uploadContainer}>
        <input
          className={classes.input}
          type="file"
          accept="image/*"
          id="image-upload"
          name="myImage"
          onChange={handleChange}
        />
        <label htmlFor="image-upload">
          <div className={classes.uploadWrap}>
            <div className={classes.uploadBorder}>
              <div className={classes.text}>
                <MdOutlineFileUpload />
                <h3 className="upload-file">Upload File</h3>
              </div>
            </div>
          </div>
        </label>
      </div>
    </>
  );
};

export default UploadBox;
