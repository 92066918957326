import { Box, Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useSelector } from "react-redux";
import CustomSpinner2 from "../UI/CustomSpinner2.jsx";

const useStyles = makeStyles((theme) => ({
  StepContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "30%",
    [theme.breakpoints.down("lg")]: {
      width: "36%",
    },
    [theme.breakpoints.down("md")]: {
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  headTitle: {
    ...theme.typography.heading28,
    margin: 0,
    marginBottom: "10px",
    marginTop: "5%",
    textAlign: "center",
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      fontSize: "21px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
      fontSize: "18px",
      lineHeight: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginBottom: "15px",
      fontSize: "24px",
    },
  },
  para: {
    ...theme.typography.body20,
    margin: 0,
    fontSize: "16px",
    textAlign: "center",
    marginBottom: "25px",
    color: "#737373",
    lineHeight: "20px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "13px",
      width: "90%",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "13px",
      lineHeight: 1.3,
      width: "85%",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      width: "100%",
      marginBottom: "15px 0px",
      fontSize: "16px",
      lineHeight: "18px",
    },
  },
  nextButton: {
    boxShadow: "none !important",
    fontSize: "14px !important",
    marginTop: "20px !important",
    color: "white !important",
    height: "40px !important",
    backgroundColor: "#528068 !important",
    width: "100% !important",
    borderRadius: "6px !important",
    fontWeight: "500 !important",
    textTransform: "none !important",
    "&:hover": {
      background: theme.palette.darkGreen,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "13px !important",

      height: "auto !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
      marginRight: "0px !important",
      // maxWidth: "240px !important",
      height: "40px !important",
    },
  },

  image: {
    maxWidth: "100%",
    maxHeight: "500px",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "& h3": {
      textAlign: "center",
      ...theme.typography.heading36,
      marginTop: "20px",
      marginBottom: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      "& h3": {
        fontSize: "24px",
        lineHeight: "34px",
      },
    },
  },
}));

const Step4 = ({ faces, faceId, onNext, setFaceId }) => {
  const classes = useStyles();

  const { loading } = useSelector((state) => state.matches);



  const onFaceSelection = async () => {
    onNext();
  };

  return (
    <>
      {loading ? (
        <div className={classes.loadingContainer}>
          <h3>
            Please wait while we search
            <br />
            more than 50 million sites for matches...
          </h3>
          <CustomSpinner2 />
        </div>
      ) : (
        <div className={classes.StepContainer}>
          <Grid
            item
            sm={6}
            container
            alignItems="center"
            justifyContent={"center"}
            marginTop="7%"
          >
            <div style={{ display: "flex", gap: "2%" }}>
              {faces.map((item, key) => (
                <div
                  style={{
                    position: "relative",
                    background: "white",
                    padding: "5px",
                    borderRadius: "10px",
                    border:
                      faceId === key ? "2px solid green" : "1px solid grey",
                  }}
                  key={key}
                >
                  <Box
                    onClick={() => setFaceId(key)}
                    sx={{
                      cursor: "pointer",
                      width: {
                        xs: "50px",
                        sm: "35px",
                        md: "50px",
                      },
                      height: { xs: "50px", sm: "35px", md: "50px" },
                    }}
                  >
                    <img
                      src={item.face_url}
                      referrerPolicy="no-referrer"
                      style={{ width: "100%" }}
                    />
                  </Box>
                </div>
              ))}
            </div>
          </Grid>
          <Grid
            item
            sm={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <h2
              className={classes.headTitle}
              style={{
                fontFamily:
                  "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji",
              }}
            >
              Is this the correct person?
            </h2>
            <p className={classes.para}>
              Confirm that this photo matches the person in the image you
              provided.
            </p>
            <>
              <Button
                variant="contained"
                className={classes.nextButton}
                onClick={onFaceSelection}
                style={{ marginTop: "20px" }}
              >
                Next
              </Button>
            </>
          </Grid>
        </div>
      )}
    </>
  );
};

export default Step4;
