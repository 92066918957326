import { Grid, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useSelector } from "react-redux";
import CustomButton from "../UI/CustomButton.jsx";
import CustomSpinner2 from "../UI/CustomSpinner2.jsx";

const useStyles = makeStyles((theme) => ({
  headTitle: {
    ...theme.typography.heading36,
    margin: 0,
    marginBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginBottom: "5px",
      fontSize: "24px",
    },
  },
  para: {
    ...theme.typography.body24,
    margin: 0,
    marginBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginBottom: "20px",
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
  yesButton: {
    fontSize: "20px !important",
    height: "70px !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px !important",
      fontSize: "16px !important",
      marginRight: "0px !important",
      maxWidth: "300px !important",

      height: "40px !important",
    },
  },
  noButton: {
    marginTop: "20px !important",
    fontSize: "20px !important",
    height: "70px !important",
    fontWeight: "400 !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px !important",
      height: "40px !important",
      fontSize: "16px !important",
      marginLeft: "0px !important",
      maxWidth: "300px !important",
    },
  },
  image: {
    maxWidth: "90%",
    maxHeight: "500px",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "& h3": {
      textAlign: "center",
      ...theme.typography.heading36,
      marginTop: "20px",
      marginBottom: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      "& h3": {
        fontSize: "24px",
        lineHeight: "34px",
      },
    },
  },
}));

const Step4 = ({ faces, faceId, showNextFace, onNext }) => {
  const classes = useStyles();
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { loading } = useSelector((state) => state.matches);

  const onFaceSelection = () => {
    onNext();
  };

  return (
    <>
      {loading ? (
        <div className={classes.loadingContainer}>
          <h3>
            Please wait while we search<br />
            more than 50 million sites for matches...
          </h3>
          <CustomSpinner2 />
        </div>
      ) : (
        <Grid container>
          <Grid item sm={6}>
            <h2 className={classes.headTitle}>Is this the correct person?</h2>
            <p className={classes.para}>
              Confirm that this photo matches the person in the image you
              provided.{" "}
            </p>
            {!isMobileScreen && (
              <>
                <CustomButton
                  yellow
                  className={classes.yesButton}
                  onClick={onFaceSelection}
                >
                  Yes
                </CustomButton>
                <CustomButton
                  className={classes.noButton}
                  onClick={showNextFace}
                >
                  No
                </CustomButton>
              </>
            )}
          </Grid>
          <Grid
            item
            sm={6}
            container
            alignItems="center"
            justifyContent={{ sm: "flex-end", xs: "center" }}
          >
            <img src={faces[faceId].face_url} className={classes.image} referrerPolicy="no-referrer"/>
          </Grid>
          {isMobileScreen && (
            <Grid item container justifyContent="center">
              <CustomButton
                yellow
                className={classes.yesButton}
                onClick={onFaceSelection}
              >
                Yes
              </CustomButton>
              <CustomButton className={classes.noButton} onClick={showNextFace}>
                No
              </CustomButton>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default Step4;
