import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
      textAlign: "center",
      ...theme.typography.small,
      margin: 0,
      marginBottom: "25px",
      padding: "70px 0",
      bottom: 0,
      [theme.breakpoints.down("sm")]: {
        marginBottom: "5px",
        fontSize: "14px",
        paddingTop: "50px",
        paddingBottom: "100px",
    },
  },
  typoBottom: {
    marginTop: "25px",
    lineHeight: "25px",
    "& a": {
      color: theme.palette.lightGreen,
      textDecoration: "none",
    },
  },
}));

const AccountAlert = () => {
  const classes = useStyles();
  return (
    <div className={classes.container} >
            <Typography variant="heading18" className={classes.typoBottom}>
              Already a Member?  <Link to="/login">Login</Link> <br />
        </Typography>      
    </div>
  );
};

export default AccountAlert;
