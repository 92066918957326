import { StyledEngineProvider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import AuthHeader from "./AuthHeader.jsx";
// import Image1 from "../../Assets/Images/img1.jpg";


const useStyles = makeStyles((theme) => ({
  authWrapper: {
    minWidth: "100vw",
    minHeight: "100vh",
    overflowY: "auto",
    background: theme.palette.lightYellow,
  },
  boxContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    height: "calc(100vh - 100px)",
  },
  box: {
    background: theme.palette.white,
    width: "60vw",
    minHeight: "400px",
    height: "70vh",
    display: "flex",
    boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.07)",
    borderRadius: "15px",
    [theme.breakpoints.down("md")]: {
      width: "85vw",
    },
  },
  boxWithoutImage: {
    background: theme.palette.white,
    width: "30vw",
    minHeight: "500px",
    height: "65vh",
    display: "flex",
    boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.07)",
    borderRadius: "15px",

    [theme.breakpoints.down("lg")]: {
      width: "40vw",
    },
    [theme.breakpoints.down("md")]: {
      width: "50vw",
    },
    [theme.breakpoints.down("md")]: {
      width: "85vw",
    },
  },
  boxWithoutImageSection: {
    padding: "15px 15px",
    width: "100%",
  },
  termsTypo: {
    color: theme.palette.grey2,
    width: "300px",
    textAlign: "center",
    marginTop: "20px",
    '& .underline': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  imageContainer: {
    width: "50%",
    "& img": {
      objectFit: "fill",
      width: "100%",
      height: "70vh",
      borderBottomLeftRadius: "15px",
    },
  },
  boxSubSection: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  dataContainer: {
    padding: "40px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
  },
  lotiLogo: {
    height: "60px",
    width: "120px",
    marginTop: "20px",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      width: "100px",
      height: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      height: "40px",
      marginTop: "5px",
    },
  },
}));

const AuthWrapper = ({ children, hideBottomLine, boxWithoutImage }) => {
  const classes = useStyles();

  return (
    <div className={classes.authWrapper}>
      <AuthHeader />
      {boxWithoutImage ? (
        <BoxContainerWithoutImage>{children}</BoxContainerWithoutImage>
      ) : (
        <BoxContainer hideBottomLine={hideBottomLine}>{children}</BoxContainer>
      )}
    </div>
  );
};

const BoxContainerWithoutImage = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.boxContainer}>
      <div className={classes.boxWithoutImage}>
        <div className={classes.boxWithoutImageSection}>{children}</div>
      </div>
    </div>
  );
};
const BoxContainer = ({ children, hideBottomLine }) => {
  const classes = useStyles();
  // const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const redirectToTerms = () => {
    window.open('https://goloti.com/terms', '_blank');
  };

  const redirectToPrivacyPolicy = () => {
    window.open('https://goloti.com/privacy-policy', '_blank');
  };
  return (
    <div className={classes.boxContainer}>
      <div className={classes.boxWithoutImage}>
        {/* {!isMobileScreen && (
          <div className={classes.imageContainer}>
            <img src={Image1} alt="image 1" referrerPolicy="no-referrer" />
          </div>
        )} */}
        <div className={classes.boxSubSection}>
          <div className={classes.dataContainer}>
            <img src="logo.svg" alt="loti logo" className={classes.lotiLogo} referrerPolicy="no-referrer" />
            {children}
          </div>
        </div>
      </div>
      {!hideBottomLine && (
        <StyledEngineProvider injectFirst>
          <Typography variant="small" className={classes.termsTypo}>
            By continuing you agree to Loti’s{" "}
            <span className="underline" onClick={redirectToTerms}>terms</span> and acknowledge you’ve read
            our <span className="underline" onClick={redirectToPrivacyPolicy}>privacy policy</span>
          </Typography>
        </StyledEngineProvider>
      )}
    </div>
  );
};

export default AuthWrapper;
