import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import API from "../../Axios/Axios";

function* getUploadedImages({ callback }) {
  try {
    yield put({ type: actionTypes.SET_UPLOADED_MEDIA_LOADING });
    const { data } = yield API.get("/asset");
    if (data.success) {
      yield put({
        type: actionTypes.GET_UPLOADED_IMAGES_SUCCESS,
        payload: data.data,
      });
      if (data?.data?.length === 0) {
        sessionStorage.setItem("uploads", 0);
      }

      if (sessionStorage.getItem("uploads") === "1") {
        if (callback) callback();
      }
    } else {
      yield put({
        type: actionTypes.GET_UPLOADED_IMAGES_FAILURE,
        payload: "Unable to fetch uploaded media.",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_UPLOADED_IMAGES_FAILURE,
      payload: "Unable to fetch uploaded media.",
    });
  }
}

function* uploadAssetSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_UPLOADS_LOADING });
    const { data } = yield API.post("/asset/upload", payload);

    if (data.success) {
      yield put({
        type: actionTypes.UPLOAD_ASSET_SUCCESS,
        payload: data.data.results,
      });
    } else {
      yield put({
        type: actionTypes.UPLOAD_ASSET_FAILURE,
        payload: 'No face found in image.',
      });
      toast.error('No face found in the image.');
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      const errorMessage = error.response.data.message || 'Unable to upload media.';
      yield put({
        type: actionTypes.UPLOAD_ASSET_FAILURE,
        payload: errorMessage,
      });
      toast.error(errorMessage);
    } else {
      yield put({
        type: actionTypes.UPLOAD_ASSET_FAILURE,
        payload: 'Unable to upload media.',
      });
      toast.error('Unable to upload media.');
    }
  }
}


function* saveAssetSaga({
  resultID,
  onboarding,
  token,
  successCallback,
  failCallback,
}) {
  try {
    yield put({ type: actionTypes.SET_UPLOADS_LOADING });
    const { data } = yield API.post(
      "/asset/save",
      { asset_id: resultID, onboarding },
      {
        headers: {
          Authorization: `Bearer ${
            onboarding ? token : localStorage.getItem("token")
          }`,
        },
      }
    );
    if (data.success) {
      yield put({
        type: actionTypes.SAVE_ASSET_SUCCESS,
      });
      if (!onboarding) {
        yield put({
          type: actionTypes.GET_UPLOADED_IMAGES,
          callback: onboarding ? undefined : successCallback,
        });
      }
      if (successCallback) successCallback(data.data.asset_id);
    } else {
      yield put({
        type: actionTypes.SAVE_ASSET_FAILURE,
        payload: "Unable to save the asset.",
      });
      if (failCallback) failCallback();
    }
  } catch (error) {
    yield put({
      type: actionTypes.SAVE_ASSET_FAILURE,
      payload: "Unable to save asset.",
    });
    if (failCallback) failCallback();
  }
}

function* deleteAssetSaga({ id }) {
  try {
    yield put({ type: actionTypes.SET_UPLOADS_LOADING });

    yield put({
      type: actionTypes.DELETE_ASSET_SUCCESS,
      payload: id,
    });
    toast.success("Asset deleted successfully.");
  } catch (error) {
    yield put({
      type: actionTypes.DELETE_ASSET_FAILURE,
      payload: "Unable to delete asset",
    });
  }
}

function* verifyAssetSaga({ payload,verifiedUser }) {
  try {
    yield put({ type: actionTypes.SET_UPLOADS_LOADING });
    const { data } = yield API.post("/deepfake/upload", payload);
    if (data.success) {
      yield put({
        type: actionTypes.VERIFY_ASSET_SUCCESS,
        payload: data.data,
      });
      if(!data?.data?.author_verified)verifiedUser(data.data)
      else toast.success('Asset added successfully')
     
    } else {
      yield put({
        type: actionTypes.VERIFY_ASSET_FAILURE,
        payload: "Could not process the image.",
      });
      toast.error("Could not process the image.");
    }
  } catch (error) {
    yield put({
      type: actionTypes.VERIFY_ASSET_FAILURE,
      payload: "Unable to upload media.",
    });
    toast.error("Unable to upload media.");
  }
}

function* getVerifiedImages() {
  try {
    yield put({ type: actionTypes.SET_UPLOADS_LOADING });
    const { data } = yield API.get("/deepfake/");
    if (data.success) {
      yield put({
        type: actionTypes.GET_VERIFIED_ASSETS_SUCCESS,
        payload: data.data,
      });
    

    } else {
      
      yield put({
        type: actionTypes.GET_VERIFIED_ASSETS_FAILURE,
        payload: "Unable to fetch verified media.",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_VERIFIED_ASSETS_FAILURE,
      payload: "Unable to fetch verified media.",
    });
  }
}

function* getSingleVerifiedImages({ payload }) {
  try {
    yield put({ type: actionTypes.SET_UPLOADS_LOADING });
    const { data } = yield API.get(`/deepfake/${payload}`);
    if (data.success) {
      yield put({
        type: actionTypes.GET_SINGLE_VERIFIED_ASSET_SUCCESS,
        payload: data.data,
      });
    

    } else {
      
      yield put({
        type: actionTypes.GET_SINGLE_VERIFIED_ASSET_FAILURE,
        payload: "Unable to fetch verified media.",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_SINGLE_VERIFIED_ASSET_FAILURE,
      payload: "Unable to fetch verified media.",
    });
  }
}

function* deleteVerifiedAsset({ payload ,successCallback}) {
  try {
    yield put({ type: actionTypes.SET_UPLOADS_LOADING });
    const { data } = yield API.post(`/deepfake/author-verification-failed`,{uuId:payload});
    if (data.success) {
      yield put({
        type: actionTypes.DELETE_VERIFIED_ASSET_SUCCESS,
      });
      if(successCallback)successCallback()
    } else {
      
      yield put({
        type: actionTypes.DELETE_VERIFIED_ASSET_FAILURE,
        payload: "Unable to delete verified media.",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.DELETE_VERIFIED_ASSET_FAILURE,
      payload: "Unable to delete verified media.",
    });
  }
}


function* subscriptionSaga() {
  yield all([
    yield takeLatest(actionTypes.GET_UPLOADED_IMAGES, getUploadedImages),
    yield takeLatest(actionTypes.GET_VERIFIED_ASSETS, getVerifiedImages),
    yield takeLatest(actionTypes.GET_SINGLE_VERIFIED_ASSET, getSingleVerifiedImages),
    yield takeLatest(actionTypes.UPLOAD_ASSET, uploadAssetSaga),
    yield takeLatest(actionTypes.SAVE_ASSET, saveAssetSaga),
    yield takeLatest(actionTypes.DELETE_ASSET, deleteAssetSaga),
    yield takeLatest(actionTypes.VERIFY_ASSET, verifyAssetSaga),
    yield takeLatest(actionTypes.DELETE_VERIFIED_ASSET, deleteVerifiedAsset),


  ]);
}

export default subscriptionSaga;
