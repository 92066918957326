// To validate email address => returns true if email is valid.
export const isEmailValid = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

// To validate password => returns true if password is valid.
export const isPasswordValid = (password) => {
  if (password.length > 7) {
    return true;
  }
  return false;
};

// To get name initials (i.e => For name Roshil Kapatel it will return RK)
export const getInitials = (name) => {
  if (!name) {
    return "";
  }
  const splitedName = name.split(" ");

  if (splitedName.length > 1) {
    return splitedName[0][0].toUpperCase() + splitedName[1][0].toUpperCase();
  }
  return name[0].toUpperCase();
};

export const validateURL = (value) => (/^(ftp|http|www|https):\/\/[^ "]+$/.test(value))

export const  capitalizeWords=(inputString)=> {
  const  words = inputString.split(' ');
  const  capitalizedWords = [];

  for (let i = 0; i < words.length; i++) {
      const  word = words[i];
      const  capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
      capitalizedWords.push(capitalizedWord);
  }

  return capitalizedWords.join(' ');
}