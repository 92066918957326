import * as actionTypes from "./actionTypes";

const initialState = {
  error: null,
  loading: false,
  errorMessage: "",
  ownUrls: [],
  nourls: false,
};

const Ownurls = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_OWN_URL_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
        nourls: false,
      };

    case actionTypes.GET_OWN_URLS_SUCCESS:
      return {
        ...state,
        ownUrls: action.payload,
        loading: false,
        error: false,
        errorMessage: "",
        nourls: action.noUrls,
      };
    case actionTypes.GET_OWN_URLS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case actionTypes.ADD_OWN_URL_SUCCESS:
      return {
        ...state,
      };
    case actionTypes.ADD_OWN_URL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
      case actionTypes.OWN_URL_TAKEDOWN_SUCCESS:
        return {
          ...state,
          loading:false,
        };
      case actionTypes.OWN_URL_TAKEDOWN_FAILURE:
        return {
          ...state,
          loading: false,
          error: true,
          errorMessage: action.payload,
        };
    default:
      return state;
  }
};

export default Ownurls;
