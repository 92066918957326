import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  yellow: {
    textTransform: "none !important",
    width: "100%",
    background: "rgba(235, 163, 17, 0.14) !important",
    border: `2px solid ${theme.palette.yellow} !important`,
    color: `${theme.palette.black} !important`,
    ...theme.typography.button2,
    fontWeight: 700,
    "&:hover": {
      background: "rgba(235, 163, 17, 0.24)",
    },
  },
  normal: {
    textTransform: "none !important",
    width: "100%",
    boxShadow: "none !important",
    background: "transparent !important",
    border: `1px solid #D9D9D9 !important`,
    color: `${theme.palette.black} !important`,
    ...theme.typography.button2,
    "&:hover": {
      background: "transparent",
    },
  },
  yellowFilled: {
    textTransform: "none !important",
    width: "100%",
    background: `${theme.palette.yellow} !important`,
    color: `${theme.palette.black} !important`,
    padding: "12px !important",
  },
}));

const CustomButton = ({ yellowFilled, yellow, children, ...rest }) => {
  const classes = useStyles();
  return (
    <Button
      {...rest}
      variant="contained"
      className={clsx(
        yellowFilled
          ? classes.yellowFilled
          : yellow
          ? classes.yellow
          : classes.normal,
        rest.className
      )}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
