import * as actionTypes from "./actionTypes";

const initialState = {
  error: null,
  loading: true,
  alerts: [],
  errorMessage:"",
  dataLength:null
};

const alert = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALERTS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.GET_ALERTS_SUCCESS:
      return {
        ...state,
        loading: false,
        alerts: state.dataLength===null?action.payload.data: state.alerts.concat(action.payload.data),
        dataLength:action.payload.data.length
      };
    case actionTypes.GET_ALERTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      };

    default:
      return state;
  }
};

export default alert;
