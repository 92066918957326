import { Grid, Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState, useEffect } from "react";
import placeholder from "../../Assets/Images/loti-placeholder.jpg";
import CustomButton from "../UI/CustomButton.jsx";

const useStyles = makeStyles((theme) => ({
  step5Container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    fontSize: "24px",
    fontWeight: 600,

    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
      paddingBottom: "100px",
    },
    "& h2": {
      ...theme.typography.heading36,
      maxWidth: "200px",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
    },
  },
  root: {
    flexGrow: 1,
    position: "relative",
    marginTop: "12px !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: "8px !important",
    },
  },
  noExactMatch: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    width: "30%",
    fontFamily:
      "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",

    [theme.breakpoints.down("xl")]: {
      width: "30%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "35%",
    },
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "50px",
      // paddingBottom: "100px",
      width: "80%",
    },
    "& h2": {
      ...theme.typography.heading28,
      fontFamily:
        "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",

      marginTop: "0px",
      textAlign: "center",
      // fontSize: "23px !important",
      [theme.breakpoints.down(1400)]: {
        fontSize: "23px !important",
        lineHeight: "28px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "22px !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "20px !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "27px !important",
      },
    },
    "& p": {
      fontFamily:
        "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",

      ...theme.typography.heading32,
      textAlign: "center",
      fontSize: "16px !important",
      [theme.breakpoints.down("lg")]: {
        fontSize: "15px !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px !important",
      },
    },
  },
  p: {
    ...theme.typography.body18,
    fontFamily:
      "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",

    maxWidth: "100%",
    textAlign: "center",
    marginTop: "0px",
    fontSize: "16px",
    color: "#737373",
    lineHeight: 1.3,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
    },
  },
  yellow: {
    color: theme.palette.yellow,
    fontWeight: 600,
  },
  bottomLine: {
    marginTop: "10px",
    ...theme.typography.body14,
    fontFamily:
      "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    fontSize: "12px",
    color: "#737373",
    padding: "5px 20px !important",
    // [theme.breakpoints.down(1300)]: {
    //   fontSize: "10px",
    // },
    [theme.breakpoints.down("xl")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "11px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "5px 20px",
    },
    "& svg": {
      color: theme.palette.yellow,
      marginRight: "10px",
    },
  },
  buttonsContainer: {
    marginTop: "20px",
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  yesButton: {
    ...theme.typography.button,
    color: theme.palette.white,
    background: `${theme.palette.orange} !important`,
    fontFamily:
      "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",
    width: "70% !important",
    // margin: "5px",
    boxShadow: "none !important",
    marginTop: "10px",
    height: "40px",
    textTransform: "none !important",
    fontWeight: "500 !important",
    fontSize: "13px !important",
    [theme.breakpoints.down("xl")]: {
      fontSize: "13px !important",
      marginLeft: "0px !important",
      height: "auto !important",
    },
    [theme.breakpoints.down(1460)]: {
      fontSize: "12px !important",
      height: "auto !important",
      width: "80% !important",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "11.5px !important",
      marginLeft: "0px !important",
      height: "auto !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      fontSize: "12px !important",
      marginLeft: "0px !important",
      height: "40px !important",
      padding: "6px 2px !important",
    },
  },

  bg_green: {
    color: `${theme.palette.white} !important`,
    background: `${theme.palette.lightGreen} !important`,
  },
  headTitle: {
    ...theme.typography.heading28,
    fontSize:"27px",
    fontFamily:
      "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",
    textAlign: "center",
    width: "100%",
    [theme.breakpoints.down(1400)]: {
      fontSize: "23px !important",
      lineHeight: "28px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "21px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
      fontSize: "18px",
      lineHeight: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginBottom: "15px",
      fontSize: "27px",
      lineHeight: "25px",
    },
  },

  nextButton: {
    fontSize: "13px !important",
    marginTop: "10px !important",
    color: "white !important",
    height: "40px !important",
    backgroundColor: "#528068 !important",
    width: "100% !important",
    borderRadius: "6px !important",
    textAlign: "center !important",
    textTransform: "none !important",
    fontWeight: "500 !important",
    boxShadow: "none !important",
    fontFamily:
      "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",
    [theme.breakpoints.down(1300)]: {
      fontSize: "12px !important",
      marginRight: "0px !important",
      // maxWidth: "240px !important",
      height: "40px !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "13px !important",

      height: "auto !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
      marginRight: "0px !important",
      // maxWidth: "240px !important",
      height: "40px !important",
    },
  },
  noButton2: {
    width: "100% !important",
    marginTop: "20px !important",
    fontSize: "13px !important",
    height: "40px !important",
    fontWeight: "500 !important",
    background: "white !important",
    lineHeight: "1.2 !important",
    textAlign: "center !important",
    borderRadius: "6px !important",
    boxShadow: "none !important",
    fontFamily:
      "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",
    [theme.breakpoints.down(1300)]: {
      fontSize: "12px !important",
      height: "40px !important",
      // padding:"6px 10px"
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px !important",
      marginRight: "0px !important",
      height: "40px !important",
      lineHeight: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px !important",
      height: "40px !important",
      fontSize: "12px !important",
      marginLeft: "0px !important",
    },
  },
}));

const Step5 = ({ noExactMatch, searches, setProgress }) => {
  const classes = useStyles();
  const gridRef = React.useRef(null);

  const [showImageCarousel, setShowImageCarousel] = useState(false);

  const onShowImage = () => {
    setShowImageCarousel(true);
  };

  const handleNo = () => {
    setProgress((current) => ({
      ...current,
      key: 6,
      needToShowGreatNews: true,
    }));
  };

  const handleYes = () => {
    setProgress((current) => ({ ...current, key: 6 }));
  };

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.scrollIntoView({ behavior: "smooth" });
    }
  });

  return (
    <>
      {showImageCarousel ? (
        <Box
          sx={{
            width: {
              xs: "80%",
              sm: "45%",
              md: "35%",
              lg: "30%",
            },
            display: "flex",
            flexDirection: "column",
            alingItems: "center",
          }}
        >
          <Grid
            item
            sm={6}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "50px",
            }}
          >
            <h2 className={classes.headTitle}>
              {noExactMatch
                ? "Good news - we found no exact matches."
                : ` We found exact matches`}
            </h2>
            <Box
              className={classes.p}
              sx={{
                fontSize: {
                  xs: "11px",
                  sm: "13px",
                  md: "14px",
                  lg: "16px",
                },
                marginBottom: "20px",
              }}
            >
              {noExactMatch ? (
                <>
                  However, we did find{" "}
                  <span style={{ color: "black", fontWeight: 500 }}>
                    {" "}
                    {searches.length}{" "}
                    {searches.length === 1 ? "close match" : "close matches"}{" "}
                  </span>
                </>
              ) : (
                ` We found ${searches.length}
                 result(s) that matches your face.`
              )}
            </Box>
            <Box
              sx={{
                padding: "4%",
                background: "white",
                borderRadius: "12px",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 3px",
                fontFamily:
                  "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji ",
              }}
            >
              <Box
                // sm={6}
                // container
                // alignItems="center"
                // justifyContent={{ sm: "flex-end", xs: "center" }}
                sx={{
                  width: "100%",
                  // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  overflowY: "scroll",
                  overflowX: "noScroll",
                  background: "white",
                  "&::-webkit-scrollbar": {
                    width: "5px", // Set the width of the scrollbar
                    height: "12px", // Set the height of the
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "rgb(180, 180, 180)", // Set the color of the thumb
                    borderRadius: "5px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f0f0f0", // Set the color of the track
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    height: {
                      xs: "250px",
                      md: "170px",
                      lg: "200px",
                      xl: "250px",
                    },
                    width: "100%",
                    gap: {
                      xs: "5%",
                      sm: "5%",
                      md: "4.5%",
                      lg: "5.5%",
                    },
                  }}
                >
                  {searches.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        width: {
                          xs: "20%",
                          sm: "20%",
                          md: "20%",
                          lg: "20%",
                        },
                        height: {
                          xs: "20%",
                          sm: "20%",
                          md: "20%",
                          lg: "20%",
                        },
                      }}
                    >
                      <img
                        referrerPolicy="no-referrer"
                        // className={classes.image}
                        src={item.faceSignedUrl}
                        onError={(ev) => {
                          ev.currentTarget.src = placeholder;
                          ev.currentTarget.onError = null;
                        }}
                        loading="lazy"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "7px",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
              <div
                style={{ marginTop: "3%", width: "100%", marginBottom: "5%" }}
              >
                <Box
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "12px",
                      md: "13px",
                      lg: "14px",
                    },
                    marginTop: "15px",
                    textAlign: "center",
                  }}
                >
                  Do any of these match your upload?
                </Box>
                <CustomButton
                  className={classes.nextButton}
                  onClick={handleYes}
                >
                  Yes, I can see the matching image(s).
                </CustomButton>

                <CustomButton className={classes.noButton2} onClick={handleNo}>
                  No, the images do not match.
                </CustomButton>
              </div>
            </Box>
          </Grid>
        </Box>
      ) : (
        <>
          {noExactMatch ? (
            <div className={classes.noExactMatch}>
              <h2>Good News - we found no exact matches.</h2>
              <Box
                className={classes.p}
                sx={{
                  fontSize: {
                    xs: "15px",
                    sm: "13px",
                    md: "14px",
                    lg: "16px",
                  },
                  marginBottom: "20px",
                }}
              >
                However, we did find{" "}
                <span style={{ fontWeight: 500, color: "black" }}>
                  {searches.length}{" "}
                  {searches.length === 1 ? "close match" : "close matches"}
                </span>
                . Would you like to see them to make sure they {"don't"} match?
              </Box>
              <Box
                sx={{
                  padding: "15px",
                  borderRadius: "12px",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 3px",
                  background: "white",
                  width: "100%",
                  height: { xs: "200px", sm: "170px", md: "200px" },
                }}
              >
                <div
                  style={{
                    background: "#F5F5F5",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "center",
                    borderRadius: "12px",
                  }}
                >
                  <Box
                    className={classes.buttonsContainer}
                    sx={{ width: { xs: "50%", sm: "80%", md: "80%" } }}
                  >
                    <Button
                      variant="contained"
                      onClick={onShowImage}
                      className={classes.yesButton}
                    >
                      Show me my matches
                    </Button>
                  </Box>
                  <div className={classes.bottomLine}>
                    {/* <MdErrorOutline color="green" /> */}
                    By clicking this button, {"you're"} consenting to see NSFW
                    images.
                  </div>
                </div>
              </Box>
            </div>
          ) : (
            <>
              <div className={classes.noExactMatch}>
                <h2>
                  We found {searches.filter((e) => e.distance <= 400).length}
                  &nbsp;result(s) that matches your face.
                </h2>
                <Box
                  className={classes.p}
                  sx={{
                    fontSize: {
                      xs: "15px",
                      sm: "13px",
                      md: "14px",
                      lg: "16px",
                    },
                    marginBottom: "20px",
                  }}
                >
                  You can see them now and can take action on them.
                </Box>

                <Box
                  sx={{
                    padding: "15px",
                    borderRadius: "12px",
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 3px",
                    background: "white",
                    width: "100%",
                    height: { xs: "200px", sm: "170px", md: "200px" },
                  }}
                >
                  <div
                    style={{
                      background: "#F5F5F5",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%",
                      justifyContent: "center",
                      borderRadius: "12px",
                    }}
                  >
                    <Box
                      className={classes.buttonsContainer}
                      sx={{ width: { xs: "50%", sm: "80%", md: "80%" } }}
                    >
                      <Button
                        variant="contained"
                        onClick={onShowImage}
                        className={classes.yesButton}
                      >
                        Show me my matches
                      </Button>
                    </Box>
                    <div className={classes.bottomLine}>
                      {/* <MdErrorOutline color="green" /> */}
                      By clicking this button, {"you're"} consenting to see NSFW
                      images.
                    </div>
                  </div>
                </Box>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Step5;
