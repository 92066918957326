import { combineReducers } from "redux";
import authReducer from "./Auth/reducer";
import user from "./User/reducer";
import matches from "./Matches/reducer";
import DMCA from "./DMCA/reducer";
import Uploads from "./Uploads/reducer";
import Subscriptions from "./Subscriptions/reducer";
import Ownurls from "./OwnUrl/reducer";
import alerts from "./Alert/reducer";

const rootReducer = combineReducers({
  authReducer,
  user,
  matches,
  DMCA,
  Subscriptions,
  Uploads,
  Ownurls,
  alerts,
});

export default rootReducer;
