import { makeStyles } from "@mui/styles";
import React, { useState, useEffect } from "react";
import { Checkbox, Chip, FormControlLabel, useMediaQuery } from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import CustomPopup from "../UI/CustomPopup.jsx";
import Image from "../../Assets/Images/img1.jpg";
import CustomButton from "../UI/CustomButton.jsx";
import CustomSpinner from "../UI/CustomSpinner.jsx";
import {
  getActiveSubscriptionAction,
  getSubscriptionPlansAction,
  upgradeSubscription,
} from "../../Store/Subscriptions/actions";
import API from "../../Axios/Axios";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      maxWidth: "100vw",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiPaper-root": {
        margin: "10px",
      },
    },
  },
  upgradeModalContainer: {
    display: "flex",
  },
  upgradeRightContainer: {
    flex: 1,
    overflowY: "hidden",

    width: "450px",
    "& img": {
      objectFit: "cover",
      height: "100%",
      width: "450px",
    },
  },
  upgradeLeftContainerPadding: {
    padding: "30px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      padding: "24px 14px",
    },
  },
  upgradeLeftContainer: {
    minWidth: "300px",
    maxWidth: "450px",
    flex: 1,
    "& h2": {
      ...theme.typography.heading36,
      fontWeight: 500,
      margin: 0,
      marginBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
    },
  },
  checkBoxContainer: {
    margin: "15px 0",

    "& .MuiFormControlLabel-root": {
      alignItems: "flex-start",
      "& .MuiButtonBase-root": {
        "&.Mui-disabled": {
          color: theme.palette.grey6,
        },
      },
      "& .MuiTypography-root": {
        "&.Mui-disabled": {
          color: `${theme.palette.grey2} !important`,
        },
      },

      "& p": {
        ...theme.typography.heading24,
        marginTop: "9px",
        fontWeight: 700,
        lineHeight: "24px",
        margin: 0,
        [theme.breakpoints.down("sm")]: {
          fontSize: "20px",
        },
        "& span": {
          ...theme.typography.body16,
          [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
          },
        },
      },
    },
  },
  checkBoxInfo: {
    ...theme.typography.body18,
    marginTop: "9px",
    margin: 0,
    maxWidth: "390px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  infoActive: {
    color: theme.palette.grey2,
  },
  activePlanChip: {
    padding: "5px 7px !important",
    borderRadius: "5px !important",
    backgroundColor: `${theme.palette.lightGreen} !important`,
    "& .MuiChip-label": {
      padding: "0 !important",
      fontSize: "14px !important",
      lineHeight: "16px !important",
      color: theme.palette.white,
    },
  },
  notEligblePlanChip: {
    padding: "5px 7px !important",
    borderRadius: "5px !important",
    backgroundColor: `${theme.palette.grey2} !important`,
    "& .MuiChip-label": {
      padding: "0 !important",
      fontSize: "14px !important",
      lineHeight: "16px !important",
      color: theme.palette.white,
    },
  },
}));

const UpgradePlanDialog = ({ open, onClose, successCallback, reason }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { plans, activeSubscription, loading } = useSelector(
    (state) => state.Subscriptions
  );

  const [checked, setChecked] = useState(0);

  const handleCheck = (value) => {
    setChecked(value);
  };

  const setSubscription = async (planId) => {
    try {
      const { data: responseData } = await API.post(
        "/subscription/subscribe?existing=true",
        {
          priceId: planId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (responseData.success) {
        dispatch(getActiveSubscriptionAction());
        toast.success("Subscription successfull");
        if (successCallback) successCallback();
      } else {
        toast.error("Unable to subscribe.");
      }
    } catch (err) {
      toast.error("Unable to subscribe.");
    }
  };

  const handleClick = () => {
    if (!activeSubscription) {
      // addPaymentSession();
      setSubscription(plans[checked - 1].gateway_identifier);
    } else {
      dispatch(
        upgradeSubscription(
          {
            new_plan: plans[checked - 1].gateway_identifier,
          },
          () => {
            onClose();
          }
        )
      );
    }
  };

  useEffect(() => {
    dispatch(getSubscriptionPlansAction());
  }, [dispatch]);

  useEffect(() => {

    // addSBL();
  }, []);

  return (
    <CustomPopup
      noPadding
      open={Boolean(open)}
      onClose={onClose}
      className={classes.root}
      withClose={!isMobileScreen && !loading}
      imgID="popup-close"
    >
      {loading ? (
        <CustomSpinner />
      ) : (
        <div className={classes.upgradeModalContainer}>
          <div className={classes.upgradeLeftContainer}>
            <div className={classes.upgradeLeftContainerPadding}>
              <h2>{"Update Plan"}</h2>
              {plans.map((plan, i) => (
                <CheckBoxComponent
                  details={plan.description}
                  price={`$${plan.price_in_usd}/month`}
                  name={plan.identifier}
                  key={i}
                  checked={checked === i + 1}
                  onChange={() => handleCheck(i + 1)}
                  activePlan={activeSubscription === plan.gateway_identifier}
                  notEligible={
                    (reason === "takedown" || reason === "savedMatches") &&
                    plan.gateway_identifier === "loti-basic"
                  }
                />
              ))}

              <CustomButton yellowFilled onClick={handleClick}>
                {activeSubscription
                  ? `Switch to ${plans[checked - 1]?.identifier}`
                  : "Subscribe"}
              </CustomButton>
            </div>
          </div>

          {!isMobileScreen && (
            <div className={classes.upgradeRightContainer}>
              <img src={Image} referrerPolicy="no-referrer"/>
            </div>
          )}
        </div>
      )}
    </CustomPopup>
  );
};

export default UpgradePlanDialog;

const CheckBoxComponent = ({
  name,
  price,
  details,
  checked,
  onChange,
  activePlan,
  notEligible,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.checkBoxContainer}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            sx={{
              "& .MuiSvgIcon-root": { fontSize: 28 },
              color: "#408266",
              "&.Mui-checked": {
                color: "#408266",
              },
            }}
            disabled={activePlan || notEligible}
          />
        }
        label={
          <p>
            {name}{" "}
            {activePlan && (
              <Chip label="Current Plan" className={classes.activePlanChip} />
            )}
            {notEligible && (
              <Chip
                label="Not Eligible"
                className={classes.notEligblePlanChip}
              />
            )}
            <br />
            <span>{price}</span>
          </p>
        }
      />
      <p
        className={clsx(
          classes.checkBoxInfo,
          activePlan ? classes.infoActive : ""
        )}
      >
        {details}
      </p>
    </div>
  );
};
