import { useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmailsByRequestAction,
  getRequestsAction,
} from "../../Store/DMCA/actions";
import DMCAMessageBox from "../../Components/DMCAInbox/DMCAMessageBox.jsx";
import DMCAMessages from "../../Components/DMCAInbox/DMCAMessages.jsx";
import CustomSpinner from "../../Components/UI/CustomSpinner.jsx";

const useStyles = makeStyles((theme) => ({
  dmcaInboxContainer: {
    height: "100%",
    display: "flex",
  },
  leftSide: {
    width: "300px",
    boxShadow: " 0px 4px 20px rgba(0, 0, 0, 0.05)",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "calc(100vh - 62px)",
    },
  },
  rightSide: {
    width: "calc(100% - 300px)",
    overflowY: "auto",
    padding: "0 0 0 10px ",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "calc(100vh - 62px)",
      padding: "0",
    },
  },

  spinnerContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const DMCAInbox = () => {
  const classes = useStyles();
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const { requests, loading, emailLoading, emails } = useSelector(
    (state) => state.DMCA
  );

  const [selectedDataKey, setSelectedDataKey] = useState();

  const handleSelect = (id, key) => {
    dispatch(getEmailsByRequestAction(id));
    setSelectedDataKey(key);
  };

  const showLeftContainer = () => {
    if (isMobileScreen && selectedDataKey >= 0) {
      return false;
    }
    return true;
  };

  const showRightContainer = () => {
    if (isMobileScreen && selectedDataKey >= 0) {
      return true;
    }
    if (!isMobileScreen) {
      return true;
    }
    return false;
  };

  const handleMessageBack = () => {
    setSelectedDataKey(undefined);
  };

  useEffect(() => {
    dispatch(getRequestsAction());
  }, [dispatch]);

  return (
    <div className={classes.dmcaInboxContainer}>
      {loading ? (
        <div className={classes.spinnerContainer}>
          <CustomSpinner />
        </div>
      ) : (
        <>
          {showLeftContainer() && (
            <div className={classes.leftSide}>
              <DMCAMessageBox
                data={requests}
                handleSelect={handleSelect}
                selectedDataKey={selectedDataKey}
              />
            </div>
          )}
          {showRightContainer() && (
            <div className={classes.rightSide}>
              <DMCAMessages
                selectedRequest={requests[selectedDataKey]}
                data={emails}
                handleMessageBack={handleMessageBack}
                emailLoading={emailLoading}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DMCAInbox;
