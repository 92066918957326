import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { alpha } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";

// import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { MdAdd } from "react-icons/md";
import placeHolder from "../../Assets/Images/loti-placeholder.jpg";
import CustomButton from "../UI/CustomButton.jsx";
// import DeleteConfirmationDialog from "../Common/ConfirmationDialog.jsx";

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    marginTop: "20px",
    "& button": {
      width: "100%",
      background: theme.palette.white,
      color: theme.palette.black,
      textTransform: "none",
      padding: "12px",
      border: `1px solid ${theme.palette.grey3}`,
      boxShadow: "none",
      "&:hover": {
        background: theme.palette.white,
      },
    },
  },
  btnRow1: {
    display: "flex",
    marginBottom: "10px",
    "& button:first-child": {
      marginRight: "10px",
    },
    "& h3": {
      textAlign: "center",
      color: theme.palette.lightGreen,
    },
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array = [], comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "image_url",
    numeric: false,
    disablePadding: false,
    label: "Image",
  },
  {
    id: "distance",
    numeric: true,
    disablePadding: false,
    label: "Confidence",
  },
  {
    id: "source_type",
    numeric: true,
    disablePadding: false,
    label: "MediaType",
  },
  {
    id: "domain",
    numeric: true,
    disablePadding: false,
    label: "Domain",
  },
  {
    id: "Action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    takedownCount,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          sx={{ background: "#408266", color: "white" }}
        >
          <Checkbox
            indeterminate={
              numSelected > 0 && numSelected + takedownCount < rowCount
            }
            checked={rowCount > 0 && numSelected + takedownCount === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ background: "#408266", color: "white" }}
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={
              headCell.id !== "image_url" && headCell.id !== "Action"
                ? orderBy === headCell.id
                  ? order
                  : false
                : undefined
            }
          >
            {headCell.id === "image_url" && "Image"}
            {headCell.id === "Action" && "Action"}
            {headCell.id !== "image_url" && headCell.id !== "Action" && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                style={{ color: "white" }}
              >
                {headCell.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  takedownCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, buttonActions, subscriptionsRedux } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Searches
        </Typography>
      )}

      {subscriptionsRedux.checkSubscription ?
       numSelected > 0 ? (
        <Button
          variant="contained"
          style={{ backgroundColor: "#408266", padding: "7px 20px" }}
          onClick={() => buttonActions.selectedTakedownFunc()}
        >
          <span style={{ whiteSpace: "nowrap" }}> Takedown {numSelected}</span>
        </Button>
      ) : (
        <Button
          variant="contained"
          style={{
            backgroundColor: "#408266",
            marginLeft: "7px",
            padding: "7px 20px",
          }}
          onClick={() => buttonActions.takeDownallFunc()}
        >
          <span style={{ whiteSpace: "nowrap" }}> TakeDown All</span>
        </Button>
      ):""}

      {/* </div> */}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function MatchesTableView({
  data,
  buttonActions,
  // setSelected,
  // selected,
  handleDelete,
  subscriptionsRedux,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Confidence");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [previewImage, setPreviewImage] = React.useState(null);
  const [visibleRows, setVisibleRows] = React.useState([]);
  const classes = useStyles();

  // const [showConfirmation, setShowConfirmation] = React.useState(false);
  // const [singleSelect, setSingleSelect] = React.useState({});
  const [totalImageRetries, settotalImageRetries] = React.useState(0);

  // const toggleConfirmation = () => {
  //   setShowConfirmation((current) => !current);
  // };

  // const handleDeleteConfirmation = () => {
  //   toggleConfirmation();
  // };

  // React.useEffect(() => console.log("rendered", data), []);

  React.useEffect(() => {
    const rowsToshow = stableSort(data, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    setVisibleRows(rowsToshow);
  }, [order, orderBy, page, rowsPerPage, data]);

  const handleImageClick = (e, imageSrc) => {
    e.stopPropagation();
    setPreviewImage(imageSrc);
  };

  const handleClosePreview = () => {
    setPreviewImage(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data
        .map((n) => !n.takedown_id && n._id)
        .filter((n) => n !== false);
      // newSelected = newSelected.
      setSelected(newSelected);
      return;
    }

    setSelected([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (
      event.target.tagName.toLowerCase() === "img" ||
      event.target.tagName.toLowerCase() === "button"
    ) {
      return;
    }
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const getMatchType = (distance) => {
    if (distance <= 100) return "100%";
    if (distance <= 200) return "95%";
    if (distance <= 300) return "90%";
    if (distance <= 400) return "80%";
    if (distance <= 500) return "75%";
    if (distance <= 600) return "70%";
    if (distance <= 700) return "65%";

    return "60%";
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const setDefaultURL = (row, ev) => {
    if (totalImageRetries === 0) {
      ev.currentTarget.src = row.faceSignedUrl;
      ev.currentTarget.onError = null;
      settotalImageRetries(1);
    } else ev.currentTarget.src = placeHolder;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          buttonActions={buttonActions}
          selected={selected}
          subscriptionsRedux={subscriptionsRedux}
        />
        {/* <DeleteConfirmationDialog
          open={showConfirmation}
          onClose={toggleConfirmation}
          handleConfirmation={handleDeleteConfirmation}
          heading="Are you sure this isn't you ?"
          body="We will not show you this result again."
          confirmButtonName="Yes"
          cancelButtonName="No"
        /> */}
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              takedownCount={data.filter((item) => item.takedown_id).length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={
                      !row.takedown_id
                        ? (event) =>
                            subscriptionsRedux.checkSubscription
                              ? handleClick(event, row._id)
                              : null
                        : null
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selected={isItemSelected}
                    sx={{
                      cursor: "pointer",
                      backgroundColor:
                        row.takedown_status === "completed" ? "#CCCCCC" : "",
                    }}
                  >
                    <TableCell padding="checkbox">
                      {subscriptionsRedux.checkSubscription && (
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          style={{ display: row.takedown_id ? "none" : "" }}
                        />
                      )}
                    </TableCell>

                    <TableCell align="left">
                      <img
                        src={
                          row.source_type === "image"
                            ? row.image_url
                            : row.frameSignedUrl
                        }
                        alt="Placeholder"
                        style={{
                          maxHeight: "100px",
                          cursor: "pointer",
                          maxWidth: "150px",
                        }}
                        onClick={(e) => {
                          handleImageClick(e, row.image_url);
                        }}
                        onError={(e) => setDefaultURL(row, e)}
                      />
                      {previewImage && (
                        <div
                          style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "rgba(0, 0, 0, 0.8)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 9999,
                          }}
                        >
                          <img
                            src={previewImage}
                            alt="Full Preview"
                            style={{ maxHeight: "90%", maxWidth: "90%" }}
                          />
                          <button
                            style={{
                              position: "absolute",
                              top: "16px",
                              right: "16px",
                              color: "#fff",
                              background: "transparent",
                              border: "none",
                              cursor: "pointer",
                              fontSize: "24px",
                            }}
                            onClick={handleClosePreview}
                          >
                            &times;
                          </button>
                        </div>
                      )}
                    </TableCell>
                    <TableCell component="a" align="left">
                      {getMatchType(row.distance)}
                    </TableCell>
                    <TableCell align="left">{row.source_type}</TableCell>
                    <TableCell align="left">{row.domain}</TableCell>
                    <TableCell
                      // onClick={(e) => {
                      //   e.stopPropagation()
                      //   window.open(row.Url, "_blank");
                      // }}
                      align="left"
                    >
                      {/* <VisibilityIcon /> */}
                      <div className={classes.buttonsContainer}>
                        <div className={classes.btnRow1}>
                          {!row.takedown_id ? (
                            <>
                              <CustomButton
                                yellow
                                onClick={() => buttonActions.onTakeDown(row)}
                              >
                                <MdAdd />
                                TakeDown
                              </CustomButton>
                              <Button
                                variant="contained"
                                onClick={() => handleDelete(row)}
                              >
                                Not me
                              </Button>
                            </>
                          ) : row.takedown_status === "completed" ? (
                            <h3 style={{ color: "black" }}>
                              Takedown Completed.
                            </h3>
                          ) : (
                            <h3>Takedown requested.</h3>
                          )}
                        </div>
                      </div>
                    </TableCell>

                    {/* <TableCell align="left">{row.Date}</TableCell> */}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
