import Alerts from "../Pages/Alerts/Alerts.jsx";
import Matches from "../Pages/Matches/Matches.jsx";
import Search from "../Pages/Search/Search.jsx";
import TakedownRequests from "../Pages/TakedownRequests/TakedownRequests.jsx";
import UploadMedia from "../Pages/UploadMedia/UploadMedia.jsx";
import DMCAInbox from "../Pages/DMCAInbox/DMCAInbox.jsx";
import Profile from "../Pages/Profile/Profile.jsx";
import Billing from "../Pages/Billing/Billing.jsx";
import Security from "../Pages/Security/Security.jsx";
import AddYourOwnUrl from "../Pages/AddYourOwnUrl/AddYourOwnUrl.jsx";
import TableView from "../Pages/TableView/TableView.jsx";
import VerifiedImage from "../Pages/VerifiedImage/VerifiedImage.jsx";
import DeepFakes from "../Pages/DeepFakes/DeepFakes.jsx";


const authProtectedRoutes = [
  { path: "/alerts", component: Alerts },
  { path: "/matches", component: Matches },
  { path: "/search", component: Search },
  { path: "/table-view", component: TableView },

  { path: "/takedown-requests", component: TakedownRequests },
  { path: "/uploaded-media", component: UploadMedia },
  { path: "/deep-fakes", component: VerifiedImage },
  { path: "/deepfake/verified/:id", component: DeepFakes,  noSideMenu: true, },



  {
    path: "/dmca-inbox",
    component: DMCAInbox,
    noSideMenu: true,
    noMobileHead: true,
  },
  {
    path: "/account/profile",
    component: Profile,
  },
  {
    path: "/account/security",
    component: Security,
  },
  {
    path: "/account/billing",
    component: Billing,
  },
  {
    path: "/bring-own-url",
    component: AddYourOwnUrl,
  },
];

export default authProtectedRoutes;
