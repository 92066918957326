import { Grid, IconButton, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  MdArrowBackIosNew,
  MdArrowForwardIos,
  MdErrorOutline,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import blackGroundImage from "../../Assets/Images/blurred_image.png";
import placeholder from "../../Assets/Images/loti-placeholder.jpg";
import CustomButton from "../UI/CustomButton.jsx";

const useStyles = makeStyles((theme) => ({
  step5Container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    fontSize: "24px",
    fontWeight: 600,
    // justifyContent: "center",
    // backgroundImage: `url(${blackGroundImage})`,
    // padding: "70px 0",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
      paddingBottom: "100px",
    },
    "& h2": {
      ...theme.typography.heading36,
      maxWidth: "200px",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
    },
  },
  root: {
    flexGrow: 1,
    position: "relative",
    marginTop: "12px !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: "8px !important",
    },
  },
  noExactMatch: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    backgroundImage: `url(${blackGroundImage})`,
    padding: "70px 0",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
      paddingBottom: "100px",
    },
    "& h2": {
      ...theme.typography.heading36,
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
    },
    "& p": {
      ...theme.typography.body24,
      maxWidth: "80%",
      textAlign: "center",
    },
  },
  yellow: {
    color: theme.palette.yellow,
    fontWeight: 600,
  },
  bottomLine: {
    marginTop: "20px",
    ...theme.typography.body16,
    display: "flex",
    alignItems: "center",
    "& svg": {
      color: theme.palette.yellow,
      marginRight: "10px",
    },
  },
  button: {
    background: `${theme.palette.lightGreen} !important`,
    color: `${theme.palette.white} !important`,
    textTransform: "capitalize !important",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "16px !important",
    fontWeight: "500 !important",
    minHeight: "35px",
    minWidth: "150px !important",
    marginTop: "10px !important",
    marginBottom: "10px !important",
    [theme.breakpoints.down("sm")]: {
      minHeight: "30px",
      minWidth: "120px !important",
      fontSize: "12px !important",
    },
  },
  buttonsContainer: {
    marginTop: "20px",
    width: "100%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  yesButton: {
    marginRight: "7px !important",
    fontSize: "20px !important",
    height: "100px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
      marginRight: "0px !important",
      maxWidth: "240px !important",
      height: "auto !important",
    },
  },
  noButton: {
    marginLeft: "7px !important",
    fontSize: "20px !important",
    height: "100px !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px !important",
      fontSize: "16px !important",
      marginLeft: "0px !important",
      maxWidth: "240px !important",
      height: "auto !important",
    },
  },
  bg_green: {
    color: `${theme.palette.white} !important`,
    background: `${theme.palette.lightGreen} !important`,
  },
  headTitle: {
    ...theme.typography.heading36,
    margin: 0,
    marginBottom: "25px",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
      textAlign: "center",
      marginBottom: "5px",
      fontSize: "24px",
    },
  },
  yesButton2: {
    fontSize: "20px !important",
    height: "70px !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px !important",
      fontSize: "16px !important",
      marginRight: "0px !important",
      maxWidth: "300px !important",

      height: "40px !important",
    },
  },
  noButton2: {
    marginTop: "20px !important",
    fontSize: "20px !important",
    height: "70px !important",
    fontWeight: "400 !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px !important",
      height: "40px !important",
      fontSize: "16px !important",
      marginLeft: "0px !important",
      maxWidth: "300px !important",
    },
  },
  para: {
    ...theme.typography.body24,
    textAlign: "center",
    margin: 0,
    marginTop: "10px",
  },
  image: {
    width: "100%",
    height: "400px",
    objectFit: "contain",
  },
  userImg: {
    height: "400px",
    [theme.breakpoints.up(1700)]: {
      height: "400px",
    },
    width: "100%",
    objectFit: "cover",
    objectPosition: "top"
  },
}));
const Step5 = ({ onNext, noExactMatch, searches, setProgress }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const gridRef = React.useRef(null);

  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [showImageCarousel, setShowImageCarousel] = useState(false);
  const [counter, setCounter] = useState(0);
  const [isReview, setIsReview] = React.useState(false);

  const onShowImage = () => {
    setShowImageCarousel(true);
  };

  const handleNo = () => {
    setProgress({ key: 7, matchFound: false, needToShowGreatNews: false });
  };

  const handleYes = () => {
    setProgress({ key: 7, matchFound: true, needToShowGreatNews: false });
  };

  const toggleSearches = () => {
    setIsReview(!isReview);
  };

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.scrollIntoView({ behavior: "smooth" });
    }
  });

  return (
    <>
      {showImageCarousel ? (
        <Grid container justifyContent="center">
          <Grid item sm={6}>
            <h2 className={classes.headTitle}>Are any of these you?</h2>

            {!isMobileScreen && (
              <>
                <CustomButton
                  yellow
                  className={classes.yesButton2}
                  onClick={handleYes}
                >
                  Yes
                </CustomButton>
                <CustomButton className={classes.noButton2} onClick={handleNo}>
                  No
                </CustomButton>
              </>
            )}
          </Grid>

          <Grid
            item
            sm={6}
            container
            alignItems="center"
            justifyContent={{ sm: "flex-end", xs: "center" }}
          >
            <Grid item container justifyContent="center" xs={2}>
              <IconButton
                onClick={() => setCounter((current) => current - 1)}
                disabled={counter === 0}
              >
                <MdArrowBackIosNew />
              </IconButton>
            </Grid>
            <Grid item xs={8}>
              <img
                referrerPolicy="no-referrer"
                className={classes.image}
                src={searches[counter].faceSignedUrl}
                onError={(ev) => {
                  ev.currentTarget.src = placeholder;
                  ev.currentTarget.onError = null;
                }}
              />
              <br />
              <p className={classes.para}>
                {counter + 1} / {searches.length}
              </p>
            </Grid>
            <Grid item container justifyContent="center" xs={2}>
              <IconButton
                onClick={() => setCounter((current) => current + 1)}
                disabled={counter === searches.length - 1}
              >
                <MdArrowForwardIos />
              </IconButton>
            </Grid>
          </Grid>
          {isMobileScreen && (
            <Grid item container justifyContent="center">
              <CustomButton
                yellow
                className={classes.yesButton2}
                onClick={handleYes}
              >
                Yes
              </CustomButton>
              <CustomButton className={classes.noButton2} onClick={handleNo}>
                No
              </CustomButton>
            </Grid>
          )}
        </Grid>
      ) : (
        <>
          {noExactMatch ? (
            <div className={classes.noExactMatch}>
              <h2>We found no exact matches.</h2>
              <p>
                However, we did find{" "}
                <span className={classes.yellow}>{searches.length}</span> close
                matches. These images have a low probability they are you. We do
                recommend you checking to confirm. Would you like to check now?
              </p>
              <div className={classes.bottomLine}>
                <MdErrorOutline />
                By clicking yes you will see NSFW images.
              </div>
              <div className={classes.buttonsContainer}>
                <CustomButton
                  yellow
                  onClick={onShowImage}
                  className={classes.yesButton}
                >
                  Yes
                </CustomButton>
                <CustomButton
                  className={classes.noButton}
                  onClick={() => navigate("/")}
                >
                  No
                </CustomButton>
              </div>
            </div>
          ) : (
            <>
              <div className={classes.noExactMatch}>
                <h2>
                  We found {searches.filter((e) => e.distance <= 400).length}
                  &nbsp; results that matches your face.
                </h2>
                <p>
                  You can see them now or click &#8221;Next&#8221; to take
                  action on them
                </p>
                <div className={classes.bottomLine}>
                  <MdErrorOutline />
                  By clicking Show Images, you will see NSFW images.
                </div>
                <div className={classes.buttonsContainer}>
                  <CustomButton
                    yellow
                    onClick={toggleSearches}
                    className={classes.yesButton}
                  >
                    Show Images
                  </CustomButton>
                  <CustomButton
                    className={`${classes.bg_green} ${classes.noButton}`}
                    onClick={onNext}
                  >
                    Next
                  </CustomButton>
                </div>
              </div>
              {isReview && (
                <div ref={gridRef} style={{ marginTop: "20px" }}>
                  <Grid container spacing={0.75}>
                    {searches
                      .filter((e) => e.distance <= 400)
                      .map((e, key) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
                          <img
                            referrerPolicy="no-referrer"
                            src={e.faceSignedUrl}
                            onError={(ev) => {
                              ev.currentTarget.src = placeholder;
                              ev.currentTarget.onError = null;
                            }}
                            className={classes.userImg}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Step5;
