import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CustomButton from "../UI/CustomButton.jsx";

const useStyles = makeStyles((theme) => ({
  step1Container: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      width: "40%",
    },
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    "& h2": {
      textAlign: "center",
      ...theme.typography.heading28,
      margin: 0,
      marginBottom: "25px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "27px",
        lineHeight: "1.2",
        textAlign: "center",
        marginBottom: "30px",
      },
    },
    "& p": {
      textAlign: "center",
      ...theme.typography.body24,
      margin: 0,
      marginBottom: "25px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "20px",
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "12px",
    padding: "5%",
    background: "white",
    width: "87%",

    // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 3px",
    // boxShadow:" var(0 0 #0000,0 0 #0000),var(0 0 #0000,0 0 #0000),var(0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1))",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  yesButton: {
    boxShadow: "none !important",
    fontSize: "14px !important",
    marginTop: "20px !important",
    color: "white !important",
    height: "40px !important",
    backgroundColor: "#528068 !important",
    width: "100% !important",
    borderRadius: "6px !important",
    fontWeight: "500 !important",
    textTransform: "none !important",
    "&:hover": {
      background: theme.palette.darkGreen,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "13px !important",

      height: "auto !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
      marginRight: "0px !important",
      // maxWidth: "240px !important",
      height: "40px !important",
    },
  },

  weight400: {
    fontWeight: "400 !important",
  },
}));

const StepType1 = ({ onClickNo, onClickYes, heading, inverseButton }) => {
  const [age, setAge] = React.useState("");
  const [error, setError] = React.useState(false);

  const classes = useStyles();
  const handleNext = () => {
    if (age !== "") {
      if (age === "above") {
        onClickYes();
      } else {
        onClickNo();
      }
    }
    setError(true);
  };
  return (
    <div
      className={classes.step1Container}
      style={{
        fontFamily:
          "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji",
      }}
    >
      <h2>{heading}</h2>
      {/* <p>{subText}</p> */}
      <div className={classes.buttonsContainer}>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="above"
            name="radio-buttons-group"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderRadius: 10,
                border: "2px solid gray",
                padding: "10px 8px",
                cursor: "pointer",
                borderColor: age === "above" ? "#408266" : "#e5e5e5",
                // borderWidth: age === "above" ? "2px" : "1.8px",
              }}
              onClick={() => setAge("above")}
            >
              <FormControlLabel
                value="above"
                control={
                  <Radio
                    // color="success"
                    sx={{
                      color: "#a9a9a9",
                      "&.Mui-checked": {
                        color: "#408266",
                      },
                    }}
                  />
                }
                label=""
                checked={age === "above" && true}
                sx={{ margin: 0 }}
              />
              <Box
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "12px",
                    md: "14px",
                  },
                  fontWeight: 600,
                }}
              >
                I am aged 18 or over.
              </Box>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderRadius: 10,
                border: "2px solid gray",
                padding: "10px 8px",
                marginTop: 8,
                cursor: "pointer",
                borderColor: age === "below" ? "#408266" : "#e5e5e5",
                // borderWidth: age === "below" ? "2px" : "1.8px",
              }}
              onClick={() => setAge("below")}
            >
              <FormControlLabel
                value="below"
                control={
                  <Radio
                    sx={{
                      color: "#a9a9a9",
                      "&.Mui-checked": {
                        color: "#408266",
                      },
                    }}
                  />
                }
                label=""
                checked={age === "below" && true}
                sx={{ margin: 0 }}
              />
              <Box
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "12px",
                    md: "14px",
                  },
                  fontWeight: 600,
                }}
              >
                I am aged 17 or under.
              </Box>
            </div>
          </RadioGroup>

          <Box
            sx={{
              fontWeight: 400,
              marginTop: "15px",
              color: "gray",
              fontSize: {
                xs: "11px",
                sm: "11px",
                md: "12px",
              },
              textAlign: "center",
            }}
          >
            By clicking next, you agree to our{" "}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => window.open("https://goloti.com/terms/")}
            >
              <u>Terms of Service</u>
            </span>
            <br />
          </Box>
          <CustomButton
            // yellow={!inverseButton}
            onClick={handleNext}
            className={clsx(
              classes.yesButton,
              inverseButton ? classes.weight400 : ""
            )}
          >
            Next
          </CustomButton>
          {error && (
            <Box
              sx={{
                color: "red",
                textAlign: "center",
                marginTop: "10px",
                fontSize: {
                  xs: "12px",
                  sm: "12px",
                  md: "14px",
                },
              }}
            >
              Please select an option before proceeding.
            </Box>
          )}
        </FormControl>
      </div>
    </div>
  );
};

export default StepType1;
