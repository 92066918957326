import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import React from "react";

const useStyles = makeStyles((theme) => ({
  step5Container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    padding: "20px 0",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
      paddingBottom: "100px",
    },
  },
  button: {
    background: `${theme.palette.lightGreen} !important`,
    color: `${theme.palette.white} !important`,
    textTransform: "capitalize !important",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "20px !important",
    fontWeight: "500 !important",
    minHeight: "65px",
    minWidth: "210px !important",
    [theme.breakpoints.down("sm")]: {
      minHeight: "40px",
      minWidth: "180px !important",
      fontSize: "16px !important",
    },
  },
  step1Container: {
    "& h2": {
      textAlign: "center",
      ...theme.typography.heading36,
      margin: 0,
      marginBottom: "25px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "5px",
        fontSize: "24px",
      },
    },
    "& p": {
      textAlign: "center",
      ...theme.typography.body24,
      margin: 0,
      marginBottom: "25px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "20px",
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
  },
  yesButton: {
    marginRight: "7px !important",
    fontSize: "20px !important",
    height: "100px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
      marginRight: "0px !important",
      maxWidth: "240px !important",
      height: "auto !important",
    },
  },
  noButton: {
    marginLeft: "7px !important",
    fontSize: "20px !important",
    height: "100px !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px !important",
      fontSize: "16px !important",
      marginLeft: "0px !important",
      maxWidth: "240px !important",
      height: "auto !important",
    },
  },
  weight400: {
    fontWeight: "400 !important",
  },
}));

const StepFromAds = ({
  onClickTryNow,
  heading,
  subText,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.step1Container}>
      <h2>{heading}</h2>
      <p>{subText}</p>
      <div className={classes.step5Container}>
          <Button  
              onClick={onClickTryNow}
              className={classes.button}>
                Try Now for Free
          </Button>
            </div>
    </div>
  );
};

export default StepFromAds;
