import React, { useState } from "react";
import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  StyledEngineProvider,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import AuthWrapper from "../../Components/AuthWrapper/AuthWrapper.jsx";
import LockIcon from "../../Assets/Images/lock_icon2.png";
import CustomTextField from "../../Components/UI/CustomTextField.jsx";
import Constants from "../../Assets/Constants/index";
import { isPasswordValid } from "../../Helpers/functions";
import { resetPasswordAction } from "../../Store/Auth/actions";
import CustomSpinner from "../../Components/UI/CustomSpinner.jsx";

const useStyles = makeStyles((theme) => ({
  backIcon: {
    fontSize: "25px",
    marginRight: "5px",
  },
  backContainer: {
    display: "flex",
    alignItems: "center",
    height: "20px",
    cursor: "pointer",
  },
  bodyContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "20px",
    height: "calc(100% - 40px)",
  },
  lockIcon: {
    height: "60px",
    width: "60px",
    marginBottom: "20px",
  },
  headText: {
    ...theme.typography.heading32,
    textAlign: "center",
  },
  bodyText: {
    ...theme.typography.body18,
    marginTop: "20px",
    textAlign: "center",
    marginBottom: "20px",
  },

  button: {
    ...theme.typography.button,
    color: theme.palette.white,
    background: theme.palette.lightGreen,
    width: "100%",
    margin: "5px",
    marginTop: "10px",
    height: "55px",
    textTransform: "none",
    "&:hover": {
      background: theme.palette.darkGreen,
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "50px",
    },
  },
  alert: {
    marginTop: "-7px",
    marginBottom: "10px",
  },
  helperText: {
    color: theme.palette.black,
  },
}));
const CreateNewPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const token = pathname.split("/")[2];

  const {
    loading,
    error: apiError,
    errorMessage,
  } = useSelector((state) => state.authReducer);

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    useState(false);
  const [credentials, setCredentials] = useState({
    password: "",
    confirm_password: "",
  });
  const [error, setError] = useState({
    password: "",
    confirm_password: "",
  });

  const validateFields = () => {
    let valid = true;
    const errorObj = { password: "", confirm_password: "" };

    if (!isPasswordValid(credentials.password)) {
      valid = false;
      if (!credentials.password) {
        errorObj.password = Constants.PASSWORD_REQUIRED;
      } else {
        errorObj.password = Constants.INVALID_PASSWORD;
      }
    }
    if (credentials.password !== credentials.confirm_password) {
      valid = false;
      errorObj.confirm_password = Constants.PASSWORD_MISMATCH;
    }
    setError(errorObj);
    return valid;
  };

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (validateFields()) {
      dispatch(
        resetPasswordAction(
          {
            new_password: credentials.password,
            confirm_new_password: credentials.confirm_password,

            token,
          },
          navigate
        )
      );
    }
  };
  return (
    <AuthWrapper hideBottomLine boxWithoutImage>
      <StyledEngineProvider injectFirst>
        <div className={classes.bodyContainer}>
          <img src={LockIcon} alt="lock icon" className={classes.lockIcon} referrerPolicy="no-referrer"/>
          <Typography className={classes.headText}>
            Create New Password
          </Typography>
          <Typography className={classes.bodyText}>
            Your new password must be different from previous used passwords.
          </Typography>
          {apiError && (
            <Alert className={classes.alert} severity="error">
              {errorMessage.replace("jwt", "link")}
            </Alert>
          )}
          <CustomTextField
            label="Password"
            type={passwordVisibility ? "text" : "password"}
            name="password"
            variant="outlined"
            value={credentials.password}
            onChange={handleChange}
            disabled={loading}
            error={Boolean(error.password)}
            helperText={
              error.password ? (
                error.password
              ) : (
                <span className={classes.helperText}>
                  Must be at least 8 characters.
                </span>
              )
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                    edge="end"
                  >
                    {passwordVisibility ? (
                      <MdOutlineVisibility />
                    ) : (
                      <MdOutlineVisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <CustomTextField
            label="Confirm password"
            type={confirmPasswordVisibility ? "text" : "password"}
            name="confirm_password"
            variant="outlined"
            value={credentials.confirm_password}
            onChange={handleChange}
            disabled={loading}
            error={Boolean(error.confirm_password)}
            helperText={
              error.confirm_password ? (
                error.confirm_password
              ) : (
                <span className={classes.helperText}>
                  Both passwords must match.
                </span>
              )
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setConfirmPasswordVisibility(!confirmPasswordVisibility)
                    }
                    edge="end"
                  >
                    {confirmPasswordVisibility ? (
                      <MdOutlineVisibility />
                    ) : (
                      <MdOutlineVisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {loading ? (
            <CustomSpinner />
          ) : (
            <Button
              variant="contained"
              className={classes.button}
              onClick={handleSubmit}
            >
              Reset Password
            </Button>
          )}
        </div>
      </StyledEngineProvider>
    </AuthWrapper>
  );
};

export default CreateNewPassword;
