import { Button } from "@mui/material";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const PaymentTesting = () => {
  const handleClick = () => {
    const fscSession = {
      reset: true,
      products: [
        {
          path: "loti-basic",
          quantity: 1,
        },
      ],
      paymentContact: {
        email: "roshilW@g.com",
      },
    };

    window.fastspring.builder.push(fscSession);
    window.fastspring.builder.checkout();
  };
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <script
            id="fsc-api"
            src="https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.9.1/fastspring-builder.js"
            type="text/javascript"
            data-storefront={
              process.env.REACT_APP_MODE === "staging"
                ? "lotillc.test.onfastspring.com/popup-lotillc"
                : "lotillc.onfastspring.com/popup-lotillc"
            }
          ></script>
        </Helmet>
      </HelmetProvider>
      {/* <div id="fsc-embedded-checkout-container"></div> */}

      <Button onClick={handleClick}>Click Me</Button>
    </div>
  );
};

export default PaymentTesting;
