import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomSpinner from "../../Components/UI/CustomSpinner.jsx";
import { validateToken } from "../../Store/Auth/actions";

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    minHeight: "100vh",
  },
  errorMessage: {
    color: theme.palette.red,
  },
}));

const EmailVerification = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { error, errorMessage } = useSelector((state) => state.authReducer);

  const token = pathname.split("/")[2];

  useEffect(() => {
    dispatch(validateToken(token));
  }, [dispatch, token]);
  return (
    <div className={classes.center}>
      {error ? (
        <Typography variant="heading24" className={classes.errorMessage}>
          {errorMessage.replace("jwt", "link")}
        </Typography>
      ) : (
        <CustomSpinner center />
      )}
    </div>
  );
};

export default EmailVerification;
