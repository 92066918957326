import { Grid, MenuItem, Select, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  customInputContainer: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  label: {
    ...theme.typography.body20,
    color: theme.palette.black,
    "& span": {
      ...theme.typography.body16,
    },

    [theme.breakpoints.down("sm")]: {
      ...theme.typography.body18,
      "& span": {
        fontSize: "14px",
      },
    },
  },
  inputBox: {
    width: "100%",
    ...theme.typography.body16,
    color: theme.palette.black,
    "& .MuiInputBase-root": {
      background: `${theme.palette.lightGreen2} !important`,
    },
    "& input": {
      padding: "8px 20px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  select: {
    width: "100%",
    ...theme.typography.body16,
    color: theme.palette.black,
    background: theme.palette.lightGreen2,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiSelect-outlined": {
      padding: "8px 20px !important",
    },
  },
}));

const CustomFormInput = ({
  select,
  options,
  label,
  optional,
  full,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems="center"
      className={classes.customInputContainer}
    >
      <Grid item xs={4} sm={4} md={full ? 3 : 2} lg={full ? 3 : 2}>
        <label className={classes.label}>
          {label}
          {optional && <span> (optional)</span>}
        </label>
      </Grid>
      <Grid item xs={8} sm={8} md={full ? 9 : 6} lg={full ? 9 : 6}>
        {select ? (
          <Select className={classes.select} value="India" {...rest}>
            {options.map((option, key) => (
              <MenuItem value={option} key={key}>
                {option}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <TextField
            variant="outlined"
            {...rest}
            className={classes.inputBox}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CustomFormInput;
