import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  customSpinner: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    background: `conic-gradient(#0000 1%, ${theme.palette.yellow})`,
    WebkitMask:
      "radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0)",
    animation: "$load-spin 0.8s infinite linear",
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      height: "80px",
    },
  },
  "@keyframes load-spin": {
    to: {
      transform: "rotate(1turn)",
    },
  },
}));

const CustomSpinner2 = () => {
  const classes = useStyles();
  return <div className={classes.customSpinner}></div>;
};

export default CustomSpinner2;
