import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

import Step4 from "../../Components/OnboardingNew/Step4.jsx";
import StepHiddenResult from "../../Components/OnboardingNew/StepHiddenResult.jsx";
import StepActionPermission from "../../Components/OnboardingNew/StepActionPermission.jsx";
import StepType1 from "../../Components/OnboardingNew/StepType1.jsx";
import StepFromAds from "../../Components/OnboardingNew/StepFromAds.jsx";
import StepUpload from "../../Components/OnboardingNew/StepUpload.jsx";
import CustomButton from "../../Components/UI/CustomButton.jsx";
import StepEmail from "../../Components/OnboardingNew/StepEmail.jsx";
import Script from "../../Components/Script/Script.jsx";
import AccountAlert from "../../Components/OnboardingNew/AccountAlert.jsx";
import { plans } from "../../constants";
import NCMEC from "../../Components/OnboardingNew/NCMEC.jsx";
import StepFormNew from "../../Components/OnboardingNew/StepFormNew.jsx";
import Paymentform from "../../Components/OnboardingNew/Paymentform.jsx";
import { getSubscriptionPlansAction } from "../../Store/Subscriptions/actions";
import CongratulationComponent from "../../Components/OnboardingNew/CongrulationComponent.jsx"


const useStyles = makeStyles((theme) => ({
  pageContainer: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "0 10px",
    background: "#F5F5F5",
  },

  onBoardingContainer: {
    width: "70vw",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  row1: {
    marginTop: "30px",
    marginBottom: "24px",
    "& img": {
      height: "36px",
      width: "72px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "11px",
      marginBottom: "20px",
      "& img": {
        height: "26px",
        width: "53px",
      },
    },
  },
  extraMarginTopForRow: {
    marginTop: "109px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "60px",
    },
  },
  counter: {
    margin: 0,
    ...theme.typography.body20,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  progress: {
    "&.MuiLinearProgress-root": {
      height: "8px ",
      backgroundColor: theme.palette.grey4,
      [theme.breakpoints.down("sm")]: {
        borderRadius: "5px",
      },
    },
    "& .MuiLinearProgress-bar": {
      backgroundColor: theme.palette.lightGreen,
    },
  },
  backContainer: {
    width: "100%",
  },
  back: {
    marginLeft: "80px",
    cursor: "pointer",
    marginTop: "45px",
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: "23px",
    },
    "& p": {
      ...theme.typography.body20,
      margin: 0,
      marginLeft: "5px",
    },

    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      marginTop: "15px",
      fontSize: "16px",
      "& p": {
        fontSize: "14px",
      },
      "& svg": {
        fontSize: "16px",
      },
    },
  },

  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "35px",
    width: "100%",
  },

  exitStepperContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& img": {
      marginTop: "100px",
    },
  },
  exitStepperButton: {
    height: "65px !important",
    width: "200px !important",
    fontSize: "20px !important",
    [theme.breakpoints.down("sm")]: {
      height: "auto !important",
      marginTop: "35px !important",
      fontSize: "16px !important",
    },
  },
  exitStepperHead: {
    marginTop: "100px",
    marginBottom: "70px",
    textAlign: "center",
    margin: 0,
    ...theme.typography.heading36,
    "& a": {
      color: theme.palette.lightGreen,
      textDecoration: "none",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px",
      marginBottom: "5px",
      fontSize: "24px",
      lineHeight: "34px",
      "& a": {
        textDecoration: "underline",
      },
    },
  },
  c2: {
    marginBottom: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  width: {
    width: "65%",
    [theme.breakpoints.down("lg")]: {
      width: "78%",
    },
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const Onboarding = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  // const { referrer } = document;
  // const refURLs = [
  //   "https://goloti.com/",
  //   "https://app.goloti.com/login",
  //   "https://staging.goloti.com/login",
  // ];
  const dispatch = useDispatch();
  const { plans: planApiData } = useSelector((state) => state.Subscriptions);

  useEffect(() => {
    dispatch(getSubscriptionPlansAction());
  }, []);

  const {
    count,
    faces,
    searches,
    loading: matchLoading,
    progressLoader,
  } = useSelector((state) => state.matches);

  const { loading: uploadsLoading } = useSelector((state) => state.Uploads);

  const [progress, setProgress] = useState({
    key: 1,
    matchFound: false,
    noExactMatch: false,
    exactMatchNumber: 0,
  });

  // const ref =
  //   typeof referrer !== "undefined" &&
  //   referrer.length > 0 &&
  //   refURLs.includes(referrer)
  //     ? 0
  //     : 1;
  const [stepFromAds, setStepFromAds] = useState(0);

  const [exitStepper, setExitStepper] = useState({
    value: false,
    key: null,
  });
  const [data, setData] = useState({
    eighteenPlus: false,
    email: "",
    full_name: "",
    password: "",
    country: "",
    street: "",
    suite: "",
    city: "",
    state: "",
    zip_code: "",
    expiryDate: undefined,
    contentCreator: false,
    card_name:"",
  });
  const [planDetailsState, setPlanDetailsState] = useState(plans[0]);

  const [faceId, setFaceId] = useState(0);
  const [uploadedImage, setUploadedImage] = useState();
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const location = useLocation();

  const handleInputChange = (e) =>
    setData((current) => ({ ...current, [e.target.name]: e.target.value }));

  const ExitContents = [
    {
      title: (
        <h2 className={classes.exitStepperHead}>
          Please see{" "}
          <a href="https://www.missingkids.org/gethelpnow/isyourexplicitcontentoutthere">
            NCMEC
          </a>{" "}
          which provides free services for people under the age of 18.
        </h2>
      ),
      button: (
        <CustomButton
          yellowFilled
          className={classes.exitStepperButton}
          onClick={() => {
            window.location.href =
              "https://www.missingkids.org/gethelpnow/isyourexplicitcontentoutthere";
          }}
        >
          Go Now!
        </CustomButton>
      ),
    },
    {
      title: (
        <h2 className={clsx(classes.exitStepperHead, classes.width)}>
          You can log back in whenever you are ready to take the next steps.
          {!isMobileScreen && <br />}
          We will be here.
        </h2>
      ),
      button: (
        <CustomButton
          yellowFilled
          className={classes.exitStepperButton}
          onClick={() => {
            window.location.href = "https://goloti.com/";
          }}
        >
          Back to Homepage
        </CustomButton>
      ),
    },
    {
      title: (
        <h2 className={classes.exitStepperHead}>
          Image does not contain any more faces.
        </h2>
      ),
      button: (
        <CustomButton
          yellowFilled
          className={classes.exitStepperButton}
          onClick={() => {
            window.location.href = "https://goloti.com/";
          }}
        >
          Back to Homepage
        </CustomButton>
      ),
    },
  ];

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const utmAffiliate = queryParams.get("utm_affiliate");
    const promoCode = queryParams.get("promo_code");

    // Check if utm_affiliate is not present in the cookies
    const utmAffiliateCookie = Cookies.get("utm_affiliate");
    if (!utmAffiliateCookie) {
      // If not present, set it from the query parameters
      if (utmAffiliate) {
        Cookies.set("utm_affiliate", utmAffiliate, {
          expires: 7,
          sameSite: "none",
        });
      }
    }
    if (promoCode) {
      Cookies.set("promo_code", promoCode, { expires: 7, sameSite: "none" });
    }
  }, [location.search]);

  const showBack = () => {
    if (!exitStepper.value) {
      return true;
    }

    return false;
  };

  const handleNext = () => {
    setProgress((current) => ({ ...current, key: current.key + 1 }));
  };

  const handleBack = () => {
    if (progress.key === 1) {
      navigate("/");
      return;
    }
    if (!(matchLoading || uploadsLoading)) {
      if (progress.key === 6 && progress.matchFound) {
        setProgress((current) => ({
          ...current,
          key: current.key - 1,
          needToShowGreatNews: false,
        }));
      } else if (progress.key === 6 && !progress.matchFound) {

        setProgress((current) => ({ ...current, key: 4 }));
      } else if (progress.key === 4) {

        setProgress((current) => ({ ...current, key: current.key - 2 }));
      } else {
        setProgress((current) => ({ ...current, key: current.key - 1 }));
      }
    }
  };

  // Only for Account Alert

  const handleChange = (e) => {
    setData((current) => ({
      ...current,
      [e.target.name]: e.target.value,
    }));
  };

  const totalSteps = [1, 2, 3, 4, 5, 6];

  const getPlanInfo = () => {
    let selectedPlan;
    if (progress.exactMatchNumber > 2) {
      // eslint-disable-next-line
      selectedPlan = plans[2];
    } else if (
      progress.exactMatchNumber <= 2 &&
      progress.exactMatchNumber !== 0
    ) {
      // eslint-disable-next-line
      selectedPlan = plans[0];
    } else {
      // eslint-disable-next-line
      selectedPlan = plans[1];
    }
    // eslint-disable-next-line
    // selectedPlan = plans[0];
    return selectedPlan;
  };

  useEffect(() => {
    setPlanDetailsState(getPlanInfo());
  }, [getPlanInfo()]);

  const showNextFace = () => {
    if (faces.length - 1 > faceId) {
      setFaceId((current) => current + 1);
      return;
    }
    setExitStepper({ value: true, key: 2 });
  };

  return (
    <div className={classes.pageContainer}>
      <Script />
      {showBack() && (
        <div className={classes.backContainer}>
          <div
            className={classes.back}
            onClick={() => handleBack()}
            style={{
              opacity: matchLoading || uploadsLoading ? 0.3 : 1,
            }}
          >
            <MdOutlineKeyboardBackspace />
            <p>{progress.key === 1 ? "Home" : "Back"} </p>
          </div>
        </div>
      )}
      <div className={classes.onBoardingContainer}>
        {exitStepper.value ? (
          <div className={classes.exitStepperContainer}>
            {exitStepper.key !== 0 && (
              <>
                <img src="./logo.svg" />
                {ExitContents[exitStepper.key].title}

                {ExitContents[exitStepper.key].button}
              </>
            )}
            {exitStepper.key === 0 && (
              <NCMEC
                heading="Let’s get you to someone that can help."
                subText="Loti is only for people over the age of 18. Please see NCMEC which provides free services for people under the age of 18."
                onClickBack={() => {
                  setExitStepper((current) => ({ ...current, value: false }));
                }}
                onClickYes={() =>
                  window.open(
                    "https://www.missingkids.org/gethelpnow/isyourexplicitcontentoutthere"
                  )
                }
              />
            )}
          </div>
        ) : (
          <div className={classes.c2}>
            <Grid
              container
              flexDirection="column"
              // justifyContent="space-between"
              alignItems="center"
              className={clsx(
                classes.row1,
                !showBack() ? classes.extraMarginTopForRow : ""
              )}
            >
              <img
                src="./logo.svg"
                style={{ marginBottom: 30, height: "22px", width: "45px" }}
              />
              {/* <img
                src="./wave_onboarding.png"
                style={{ height: 5, marginTop: 10 }}
              /> */}
              <svg
                width="81"
                height="6"
                viewBox="0 0 81 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M79.5 0C77.3975 0 76.3291 1.31348 75.4707 2.36914C74.623 3.41064 74.0898 4 72.9922 4C71.8945 4 71.3623 3.41064 70.5146 2.36914C69.6563 1.31348 68.5879 0 66.4854 0C64.3838 0 63.3154 1.31396 62.457 2.36963C61.6113 3.41064 61.0781 4 59.9824 4C58.8877 4 58.3555 3.41064 57.5098 2.36963C56.6523 1.31396 55.585 0 53.4844 0C51.3838 0 50.3164 1.31348 49.458 2.36914C48.6123 3.41064 48.0801 4 46.9834 4C45.8887 4 45.3564 3.41113 44.5107 2.36963C43.6533 1.31396 42.5859 0 40.4844 0C38.3838 0 37.3164 1.31348 36.459 2.36914C35.6133 3.41064 35.0811 4 33.9854 4C32.8906 4 32.3584 3.41113 31.5127 2.36963C30.6553 1.31396 29.5879 0 27.4863 0C25.3857 0 24.3193 1.31396 23.4619 2.36963C22.6162 3.41064 22.085 4 20.9902 4C19.8965 4 19.3643 3.41064 18.5195 2.36963C17.6631 1.31396 16.5967 0 14.4971 0C12.3955 0 11.3281 1.31396 10.4707 2.36963C9.625 3.41113 9.09277 4 7.99805 4C6.90332 4 6.37109 3.41064 5.52539 2.36963C4.66797 1.31396 3.60059 0 1.5 0C0.947266 0 0.5 0.447754 0.5 1C0.5 1.55225 0.947266 2 1.5 2C2.59473 2 3.12695 2.58936 3.97266 3.63037C4.83008 4.68604 5.89746 6 7.99805 6C10.0986 6 11.166 4.68652 12.0234 3.63086C12.8691 2.58936 13.4014 2 14.4971 2C15.5908 2 16.1221 2.58887 16.9668 3.62988C17.8232 4.68604 18.8906 6 20.9902 6C23.0908 6 24.1572 4.68604 25.0146 3.63037C25.8604 2.58936 26.3916 2 27.4863 2C28.582 2 29.1143 2.58936 29.96 3.63086C30.8174 4.68652 31.8848 6 33.9854 6C36.0869 6 37.1543 4.68604 38.0117 3.63037C38.8574 2.58887 39.3896 2 40.4844 2C41.5801 2 42.1123 2.58936 42.958 3.63086C43.8154 4.68652 44.8828 6 46.9834 6C49.085 6 50.1523 4.68604 51.0107 3.63037C51.8564 2.58936 52.3887 2 53.4844 2C54.5791 2 55.1113 2.58936 55.957 3.63037C56.8145 4.68604 57.8818 6 59.9824 6C62.084 6 63.1514 4.68652 64.0098 3.63086C64.8564 2.58936 65.3887 2 66.4854 2C67.583 2 68.1162 2.58936 68.9639 3.63135C69.8223 4.68652 70.8906 6 72.9922 6C75.0947 6 76.1631 4.68652 77.0215 3.63086C77.8691 2.58936 78.4023 2 79.5 2C80.0527 2 80.5 1.55225 80.5 1C80.5 0.447754 80.0527 0 79.5 0Z"
                  fill="black"
                  fillOpacity={0.1}
                ></path>
              </svg>
            </Grid>
            {progress.key !== 1 && (
              <Box className={classes.box}>
                <Box sx={{ color: "grey", fontSize: "14px", fontWeight: 500 }}>
                  {" "}
                  Step {progress.key - 1} of {totalSteps.length}{" "}
                </Box>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 4,
                    marginTop: 10,
                    justifyContent: "center",
                  }}
                >
                  {totalSteps.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        width: 30,
                        height: 8,
                        backgroundColor:
                          progress.key - 1 >= index + 1 ? "#408266" : "#D9D9D9",
                        borderRadius: 10,
                      }}
                    ></div>
                  ))}
                </div>
              </Box>
            )}
            {progress.key === 1 && (
              <>
                {stepFromAds === 1 && (
                  <StepFromAds
                    heading="Do you know if you have any intimate images that have been posted online without your consent?"
                    subText="Loti can help you find out in less than 30 seconds. Click next and upload a profile image. In 30 seconds or less, we will tell you if we found any matching images or videos."
                    onClickTryNow={() => {
                      setStepFromAds(0);
                    }}
                  />
                )}

                {stepFromAds === 0 && (
                  <>
                    <StepType1
                      heading="To get started, please confirm your age."
                      subText=""
                      onClickYes={() => {
                        setData((current) => ({
                          ...current,
                          eighteenPlus: true,
                        }));
                        handleNext();
                      }}
                      onClickNo={() => setExitStepper({ value: true, key: 0 })}
                    />
                  </>
                )}
              </>
            )}
            {/* {progress.key === 2 && (
              <>
                <StepContent
                  inverseButton
                  heading="Are you a content creator?"
                  subText="If you are not sure, select No."
                  onClickYes={() => {
                    setData((current) => ({
                      ...current,
                      contentCreator: true,
                    }));
                    handleNext();
                  }}
                  onClickNo={() => {
                    setData((current) => ({
                      ...current,
                      contentCreator: false,
                    }));
                    setProgress((current) => ({ ...current, key: 3 }));
                  }}
                />
                <div></div>
              </>
            )} */}
            {progress.key === 2 && (
              <StepUpload
                setProgress={setProgress}
                onNext={handleNext}
                setUploadedImage={setUploadedImage}
              />
            )}

            {progress.key === 3 && (
              <Step4
                showNextFace={showNextFace}
                onNext={handleNext}
                faces={faces}
                faceId={faceId}
                setFaceId={setFaceId}
              />
            )}

            {progress.key === 4 && (
              <StepEmail
                faces={faces}
                data={data}
                faceId={faceId}
                setProgress={setProgress}
                handleInputChange={handleInputChange}
                uploadedImage={uploadedImage}
              />
            )}

            {progress.key <= 4 &&
              !matchLoading &&
              !uploadsLoading &&
              !progressLoader && <AccountAlert />}

            {progress.matchFound ? (
              <>
                {progress.key === 5 &&
                  progress.needToShowGreatNews === false && (
                    <StepHiddenResult
                      count={count}
                      onNext={handleNext}
                      noExactMatch={progress.noExactMatch}
                      searches={searches}
                      setProgress={setProgress}
                    />
                  )}

                {/* {progress.key === 6 &&
                  progress.needToShowGreatNews === false && (
                    <StepActionPermission
                      onNext={handleNext}
                      onClickNo={() => setExitStepper({ value: true, key: 1 })}
                      imageFound={progress.matchFound}
                    />
                  )} */}
              </>
            ) : (
              <>
                {progress.key === 5 &&
                  progress.needToShowGreatNews === true && (
                    <StepActionPermission
                      onNext={handleNext}
                      onClickNo={() => setExitStepper({ value: true, key: 1 })}
                      imageFound={false}
                      setProgress={setProgress}
                    />
                  )}

                {/* {progress.key === 7 &&
                  progress.needToShowGreatNews === false && (
                    <StepActionPermission
                      onNext={handleNext}
                      onClickNo={() => setExitStepper({ value: true, key: 1 })}
                      imageFound={progress.matchFound}
                    />
                  )} */}
              </>
            )}
            {progress.key === 6 &&
              (progress.needToShowGreatNews ? (
                <StepActionPermission
                  onNext={handleNext}
                  onClickNo={() => setExitStepper({ value: true, key: 1 })}
                  imageFound={progress.matchFound}
                  setProgress={setProgress}
                />
              ) : (
                <StepFormNew
                  data={data}
                  handleInputChange={handleInputChange}
                  setData={setData}
                  handleNext={handleNext}
                  handleChange={handleChange}
                  planDetails={getPlanInfo()}
                  setProgress={setProgress}
                  progress={progress}
                  planDetailsState={planDetailsState}
                  setPlanDetailsState={setPlanDetailsState}
                  planApiData={planApiData}
                />
              ))}
            {progress.key === 7 && (
              // <CongrulationComponent plan={getPlanInfo().name} />
              <Paymentform
                data={data}
                handleInputChange={handleInputChange}
                setData={setData}
                handleNext={handleNext}
                handleChange={handleChange}
                // planDetails={getPlanInfo()}
                setProgress={setProgress}
                progress={progress}
                planDetailsState={planDetailsState}
                setPlanDetailsState={setPlanDetailsState}
              />
            )}
              {progress.key === 8 && (
              <CongratulationComponent plan={getPlanInfo().name} />
              
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Onboarding;
