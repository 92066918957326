import {
  Alert,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { makeStyles } from "@mui/styles";
import { FiCheck } from "react-icons/fi";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import React, { useState, useEffect } from "react";
import { css } from "styled-components";
import Cookies from "js-cookie";

import CustomButton from "../UI/CustomButton.jsx";
import linesIcon from "../../Assets/Images/lines_icon.png";
import CustomFormInputType2 from "../UI/CustomFormInputType2.jsx";
import { onboardStep2Action, getPromoCode } from "../../Store/Auth/actions";
import { isPasswordValid } from "../../Helpers/functions";
import CustomSpinner from "../UI/CustomSpinner.jsx";
import PickPlanDialog from "../Common/PickPlanDialog.jsx";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  greenBox: {
    padding: "40px  50px",
    background: theme.palette.lightGreen2,
    "& h1": {
      textAlign: "center",
      ...theme.typography.heading44,
      margin: 0,
    },
    "& p": {
      textAlign: "center",
      margin: 0,
      marginTop: "30px",
      ...theme.typography.body24,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "25px  10px",
      "& h1": {
        fontSize: "32px",
      },
      "& p": {
        fontSize: "16px",
      },
    },
  },
  box1: {
    border: "1px solid rgba(64, 130, 102,0.29)",
    marginTop: "40px",
    padding: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& h2": {
      textAlign: "center",
      ...theme.typography.heading36,
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      "& h2": {
        fontSize: "24px",
      },
    },
  },
  listItem: {
    "& .MuiListItemIcon-root": {
      minWidth: "auto",
      marginRight: "25px",
      "& svg": {
        color: theme.palette.lightGreen,
        fontSize: "30px",
      },
    },
    "& .MuiTypography-root": {
      fontSize: "22px",
    },

    [theme.breakpoints.down("sm")]: {
      "& .MuiListItemIcon-root": {
        marginRight: "15px",
        "& svg": {
          fontSize: "24px",
        },
      },
      "& .MuiTypography-root": {
        fontSize: "16px",
      },
    },
  },
  sublistItem: {
    "& .MuiListItemIcon-root": {
      marginRight: "55px",
    },

    [theme.breakpoints.down("sm")]: {
      "& .MuiListItemIcon-root": {
        marginRight: "40px",
      },
    },
  },
  rateBox: {
    marginTop: "5px",
    "& p": {
      color: theme.palette.darkGreen,
      ...theme.typography.heading44,
      "& span": {
        ...theme.typography.body20,
        textTransform: "uppercase",
        letterSpacing: "0.19em",
        color: theme.palette.black,
        [theme.breakpoints.down("sm")]: {
          fontSize: "14px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "34px",
      },
    },
  },
  button: {
    marginTop: "20px !important",
    height: "60px !important",
    width: "300px ",
  },
  sideStrip1: {
    height: "300px",
    background: theme.palette.lightGreen,
    width: "30px",
    position: "absolute",
    top: "70px",
    left: "-30px",
    [theme.breakpoints.down("sm")]: {
      left: "-10px",
      width: "10px",
    },
  },
  sideStrip2: {
    height: "300px",
    background: theme.palette.lightGreen,
    width: "30px",
    position: "absolute",
    bottom: "100px",
    right: "-30px",
    [theme.breakpoints.down("sm")]: {
      right: "-10px",
      width: "10px",
    },
  },
  box2Inactive: {
    opacity: "0.29",
    "& h1": {
      color: theme.palette.darkGreen,
    },
  },
  box2: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: "50px",
    marginBottom: "50px",
    border: "1px solid rgba(64, 130, 102,0.29)",
    padding: "50px  40px",

    "& h1": {
      fontSize: "55px",
      textAlign: "center",
      fontWeight: "700",
      width: "60%",
      margin: "0",
      [theme.breakpoints.down("lg")]: {
        width: "80%",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },

    "& p": {
      ...theme.typography.body24,
      margin: 0,
      textAlign: "center",
      marginTop: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "50px  10px",
      "& h1": {
        fontSize: "36px",
      },
    },
  },
  collapseContainer: {
    marginTop: "20px",
    width: "100%",
  },
  collapseContainerHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h2": {
      fontSize: "32px",
      lineHeight: "34px",
      fontWeight: "700",
      margin: 0,
    },
    "& svg": {
      fontSize: "36px",
    },
  },
  line: {
    borderBottom: "1px solid #D9D9D9",
    marginTop: "10px",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "20px",
    borderBottom: `1px solid ${theme.palette.grey7}`,
    "& p": {
      ...theme.typography.body16,
      margin: 0,
    },
  },
  checkboxContainer2: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",

    "& .message": {
      color: theme.palette.grey2,
      ...theme.typography.body16,
    },
  },

  totalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    "& h2": {
      ...theme.typography.heading24,
      fontWeight: 700,
    },
  },
  subscribeButton: {
    background: `${theme.palette.lightGreen} !important`,
    color: `${theme.palette.white} !important`,
    maxWidth: "260px !important",
    height: "60px !important",
    position: "absolute !important",
    fontWeight: "500 !important",
    bottom: "-30px !important",
    fontSize: "24px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
      height: "40px !important",
      bottom: "-20px !important",
    },
  },
  applyButton: {
    background: `${theme.palette.lightGreen} !important`,
    color: `${theme.palette.white} !important`,
    maxWidth: "100px !important",
    height: "40px !important",
    marginTop: "43px !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px !important",
    },
  },
  ortext: {
    fontWeight: "400 !important",
    fontSize: "18px !important",
    marginTop: "25px !important",
  },

  subText: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
  },

  typoBottom: {
    marginTop: "20px !important",
    lineHeight: "20px !important",
    color: `${theme.palette.lightGreen} !important`,
    border: "none !important",
  },

  fullScreenLoaderContainer: {
    position: "fixed",
    top: "0px",
    left: "0px",
    zIndex: "10",
    height: "100vh",
    width: "100vw",
    background: theme.palette.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    ...theme.typography.body18,
    fontWeight: 500,
    color: theme.palette.black,
    marginBottom: "10px",
    "& span": {
      ...theme.typography.body16,
      color: theme.palette.grey2,
    },

    [theme.breakpoints.down("sm")]: {
      ...theme.typography.body18,
      "& span": {
        fontSize: "14px",
      },
    },
  },
}));

const StepForm = ({
  data,
  setData,
  handleNext,
  handleChange,
  planDetails,
  setProgress,
  progress,
}) => {
  const classes = useStyles();

  const [showForm, setShowForm] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [planDetailsState, setPlanDetailsState] = useState(planDetails);

  const onClose = (plan) => {
    if (plan.gatewayIdentifier) {
      setPlanDetailsState(plan);
    }
    setShowUpgradeModal(false);
  };

  return (
    <div>
      <PickPlanDialog
        open={showUpgradeModal}
        onClose={onClose}
        selectedPlan={planDetailsState}
      />
      <div className={classes.container}>
        <div className={classes.sideStrip1}></div>
        <div className={classes.sideStrip2}></div>
        <div className={classes.greenBox}>
          <h1>
            {planDetailsState.name === planDetails.name
              ? `We recommend our ${planDetailsState.name} plan`
              : `You have Selected ${planDetailsState.name} plan`}
          </h1>
          <p>{planDetailsState.description}</p>

          <div className={classes.box1}>
            <h2>Features</h2>
            <List>
              {planDetailsState.points.map((point, key) => (
                <ListItem className={classes.listItem} key={key}>
                  <ListItemIcon>
                    <FiCheck />
                  </ListItemIcon>
                  <ListItemText primary={point} />
                </ListItem>
              ))}
              {planDetailsState.sub && (
                <ListItem
                  className={clsx(classes.listItem, classes.sublistItem)}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={planDetailsState.sub} />
                </ListItem>
              )}
            </List>

            <div className={classes.rateBox}>
              <p>
                ${planDetailsState.price}
                <span>/per month </span>
                <div className={`${classes.subText}`}>
                  {/* {Cookies.get("seiren_discount") &&
                    "(We have applied 90% off on above amount untill you apply other coupon)"} */}
                </div>
                <div className={classes.ortext}>OR </div>
                <div>
                  <CustomButton
                    className={`${classes.button} ${classes.typoBottom}`}
                    onClick={() => setShowUpgradeModal(true)}
                  >
                    Pick a different Plan
                  </CustomButton>
                </div>
                <CustomButton
                  yellowFilled
                  className={classes.button}
                  onClick={() => setShowForm(true)}
                >
                  Get Started Today
                </CustomButton>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Box2
        showForm={showForm}
        data={data}
        setData={setData}
        handleNext={handleNext}
        price={planDetailsState.price}
        identifier={planDetailsState.gatewayIdentifier}
        handleChange={handleChange}
        progress={progress}
        setProgress={setProgress}
        planName={planDetailsState.name}
      />
    </div>
  );
};

const Box2 = ({
  showForm,
  data,
  handleChange,
  price,
  identifier,
  progress,
  setProgress,
  planName,
  setData,
}) => {
  const {
    loading,
    error: apiError,
    errorMessage,
    onboardingToken,
    promoCode,
  } = useSelector((state) => state.authReducer);

  const [userUpdated, setUserUpdated] = useState(false);
  const [fullScreenLoader] = useState(false);
  const [error, setError] = useState({
    full_name: "",
    password: "",
    country: "",
    state: "",
    street: "",
    zip_code: "",
    city: "",
    coupon: "",
  });

  const [cardNumber, setCradNumber] = useState("");
  const [expDate, setExpDate] = useState("");
  const [cvc, setCvc] = useState("");
  const [isCardError, setCardError] = useState(false);
  const [isCouponApplied, setCouponApplied] = useState(false);
  const [selectedPromoCode, setSelectedPromoCode] = useState(undefined);

  const onCardError = (cardError) => {
    if (cardError) {
      setCardError(true);
    } else {
      setCardError(false);
    }
  };
  const onChangeInput = (event) => {
    switch (event.target.name) {
      case "cardNumber": {
        setCradNumber(event.target.value);
        break;
      }
      case "expiryDate": {
        setExpDate(event.target.value);
        break;
      }
      case "cvc": {
        setCvc(event.target.value);
        break;
      }
      default: {
        break;
      }
    }
  };
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs({ onError: onCardError, onChange: onChangeInput });

  const errorObj = {
    full_name: "",
    password: "",
    country: "",
    state: "",
    street: "",
    zip_code: "",
    city: "",
    coupon: "",
  };
  const isDataValid = () => {
    let valid = true;

    if (!data.full_name) {
      valid = false;
      errorObj.full_name = "Full name is required.";
    }

    if (data.full_name) {
      if (!/^[a-zA-z\s]+$/.test(data.full_name)) {
        valid = false;
        errorObj.full_name = "Full name should only contain alphabets.";
      }
    }

    if (!isPasswordValid(data.password)) {
      valid = false;
      errorObj.password = "Password must be 8 characters.";
    }

    if (!data.country) {
      valid = false;
      errorObj.country = "Country is required.";
    }

    if (!data.city) {
      valid = false;
      errorObj.city = "City is required.";
    }

    if (!data.state) {
      valid = false;
      errorObj.state = "State is required.";
    }

    if (!data.zip_code) {
      valid = false;
      errorObj.zip_code = "Zip code is required.";
    }

    if (!data.street) {
      valid = false;
      errorObj.street = "Street is required.";
    }

    if (data?.coupon && !selectedPromoCode) {
      valid = false;
      errorObj.coupon = "Invalid Coupon";
    }

    if (isCardError) {
      valid = false;
    }
    setError(errorObj);
    return valid;
  };

  const onApplyCoupon = () => {
    if (isCouponApplied) {
      setData({ ...data, coupon: "" });
      setCouponApplied(!isCouponApplied);
      setSelectedPromoCode(undefined);
    } else {
      dispatch(getPromoCode(onboardingToken, data.coupon));
    }
  };

  const handleSubmit = () => {
    if (isDataValid()) {
      const utmAffiliate = Cookies.get("utm_affiliate");

      if (!userUpdated) {
        dispatch(
          onboardStep2Action(
            {
              full_name: data.full_name,
              password: data.password,
              country: data.country,
              state: data.state,
              suite: data.suite,
              zip_code: data.zip_code,
              city: data.city,
              street: data.street,
              number: cardNumber,
              exp_month: expDate?.split("/")[0].replace(/\s+/g, ""),
              exp_year: expDate?.split("/")[1].replace(/\s+/g, ""),
              cvc,
              // coupon:
              //   seirenDiscount && data.coupon.toLowerCase() === "SEIREN10"
              //     ? "m20NW4gj"
              //     : undefined,
              promoCode: selectedPromoCode ? selectedPromoCode?.id : undefined,
              priceId: identifier,
              utm_affiliate: utmAffiliate,
            },
            onboardingToken,
            () => {
              setUserUpdated(true);
              setProgress({ ...progress, key: 9 });
              // addPaymentSession();
            }
          )
        );
      } else {
        // addPaymentSession();
      }
    }
  };

  useEffect(() => {
    const discount = Cookies.get("promo_code");
    if (discount) {
      setData({ ...data, coupon: discount });
      dispatch(getPromoCode(onboardingToken, discount));
    }
  }, []);

  useEffect(() => {
    if (promoCode?.id) {
      setSelectedPromoCode(promoCode);
      setCouponApplied(true);
    }
  }, [promoCode]);

  return (
    <div className={clsx(classes.box2, showForm ? "" : classes.box2Inactive)}>
      <img
        src={linesIcon}
        referrerPolicy="no-referrer"
        style={{
          top: "-13px",
          position: "absolute",
          height: "26px",
          width: "28px",
        }}
      />
      {fullScreenLoader && (
        <div className={classes.fullScreenLoaderContainer}>
          <CustomSpinner />
        </div>
      )}
      <h1>You&apos;re one step away from protection!</h1>
      {showForm && (
        <>
          <p>
            Please enter your information to subscribe to our {planName} Plan.{" "}
            <br />
            You can cancel at any time.
          </p>
          <Grid container>
            <CollapseContainer title="Info">
              <br />
              <br />
              <Grid container columnSpacing={3}>
                <Grid item sm={6} xs={12}>
                  <CustomFormInputType2
                    type="text"
                    label="Full Name"
                    name="full_name"
                    value={data.full_name}
                    onChange={handleChange}
                    error={Boolean(error.full_name)}
                    helperText={error.full_name}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <CustomFormInputType2
                    type="email"
                    label="Email"
                    value={data.email}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container columnSpacing={3}>
                <Grid item sm={6} xs={12}>
                  <CustomFormInputType2
                    type="password"
                    label="Password"
                    value={data.password}
                    name="password"
                    error={Boolean(error.password)}
                    helperText={error.password}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <CustomFormInputType2
                    type="text"
                    label="Country"
                    name="country"
                    value={data.country}
                    onChange={handleChange}
                    error={Boolean(error.country)}
                    helperText={error.country}
                  />
                </Grid>
              </Grid>
              <Grid container columnSpacing={3}>
                <Grid item sm={4} xs={12}>
                  <CustomFormInputType2
                    type="text"
                    label="Street address"
                    name="street"
                    value={data.street}
                    onChange={handleChange}
                    error={Boolean(error.street)}
                    helperText={error.street}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <CustomFormInputType2
                    type="text"
                    label="Suite / Unit"
                    optional
                    name="suite"
                    value={data.suite}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  {" "}
                  <CustomFormInputType2
                    type="text"
                    label="City"
                    name="city"
                    value={data.city}
                    onChange={handleChange}
                    error={Boolean(error.city)}
                    helperText={error.city}
                  />
                </Grid>
              </Grid>
              <Grid container columnSpacing={3}>
                <Grid item sm={4} xs={12}>
                  {" "}
                  <CustomFormInputType2
                    type="text"
                    label="State"
                    name="state"
                    value={data.state}
                    onChange={handleChange}
                    error={Boolean(error.state)}
                    helperText={error.state}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <CustomFormInputType2
                    type="text"
                    label="ZIP Code"
                    name="zip_code"
                    value={data.zip_code}
                    onChange={handleChange}
                    error={Boolean(error.zip_code)}
                    helperText={error.zip_code}
                  />
                </Grid>
              </Grid>
              <Grid container mt={5}>
                <label className={classes.label}>Payment Details</label>
                <PaymentInputsWrapper
                  styles={{
                    fieldWrapper: {
                      base: css`
                        margin-bottom: 1rem;
                        width: 100%;
                      `,
                    },
                    inputWrapper: {
                      base: css`
                        background-color: #eef3f2;
                        border-color: #eef3f2 !important;
                        box-shadow: #eef3f2 !important;
                      `,
                      focused: css`
                        border-color: unset;
                        box-shadow: unset;
                        outline: 2px #eef3f2;
                        outline-offset: 2px;
                      `,
                    },
                    input: {
                      base: css`
                        background-color: #eef3f2;
                        fontsize: 16px;
                        outline: none;
                      `,
                      errored: css`
                        color: maroon;
                      `,
                      cardNumber: css`
                        width: 80%;
                      `,
                      expiryDate: css`
                        float: right;
                      `,
                      cvc: css`
                        float: right;
                      `,
                    },
                  }}
                  {...wrapperProps}
                >
                  <svg {...getCardImageProps({ images })} />
                  <input {...getCardNumberProps()} />
                  <input {...getExpiryDateProps()} />
                  <input {...getCVCProps()} />
                </PaymentInputsWrapper>
              </Grid>

              <Grid container columnSpacing={3}>
                <Grid item sm={7} xs={12}></Grid>
                <Grid item sm={3} xs={12}>
                  <CustomFormInputType2
                    type="text"
                    label="Coupon"
                    name="coupon"
                    value={data.coupon}
                    onChange={handleChange}
                    error={Boolean(error.coupon)}
                    helperText={
                      isCouponApplied
                        ? selectedPromoCode?.coupon?.name
                        : error.coupon
                        ? error.coupon
                        : ""
                    }
                    disabled={isCouponApplied}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  <CustomButton
                    className={classes.applyButton}
                    onClick={onApplyCoupon}
                  >
                    {isCouponApplied ? "Remove" : "Apply"}
                  </CustomButton>
                </Grid>
                {isCouponApplied && <Grid item xs={12}></Grid>}
              </Grid>
            </CollapseContainer>
          </Grid>

          <div className={classes.totalContainer}>
            <h2>Total</h2>
            <h2>
              $
              {isCouponApplied && selectedPromoCode
                ? (
                    Number(price) -
                    (Number(price) *
                      Number(selectedPromoCode.coupon.percent_off)) /
                      100
                  ).toFixed(2)
                : Number(price).toFixed(2)}
            </h2>
          </div>

          {apiError && <Alert severity="error">{errorMessage}</Alert>}
          {loading ? (
            <CustomSpinner />
          ) : (
            <CustomButton
              className={classes.subscribeButton}
              onClick={handleSubmit}
              disable={loading}
            >
              Subscribe Now
            </CustomButton>
          )}
        </>
      )}
    </div>
  );
};

const CollapseContainer = ({ children, title }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(true);

  const toggleOpen = () => {
    setOpen((current) => !current);
  };

  return (
    <>
      <div className={classes.collapseContainer}>
        <div className={classes.collapseContainerHead} onClick={toggleOpen}>
          <h2>{title}</h2>
          {open ? <MdOutlineKeyboardArrowDown /> : <MdOutlineKeyboardArrowUp />}
        </div>
        <div className={classes.line} />
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </div>
    </>
  );
};

export default StepForm;
