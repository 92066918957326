import React from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordAction } from "../../Store/Auth/actions";
import CustomSpinner from "../../Components/UI/CustomSpinner.jsx";

const useStyles = makeStyles((theme) => ({
  securityContainer: {
    height: "100%",
  },
  headingContainer: {
    padding: "20px 0",
    margin: "0 20px",

    "& h2": {
      margin: 0,
      ...theme.typography.heading18,
      fontWeight: 500,
      lineHeight: "34px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px 0",
      margin: "0 15px",
    },
  },
  inputContainer: {
    padding: "0 20px",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0 15px",
    },
  },
  dot: {
    background: theme.palette.black,
    borderRadius: "50%",
    display: "inline-block",
    height: "8px",
    marginLeft: "4px",
    marginRight: "4px",
    width: "8px",
  },
  changePasswordContainer: {
    marginLeft: "20px",
    cursor: "pointer",
    "& p": {
      margin: 0,
      ...theme.typography.body18,
      fontWeight: 500,
      color: theme.palette.lightGreen,
      textDecoration: "underline",
    },
  },
}));

const Security = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.authReducer);

  const handeClick = () => {
    dispatch(forgotPasswordAction(userData.email_address, undefined, true));
  };

  return (
    <div className={classes.securityContainer}>
      {loading ? (
        <CustomSpinner center />
      ) : (
        <>
          {" "}
          <div className={classes.headingContainer}>
            <h2>Password</h2>
          </div>
          <div className={classes.inputContainer}>
            <div>
              <span className={classes.dot} />
              <span className={classes.dot} />
              <span className={classes.dot} />
              <span className={classes.dot} />
              <span className={classes.dot} />
              <span className={classes.dot} />
              <span className={classes.dot} />
              <span className={classes.dot} />
            </div>
            <div
              className={classes.changePasswordContainer}
              onClick={handeClick}
            >
              <p>Change Password</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Security;
