import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { Typography, useMediaQuery } from "@mui/material";
import Header from "./Header.jsx";
import Options from "./Options.jsx";
import { getUserInforAction } from "../../Store/User/actions";
import CustomSpinner from "../UI/CustomSpinner.jsx";
import BottomNavigation from "./BottomNavigation.jsx";
import { getActiveSubscriptionAction } from "../../Store/Subscriptions/actions";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: theme.palette.backgroundColor,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    minHeight: "100vh",
  },
  container: {
    display: "flex",
    height: "calc(100vh - 113px)",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 118px)",
    },
  },
  noMobileHeadContainer: {
    height: "100vh",
  },
  leftMenu: {
    width: "250px",
    boxShadow: " 0px 4px 20px rgba(0, 0, 0, 0.05)",
  },
  rightContainer: {
    width: "calc(100vw - 230px)",
    overflowY: "auto",
    overflowX: "hidden",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      padding: "0",
    },
  },
  rightWidthFull: {
    width: "100vw",
  },
  menuContainer: {
    margin: "8px",
    padding: "9px 20px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.black,
    "&.active": {
      background: "#EEEEEE",
      "& .MuiTypography-root": {
        fontWeight: 700,
      },
    },
  },
  optionName: {
    marginLeft: "10px !Important",
  },
  icon: {
    height: "25px",
    width: "25px",
  },
}));

const DashboardWrapper = ({ noSideMenu, children, noMobileHead }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { loading, error, errorMessage, userData } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    dispatch(getUserInforAction());
    dispatch(getActiveSubscriptionAction());
  }, [dispatch]);

  return (
    <div className={classes.wrapper}>
      {loading ? (
        <div className={classes.center}>
          <CustomSpinner center />
        </div>
      ) : error ? (
        <div className={classes.center}>
          <Typography variant="heading24">
            {errorMessage.replace("jwt", "link")}
          </Typography>
        </div>
      ) : (
        <>
          {isMobileScreen && noMobileHead ? undefined : (
            <Header userData={userData} />
          )}
          <div
            className={clsx(
              classes.container,
              noMobileHead && isMobileScreen
                ? classes.noMobileHeadContainer
                : undefined
            )}
          >
            {!noSideMenu && !isMobileScreen && (
              <div className={classes.leftMenu}>
                <Options />
              </div>
            )}
            <div
              className={clsx(
                classes.rightContainer,
                noSideMenu && classes.rightWidthFull
              )}
            >
              {children}
            </div>
          </div>
        </>
      )}
      {isMobileScreen && <BottomNavigation />}
    </div>
  );
};

export default DashboardWrapper;
