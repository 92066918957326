export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const VERIFICATION_PENDING = "VERIFICATION_PENDING";

export const REGISTER = "REGISTER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const RESEND_TOKEN = "RESEND_TOKEN";
export const RESEND_TOKEN_SUCCESS = "RESEND_TOKEN_SUCCESS";
export const RESEND_TOKEN_FAILURE = "RESEND_TOKEN_FAILURE";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_REQUEST_SUCCESS =
  "FORGOT_PASSWORD_REQUEST_SUCCESS";
export const FORGOT_PASSWORD_REQUEST_FAILURE =
  "FORGOT_PASSWORD_REQUEST_FAILURE";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const ONBOARD_USER = "ONBOARD_USER";
export const ONBOARD_USER_SUCCESS = "ONBOARD_USER_SUCCESS";
export const ONBOARD_USER_FAILURE = "ONBOARD_USER_FAILURE";

export const ONBOARD_USER_STEP_2 = "ONBOARD_USER_STEP_2";
export const ONBOARD_USER_STEP_2_SUCCESS = "ONBOARD_USER_STEP_2_SUCCESS";
export const ONBOARD_USER_STEP_2_FAILURE = "ONBOARD_USER_STEP_2_FAILURE";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const VALIDATE_TOKEN_FAILURE = "VALIDATE_TOKEN_FAILURE";

export const RESET_AUTH_STATUS = "RESET_AUTH_STATUS";

export const SET_AUTH_LOADING = "SET_AUTH_LOADING";

export const GET_PROMOTION_CODES = "GET_PROMOTION_CODES";
export const GET_PROMOTION_CODES_SUCCESS = "GET_PROMOTION_CODES_SUCCESS";
export const GET_PROMOTION_CODES_FAILURE = "GET_PROMOTION_CODES_FAILURE";

