import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  StyledEngineProvider,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import CryptoJS from "crypto-js";
import AuthWrapper from "../../Components/AuthWrapper/AuthWrapper.jsx";
import Constants from "../../Assets/Constants/index";
import { isEmailValid, isPasswordValid } from "../../Helpers/functions";
import { loginAction, logoutAction } from "../../Store/Auth/actions";
import CustomSpinner from "../../Components/UI/CustomSpinner.jsx";
import CustomTextField from "../../Components/UI/CustomTextField.jsx";

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: "20px !important",
  },
  textField: {
    margin: "5px",
    width: "100%",
    "& .MuiFormLabel-root": {
      color: theme.palette.grey2,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.grey1} !important`,
    },
    "& .Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.red} !important`,
    },
    "& .MuiButtonBase-root": {
      color: theme.palette.lightGreen,
    },
  },
  loginButton: {
    ...theme.typography.button,
    color: theme.palette.white,
    background: `${theme.palette.lightGreen} !important`,
    width: "100%",
    margin: "5px",
    marginTop: "10px",
    height: "55px",
    textTransform: "none",
    "&:hover": {
      background: theme.palette.darkGreen,
    },
  },
  typoBottom: {
    marginTop: "25px",
    lineHeight: "25px",
    "& a": {
      color: theme.palette.lightGreen,
      textDecoration: "none",
    },
  },
  alert: {
    marginBottom: "10px",
  },
}));

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    loading,
    errorMessage,
    isUserLogout,
  } = useSelector((state) => state.authReducer);

  const [credentials, setCredentails] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({
    email: "",
    password: "",
  });
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [decodedId, setDecodedId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const utmAffiliate = queryParams.get("utm_affiliate");
    const promoCode = queryParams.get("promo_code");

    // Check if utm_affiliate is not present in the cookies
    const utmAffiliateCookie = Cookies.get("utm_affiliate");
    if (!utmAffiliateCookie) {
      // If not present, set it from the query parameters
      if (utmAffiliate) {
        Cookies.set("utm_affiliate", utmAffiliate, {
          expires: 7,
          sameSite: "none",
        });
      }
    }

    if (promoCode) {
      Cookies.set("promo_code", promoCode, { expires: 7, sameSite: "none" });
    }

    const userId = queryParams.get("userid");
    if (userId) {
      const encryptionDecryptionKey = "goloti-redirect-key";
      const decryptedData = CryptoJS.AES.decrypt(
        userId,
        encryptionDecryptionKey
      ).toString(CryptoJS.enc.Utf8);
      if (userId && !isUserLogout) {
        dispatch(logoutAction());
      }
      setDecodedId(decryptedData);
      localStorage.setItem("loti_user_id", decryptedData);
    }
  }, [location.search]);

  useEffect(() => {
    if (decodedId && isUserLogout) {
      dispatch(
        loginAction(
          {
            userId: decodedId,
          },
          navigate
        )
      );
    }
  }, [decodedId, isUserLogout]);

  const handleInputChange = (e) => {
    setCredentails({ ...credentials, [e.target.name]: e.target.value });
  };

  const validateFields = () => {
    let valid = true;
    const errorObj = { email: "", password: "" };

    if (!isEmailValid(credentials.email)) {
      valid = false;
      if (!credentials.email) {
        errorObj.email = Constants.EMAIL_REQUIRED;
      } else {
        errorObj.email = Constants.INVALID_EMAIL;
      }
    }

    if (!isPasswordValid(credentials.password)) {
      valid = false;
      if (!credentials.password) {
        errorObj.password = Constants.PASSWORD_REQUIRED;
      } else {
        errorObj.password = Constants.INVALID_PASSWORD;
      }
    }
    setError(errorObj);
    return valid;
  };

  const handleLogin = () => {
    if (validateFields()) {
      dispatch(
        loginAction(
          {
            email_address: credentials.email,
            password: credentials.password,
          },
          navigate
        )
      );
    }
  };

  return (
    <AuthWrapper>
      <Typography variant="heading24" className={classes.heading}>
        Welcome back!
      </Typography>
      <StyledEngineProvider injectFirst>
        {errorMessage && (
          <Alert className={classes.alert} severity="error">
            {errorMessage}
          </Alert>
        )}
        <CustomTextField
          label="Enter Your Email"
          type="email"
          name="email"
          error={Boolean(error.email)}
          helperText={error.email}
          variant="outlined"
          bnvalue={credentials.email}
          onChange={handleInputChange}
          disabled={loading}
        />
        <TextField
          label="Password"
          variant="outlined"
          name="password"
          type={passwordVisibility ? "text" : "password"}
          className={classes.textField}
          value={credentials.password}
          onChange={handleInputChange}
          error={Boolean(error.password)}
          helperText={error.password}
          disabled={loading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setPasswordVisibility(!passwordVisibility)}
                  edge="end"
                >
                  {passwordVisibility ? (
                    <MdOutlineVisibility />
                  ) : (
                    <MdOutlineVisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {loading ? (
          <CustomSpinner />
        ) : (
          <Button
            variant="contained"
            className={classes.loginButton}
            onClick={handleLogin}
          >
            Login to Loti
          </Button>
        )}
        <Typography variant="heading18" className={classes.typoBottom}>
          Not a member? <Link to="/onboarding">Sign Up</Link> <br />
          <Link to="/forgot-password">Forgot Your Password?</Link>
        </Typography>
      </StyledEngineProvider>
    </AuthWrapper>
  );
};

export default Login;
