import React, { useState, useRef } from "react";
import { makeStyles } from "@mui/styles";

// import html2canvas from "html2canvas";
import ImageList from "@mui/material/ImageList";
import { useDispatch, useSelector } from "react-redux";
import ImageListItem from "@mui/material/ImageListItem";
import { toast } from "react-toastify";

import { useNavigate } from "react-router";

import CircularProgress from "@mui/material/CircularProgress";
import DownloadIcon from "@mui/icons-material/Download";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { deleteVerifiedImagesAction, getVerifiedImagesAction, verifyAssetAction } from "../../Store/Uploads/actions";
import ConfirmationDialog from "../../Components/Common/ConfirmationDialog.jsx";
import LotiPlaceholder from "../../Assets/Images/loti-placeholder.jpg";

const useStyles = makeStyles((theme) => ({
	uploadMediaContainer: {
		display: "flex",
		flexWrap: "wrap",
		gap: "20px",
	},

	uploadHere: {
		color: "white",
		// padding: "5px 0px",
		width: "10%",
		height: "30px",
		cursor: "pointer",
		boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
		borderRadius: "2px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		[theme.breakpoints.down("xl")]: {
			width: "15%",
		},
		[theme.breakpoints.down("lg")]: {
			width: "20%",
		},
	},
	protect: {
		fontWeight: 600,
	},
	errorDiv: {
		display: "flex",
		fontSize: "30px",
		height: "70vh",
		width: "100%",
		justifyContent: "center",
		alignItems: "center",
		fontWeight: 600,
	},
	hoverDiv: {
		position: "absolute",
		backgroundImage: "linear-gradient(0deg, transparent, black)",
		color: "white",
		width: "90%",
		padding: "5%",
		textAlign: "end",
		zIndex: 10,
	},
	UploadMediaCardContainer: {
		background: theme.palette.lightGreen,
		boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.05)",
		borderRadius: "5px",
		// padding: "4px 10px",
		height: "calc(100% - 28px)",
		cursor: "pointer",
	},
	innerLine: {
		// margin: "10px",
		height: "calc(100% - 20px)",
		// border: `1px dashed ${theme.palette.lightGreen}`,
		borderRadius: "5px",
	},
	uploadContainer: {
		background: theme.palette.lightGreen2,
		marginTop: "60px",
		width: "65vw",
		[theme.breakpoints.down("md")]: {
			marginTop: "45px",
		},
		[theme.breakpoints.down("sm")]: {
			marginTop: "30px",
			width: "90vw",
		},
	},
	uploadWrap: {
		padding: "15px",
		height: "250px",
		cursor: "pointer",
	},

	input: {
		display: "none",
	},
	text: {
		display: "flex",
		height: "100%",
		justifyContent: "center",
		alignItems: "center",
		cursor: "pointer",

		"& h3": {
			...theme.typography.body24,
			color: "white",
		},
		// "& svg": {
		// 	fontSize: "30px",
		// 	color: theme.palette.lightGreen,
		// 	marginRight: "10px",
		// },
	},
}));

const VerifiedImage = () => {
	const divRef = useRef(null);
	const classes = useStyles();
	const navigate = useNavigate();
	const [hoverIndex, setHoverIndex] = useState();
	const [imageConfirmatiom, setImageConfirmation] = useState(false);
	const [imageData, setImageData] = useState({});
	const [imageLoad, setImageLoad] = useState([]);

	const dispatch = useDispatch();
	const { errorMessage, loading, verified_assets: verifiedAssets } = useSelector((state) => state.Uploads);

	React.useEffect(() => {
		dispatch(getVerifiedImagesAction());
	}, [dispatch]);

	const downloadImage = async (imageLink) => {
		window.open(imageLink, "_blank");
	};

	function handleImageUpload(file) {
		if (file) {
			const supportedFileTypes = ["jpeg", "jpg", "png", "webg", "heic"];
			const filename = file.name;
			const fileType = filename.substring(filename.lastIndexOf(".") + 1, filename.length);

			if (!supportedFileTypes.includes(fileType.toLowerCase())) {
				toast.error("File not supported.");
				return;
			}

			const reader = new FileReader();

			// Set up the FileReader onload event to handle the file read
			reader.onload = (event) => {
				const img = new Image();
				img.onload = () => {
					// Access the width and height of the image
					const { height, width } = img;

					if ((width < 400 || width > 800) || (height < 400 || height > 800)) {
						toast.error("This Image's resolution is too low to protect");
						return;
					}
					// Continue with your existing code to dispatch and handle the image data
					const formData = new FormData();
					formData.append("image", file);

					dispatch(
						verifyAssetAction(formData, (data) => {
							setImageConfirmation(true);
							setImageData(data);
						})
					);
				};

				// Set the source of the image to the result of the FileReader
				img.src = event.target.result;
			};

			// Read the file as a data URL
			reader.readAsDataURL(file);
		}
	}

	const deleteAsset = () => {
		dispatch(
			deleteVerifiedImagesAction(imageData.uuId, () => {
				dispatch(getVerifiedImagesAction());
				setImageLoad([]);
				setImageConfirmation(false);
			})
		);
	};

	return (
		<div className={classes.uploadMediaContainer}>
			{errorMessage ? (
				<div className={classes.errorDiv}>{errorMessage}</div>
			) : (
				<>
					<div style={{ width: "100%" }}>
						{/* <UploadCard handleUploadImage={handleImageUpload} /> */}

						<ConfirmationDialog
							open={imageConfirmatiom}
							heading="You are not there in the image"
							body="Are you sure, you want upload the selected content"
							confirmButtonName="Yes"
							cancelButtonName="No"
							onClose={() => {
								deleteAsset();
							}}
							handleConfirmation={() => {
								setImageConfirmation(false);
								dispatch(getVerifiedImagesAction());
								setImageLoad([]);
								toast.success("Asset added successfully");
							}}
						/>
						<div
							style={{
								width: "100%",
								minWidth: "350px",
								// position: "relative",
								display: "flex",
								alignItems: "center",
								gap: "10px",
								fontSize: "18px",
							}}
						>
							<span className={classes.protect}> Protect your Image :</span>

							<div className={classes.UploadMediaCardContainer}>
								{!loading && (
									<input
										className={classes.input}
										type="file"
										accept="image/*"
										id="image-upload"
										name="myImage"
										onChange={(event) => {
											handleImageUpload(event.target.files[0]);
										}}
									/>
								)}
								<div className={classes.innerLine}>
									<label htmlFor="image-upload">
										<div className={classes.text}>
											<span style={{ padding: loading ? "3px 59px" : " 4px 20px", color: "white" }} className="upload-file">
												{loading ? <CircularProgress sx={{ color: "white",marginTop:"1px" }} size={20} /> : "Upload here"}
											</span>
										</div>
									</label>
								</div>
							</div>
						</div>
					</div>

					{verifiedAssets?.length > 0 ? (
						<ImageList variant="masonry" cols={4} gap={4} >
							{verifiedAssets.map((item, index) => (
								<ImageListItem key={item.img} >
									<div
										id={`imageRender-${index}`}
										key={index}
										style={{
											background: "transparent",
											display: "flex",
											// width: "400px",
											// height: (item.height / item.width) * 400,
											position: "relative",
										}}
										onMouseEnter={() => {
											setHoverIndex(index);
										}}
										onMouseLeave={() => {
											setHoverIndex(-1);
										}}
									>
										{hoverIndex === index && (
											<div className={classes.hoverDiv} id="elementToExclude">
												<span>
													<RemoveRedEyeOutlinedIcon
														sx={{ fontSize: "30px", cursor: "pointer" }}
														onClick={() => navigate(`/deepfake/verified/${item.uuId}`)}
													/>
												</span>
												<span>
													<DownloadIcon
														sx={{ fontSize: "30px", cursor: "pointer", marginLeft: "10px" }}
														onClick={() => downloadImage(item.protected_file_url)}
													/>
												</span>
											</div>
										)}
										<img
											src={item.protected_file_url}
											id={`loadImage-${index}`}
											alt="Uploaded"
											style={{ width: "100%", background: "transparent", position: "absolute", zIndex: 8, height: "100%" }}
											ref={divRef}
											loading="lazy"
											referrerPolicy="no-referrer"
											onLoad={() => {
												let data = [...imageLoad];
												data = [...data, index];
												setImageLoad(data);
											}}
										/>
										<img
											src={LotiPlaceholder}
											alt="Uploaded"
											style={{ width: "100%", background: "transparent", height: "100%" }}
											ref={divRef}
											loading="lazy"
											referrerPolicy="no-referrer"
										/>
									</div>
								</ImageListItem>
							))}
						</ImageList>
					) : (
						!loading && <div className={classes.errorDiv}>No data found !</div>
					)}
				</>
			)}
		</div>
	);
};

export default VerifiedImage;
