import * as actionTypes from "./actionTypes";

const initialState = {
  error: null,
  loading: false,
  errorMessage: "",
  uploadedMedia: [],
  uploadLoading: false,
  faces: [],
  verified_assets:[],
  single_verified_asset:{}
};

const Uploads = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_UPLOADS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
        faces: [],
      };

    case actionTypes.SET_UPLOADED_MEDIA_LOADING:
      return {
        ...state,
        uploadLoading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.GET_UPLOADED_IMAGES_SUCCESS:
      return {
        ...state,
        uploadLoading: false,
        uploadedMedia: action.payload,
      };
    case actionTypes.GET_UPLOADED_IMAGES_FAILURE:
      return {
        ...state,
        uploadLoading: false,
        error: true,
        errorMessage: action.payload,
      };

      case actionTypes.GET_VERIFIED_ASSETS_SUCCESS:
      return {
        ...state,
        loading: false,
        verified_assets: action.payload,
      };
    case actionTypes.GET_VERIFIED_ASSETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };


      case actionTypes.GET_SINGLE_VERIFIED_ASSET_SUCCESS:
        return {
          ...state,
          loading: false,
          single_verified_asset: action.payload,
        };
      case actionTypes.GET_SINGLE_VERIFIED_ASSET_FAILURE:
        return {
          ...state,
          loading: false,
          error: true,
          errorMessage: action.payload,
        };

      case actionTypes.UPLOAD_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        faces: action.payload,
      };
    case actionTypes.UPLOAD_ASSET_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };


      case actionTypes.VERIFY_ASSET_SUCCESS:
        return {
          ...state,
          loading: false,
          // verified_assets: action.payload,
        };
      case actionTypes.VERIFY_ASSET_FAILURE:
        return {
          ...state,
          loading: false,
          error: true,
          errorMessage: action.payload,
        };

    case actionTypes.DELETE_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        uploadedMedia: state.uploadedMedia.filter(
          (e) => e._id !== action.payload
        ),
      };
    case actionTypes.DELETE_ASSET_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case actionTypes.SAVE_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        // faces: action.payload,
      };
    case actionTypes.SAVE_ASSET_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case actionTypes.CLEAR_FACES:
      return {
        ...state,
        faces: [],
      };

    default:
      return state;
  }
};

export default Uploads;
