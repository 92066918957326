import * as actionTypes from "./actionTypes";

export const getUserInforAction = () => ({
  type: actionTypes.GET_USER_INFO,
});

export const updateUserInfoAction = (payload) => ({
  type: actionTypes.UPDATE_USER_INFO,
  payload,
});
