import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import API from "../../Axios/Axios";

function* loginSaga({ payload, navigate }) {
  try {
    yield put({ type: actionTypes.SET_AUTH_LOADING });
    const { data } = yield API.post("/user/authenticate", payload);
    if (data.success) {
      if (data.data.id) {
        yield put({
          type: actionTypes.VERIFICATION_PENDING,
          payload: payload.email_address,
        });
        navigate("/confirmation", { state: { email: payload.email_address } });
      } else {
        localStorage.setItem("token", data.data.session_token);
        yield put({
          type: actionTypes.LOGIN_SUCCESS,
          payload: data.data.session_token,
          query: payload,
        });
      }
    } else {
      yield put({
        type: actionTypes.LOGIN_FAILURE,
        payload: data?.message || "Unable to login",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.LOGIN_FAILURE,
      payload: error?.response?.data?.message || "Unable to login",
    });
  }
}

function* registerSaga({ payload, navigate }) {
  try {
    yield put({ type: actionTypes.SET_AUTH_LOADING });
    const { data } = yield API.post("/user/register", payload);

    if (data.success) {
      yield put({
        type: actionTypes.REGISTER_SUCCESS,
      });
      navigate("/confirmation", { state: { email: payload.email_address } });
    } else {
      yield put({
        type: actionTypes.REGISTER_FAILURE,
        payload: data?.message || "Unable to register",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.REGISTER_FAILURE,
      payload: error?.response?.data?.message || "Unable to register",
    });
  }
}

function* validateTokenSaga({ token }) {
  try {
    yield put({ type: actionTypes.SET_AUTH_LOADING });
    const { data } = yield API.post(
      "/user/verify-email",
      {},
      {
        headers: {
          "verification-token": token,
        },
      }
    );
    if (data.success) {
      yield put({
        type: actionTypes.LOGIN_SUCCESS,
        payload: data.data.session_token,
      });
    } else {
      yield put({
        type: actionTypes.VALIDATE_TOKEN_FAILURE,
        payload: data?.message || "Unable to complete verification",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.VALIDATE_TOKEN_FAILURE,
      payload:
        error?.response?.data?.message || "Unable to complete verification",
    });
  }
}

function* forgotPasswordSaga({ email, navigate, isAuth }) {
  try {
    yield put({ type: actionTypes.SET_AUTH_LOADING });
    const { data } = yield API.post("/user/forgot-password", {
      email_address: email,
    });
    if (data.success) {
      yield put({
        type: actionTypes.FORGOT_PASSWORD_REQUEST_SUCCESS,
      });
      if (isAuth) {
        toast.success(
          "We’ve sent you password instructions to your email. If you don’t receive the email, please try again."
        );
      }
      if (navigate) navigate("/forgot-password/confirmation");
    } else {
      yield put({
        type: actionTypes.FORGOT_PASSWORD_REQUEST_FAILURE,
        payload: data?.message || "Unable to send verification email",
      });
      if (isAuth) {
        toast.error(data?.message || "Unable to send verification email");
      }
    }
  } catch (error) {
    yield put({
      type: actionTypes.FORGOT_PASSWORD_REQUEST_FAILURE,
      payload:
        error?.response?.data?.message || "Unable to send verification email",
    });
    if (isAuth) {
      toast.error(
        error?.response?.data?.message || "Unable to send verification email"
      );
    }
  }
}

function* resetPasswordSaga({ payload, navigate }) {
  try {
    yield put({ type: actionTypes.SET_AUTH_LOADING });
    const { data } = yield API.post("/user/reset-password", payload);
    if (data.success) {
      yield put({
        type: actionTypes.RESET_PASSWORD_SUCCESS,
      });
      navigate("/login-back");
    } else {
      yield put({
        type: actionTypes.RESET_PASSWORD_FAILURE,
        payload: data?.message || "Unable to reset password",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.RESET_PASSWORD_FAILURE,
      payload: error?.response?.data?.message || "Unable to reset password",
    });
  }
}

function* resendTokenSaga({ email }) {
  try {
    yield put({ type: actionTypes.SET_AUTH_LOADING });
    const { data } = yield API.post("/user/resend-otp", {
      email_address: email,
    });
    if (data.success) {
      yield put({
        type: actionTypes.RESEND_TOKEN_SUCCESS,
      });
    } else {
      yield put({
        type: actionTypes.RESEND_TOKEN_FAILURE,
        payload: data?.message || "Unable to resend email",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.RESEND_TOKEN_FAILURE,
      payload: error?.response?.data?.message || "Unable to resend email",
    });
  }
}

function* onboardUserSaga({ email, successCallback,failCallback }) {
  try {
    yield put({ type: actionTypes.SET_AUTH_LOADING });
    const { data } = yield API.post("/user/register", {
      email_address: email,
      onboarding: sessionStorage.getItem("onboardingId"),
    });
    if (data.success) {
      yield put({
        type: actionTypes.ONBOARD_USER_SUCCESS,
        payload: data.data.session_token,
      });

      successCallback(data.data.session_token);
    } else {
      failCallback(data?.message || "Unable to perform action")
      yield put({
        type: actionTypes.ONBOARD_USER_FAILURE,
        payload: data?.message || "Unable to perform action",
      });
    }
  } catch (error) {
    failCallback(error?.response?.data?.message || "Unable to perform action",)
    yield put({
      type: actionTypes.ONBOARD_USER_FAILURE,
      payload: error?.response?.data?.message || "Unable to perform action",
    });
  }
}

function* onboardUserStep2Saga({ data: payload, token, successCallback }) {
  try {
    yield put({ type: actionTypes.SET_AUTH_LOADING });
    const { data } = yield API.post("/subscription/subscribe", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (data.success) {
      yield put({
        type: actionTypes.ONBOARD_USER_STEP_2_SUCCESS,
      });
      successCallback();
    } else {
      yield put({
        type: actionTypes.ONBOARD_USER_STEP_2_FAILURE,
        payload: data?.message || "Unable to perform action",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.ONBOARD_USER_STEP_2_FAILURE,
      payload: error?.response?.data?.message || "Unable to perform action",
    });
  }
}

function* logoutSaga() {
  try {
    yield put({ type: actionTypes.SET_AUTH_LOADING });
    const { data } = yield API.get("/user/logout");
    if (data.success) {
      yield put({
        type: actionTypes.LOGOUT_SUCCESS,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.LOGOUT_SUCCESS,
    });
  }
}

function* getPromoCodeSaga({ token, promoCodeName }) {
  try {
    const { data } = yield API.get(
      `/subscription/promoCode?promocodeName=${promoCodeName}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data.success) {
      yield put({
        type: actionTypes.GET_PROMOTION_CODES_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_PROMOTION_CODES_FAILURE,
    });
  }
}

function* authSaga() {
  yield all([
    yield takeLatest(actionTypes.LOGIN, loginSaga),
    yield takeLatest(actionTypes.REGISTER, registerSaga),
    yield takeLatest(actionTypes.VALIDATE_TOKEN, validateTokenSaga),
    yield takeLatest(actionTypes.FORGOT_PASSWORD_REQUEST, forgotPasswordSaga),
    yield takeLatest(actionTypes.RESET_PASSWORD, resetPasswordSaga),
    yield takeLatest(actionTypes.RESEND_TOKEN, resendTokenSaga),
    yield takeLatest(actionTypes.LOGOUT, logoutSaga),
    yield takeLatest(actionTypes.GET_PROMOTION_CODES, getPromoCodeSaga),
    yield takeLatest(actionTypes.ONBOARD_USER, onboardUserSaga),
    yield takeLatest(actionTypes.ONBOARD_USER_STEP_2, onboardUserStep2Saga),
  ]);
}

export default authSaga;
