import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  billingCard: {
    background: theme.palette.white,
    padding: "20px",
    boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      margin: "10px",
    },
  },
}));
const BillingCard = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.billingCard}>{children}</div>;
};

export default BillingCard;
