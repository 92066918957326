import { makeStyles } from "@mui/styles";
import React from "react";
import CustomButton from "../UI/CustomButton.jsx";
import CustomPopup from "../UI/CustomPopup.jsx";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    "& .MuiPaper-root": {
      padding: "10px",
    },

    [theme.breakpoints.down("sm")]: {
      "& .MuiPaper-root": {
        margin: "10px",
      },
    },
  },
  continueButton: {
    fontSize: "18px !important",
    fontWeight: "400 !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
    },
  },
  cancelButton: {
    fontSize: "18px !important",
    fontWeight: "400 !important",
    marginTop: "7px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
    },
  },
  dialogHead: {
    ...theme.typography.heading24,
    fontWeight: "700",
    margin: 0,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.heading20,
    },
  },
  dialogBodyFont: {
    ...theme.typography.body16,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.body14,
    },
  },
}));

const ConfirmationDialog = ({
  open,
  onClose,
  handleConfirmation,
  heading,
  body,
  confirmButtonName,
  cancelButtonName,
}) => {
  const classes = useStyles();

  return (
    <CustomPopup
      open={Boolean(open)}
      onClose={onClose}
      className={classes.dialogContainer}
    >
      <h2 className={classes.dialogHead}>{heading}</h2>
      <p className={classes.dialogBodyFont}>{body}</p>
      <br />
      <CustomButton
        yellow
        className={classes.continueButton}
        onClick={handleConfirmation}
      >
        {confirmButtonName}
      </CustomButton>
      {cancelButtonName && (
        <CustomButton className={classes.cancelButton} onClick={onClose}>
          {cancelButtonName}
        </CustomButton>
      )}
    </CustomPopup>
  );
};

export default ConfirmationDialog;
