import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    lightYellow: "#FDFAF1",
    yellow: "#EBA311",
    darkGreen: "#002E25",
    grey1: "#E0E0E0",
    grey2: "#787878",
    grey3: "#E8E8E8",
    grey4: "#EEEEEE",
    grey5: "#E5E5E5",
    grey6: "#DADADA",
    grey7: "#D9D9D9",
    lightGreen: "#408266",
    lightGreen2: "#EEF3F2",
    orange:"#FAA005",
    black: "#000000",
    white: "#FFFFFF",
    red: "#d32f2f",
    red2: "#c64242",
    backgroundColor: "#fdfcfc",
  },

  typography: {
    button: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "21px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    button2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "28px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    link: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "23px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    small: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
    },
    heading44: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "44px",
      fontWeight: 700,
      lineHeight: "44px",
    },
    heading36: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "36px",
      fontWeight: 700,
      lineHeight: "44px",
    },
    heading32: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "32px",
      fontWeight: 700,
      lineHeight: "34px",
    },
    heading28: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "28px",
      fontWeight: 700,
      lineHeight: "38px",
    },
    heading24: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "34px",
    },
    heading20: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "24px",
    },
    heading18: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "21px",
    },
    subHead14: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "17px",
    },
    body20: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "34px",
    },
    body24: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "24px",
      fontWeight: 400,
      lineHeight: "38px",
    },
    body16: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "22px",
    },
    body18: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "21px",
    },
    body14: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "16px",
    },
    body16_500: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "18px",
    },
    cursive: {
      fontFamily: "'Alex Brush', cursive",
      fontSize: "22px",
      fontWeight: 400,
      lineHeight: "34px",
    },
  },
});

export default theme;
