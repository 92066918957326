import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import { BiTrash } from "react-icons/bi";
import CustomFormInput from "../../Components/UI/CustomFormInput.jsx";
import CustomButton from "../../Components/UI/CustomButton.jsx";
import { updateUserInfoAction } from "../../Store/User/actions";

const useStyles = makeStyles((theme) => ({
  profileContainer: {
    height: "100%",
  },
  headingContainer: {
    padding: "20px 0",
    margin: "0 20px",
    borderBottom: `1px solid ${theme.palette.grey4}`,
    "& h2": {
      margin: 0,
      ...theme.typography.heading20,
      fontWeight: 500,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px 0",
      margin: "0 15px",
    },
  },
  inputContainer: {
    padding: "10px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 15px",
    },
  },
  takeDownContainer: {
    marginTop: "30px",
  },
  buttonContainer: {
    marginTop: "25px !important",
  },
  hr: {
    margin: "30px 20px 20px 20px",
    [theme.breakpoints.down("sm")]: {
      margin: "30px 15px 15px 15px",
    },
    borderBottom: `1px solid ${theme.palette.grey4}`,
  },
  deleteButton: {
    color: `${theme.palette.red} !important`,
    textTransform: "capitalize !important",
    ...theme.typography.body16,
    fontSize: "16px !important",
    fontWeight: "400 !important",
    lineHeight: "24px !important",
    "& svg": {
      marginRight: "15px",
      fontSize: "18px",
    },
  },
}));

const Profile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.user);

  const [data, setData] = useState({
    fullName: "",
    country: "",
    streetAddress: "",
    suite: "",
    city: "",
    state: "",
    zipCode: "",
    email: "",
  });
  const [errors, setErrors] = useState({
    fullName: "",
    country: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
  });

  const onChange = (e) => {
    setData((current) => ({ ...current, [e.target.name]: e.target.value }));
  };

  const handleValidate = () => {
    let isValid = true;
    const err = {
      fullName: "",
      country: "",
      streetAddress: "",
      city: "",
      state: "",
      zipCode: "",
      phoneNumber: "",
    };

    if (!data.fullName) {
      isValid = false;
      err.fullName = "Full name is required.";
    }

    if (data.fullName) {
      if (!/^[a-zA-z\s]+$/.test(data.fullName)) {
        isValid = false;
        err.fullName = "Full name should only contain alphabets.";
      }
    }

    if (!data.country) {
      isValid = false;
      err.country = "Country is required.";
    }
    if (!data.streetAddress) {
      isValid = false;
      err.streetAddress = "Street is required.";
    }
    if (!data.city) {
      isValid = false;
      err.city = "City is required.";
    }
    if (!data.state) {
      isValid = false;
      err.state = "State is required.";
    }
    if (!data.zipCode) {
      isValid = false;
      err.zipCode = "Zip code is required.";
    }

    setErrors(err);
    return isValid;
  };

  const handleUpdate = () => {
    if (handleValidate()) {
      dispatch(
        updateUserInfoAction({
          full_name: data.fullName,
          country: data.country,
          street: data.streetAddress,
          suite: data.suite,
          city: data.city,
          state: data.state,
          zip_code: data.zipCode,
        })
      );
    }
  };

  const inputs = [
    {
      label: "Full Name",
      type: "text",
      value: data.fullName,
      name: "fullName",
      error: Boolean(errors.fullName),
      helperText: errors.fullName,
    },
    {
      label: "Country",
      type: "text",
      value: data.country,
      name: "country",
      error: Boolean(errors.country),
      helperText: errors.country,
    },
    {
      label: "Street Address",
      type: "text",
      value: data.streetAddress,
      name: "streetAddress",
      error: Boolean(errors.streetAddress),
      helperText: errors.streetAddress,
    },
    {
      label: "Suite / Unit",
      optional: true,
      type: "text",
      value: data.suite,
      name: "suite",
    },
    {
      label: "City",
      type: "text",
      value: data.city,
      name: "city",
      error: Boolean(errors.city),
      helperText: errors.city,
    },
    {
      label: "State",
      type: "text",
      value: data.state,
      name: "state",
      error: Boolean(errors.state),
      helperText: errors.state,
    },
    {
      label: "ZIP code",
      type: "text",
      value: data.zipCode,
      name: "zipCode",
      error: Boolean(errors.zipCode),
      helperText: errors.zipCode,
    },
  ];

  useEffect(() => {
    setData({
      email: userData?.email_address || "",
      fullName: userData?.full_name || "",
      country: userData?.country || "",
      streetAddress: userData?.street || "",
      suite: userData?.suite || "",
      city: userData?.city || "",
      state: userData?.state || "",
      zipCode: userData?.zip_code || "",
    });
  }, []);

  return (
    <div className={classes.profileContainer}>
      <Heading heading="General Information" />
      <div className={classes.inputContainer}>
        <CustomFormInput
          onChange={onChange}
          name="email"
          type="email"
          label="Email"
          value={data.email}
          disabled={true}
        />
      </div>
      <div className={classes.takeDownContainer}>
        <Heading heading="DMCA Takedown Information" />
        <div className={classes.inputContainer}>
          {inputs.map((e, i) => (
            <CustomFormInput onChange={onChange} key={i} {...e} />
          ))}
          <Grid container>
            <Grid
              item
              xs={4}
              sm={4}
              md={2}
              lg={2}
              className={classes.buttonContainer}
            >
              <CustomButton yellowFilled onClick={handleUpdate}>
                Update
              </CustomButton>
            </Grid>
          </Grid>
        </div>
      </div>
      {/* <div className={classes.hr} />
      <div className={classes.inputContainer}>
        <Button className={classes.deleteButton}>
          <BiTrash />
          Delete Account
        </Button>
      </div> */}
    </div>
  );
};

const Heading = ({ heading }) => {
  const classes = useStyles();
  return (
    <div className={classes.headingContainer}>
      <h2>{heading}</h2>
    </div>
  );
};

export default Profile;
