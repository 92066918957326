export const SET_DMCA_LOADING = "SET_DMCA_LOADING";

export const GET_REQUESTS = "GET_REQUESTS";
export const GET_REQUESTS_SUCCESS = "GET_REQUESTS_SUCCESS";
export const GET_REQUESTS_FAILURE = "GET_REQUESTS_FAILURE";

export const SET_EMAIL_LOADING = "SET_EMAIL_LOADING";

export const GET_EMAILS_BY_REQUEST = "GET_EMAILS_BY_REQUEST";
export const GET_EMAILS_BY_REQUEST_SUCCESS = "GET_EMAILS_BY_REQUEST_SUCCESS";
export const GET_EMAILS_BY_REQUEST_FAILURE = "GET_EMAILS_BY_REQUEST_FAILURE";

export const ADD_TAKEDOWN_REQUEST = "ADD_TAKEDOWN_REQUEST";
export const ADD_TAKEDOWN_REQUEST_SUCCESS = "ADD_TAKEDOWN_REQUEST_SUCCESS";
export const ADD_TAKEDOWN_REQUEST_FAILURE = "ADD_TAKEDOWN_REQUEST_FAILURE";

export const TAKEDOWN_ALL = "TAKEDOWN_ALL";
export const TAKEDOWN_ALL_LOADING = "TAKEDOWN_ALL_LOADING";

export const TAKEDOWN_ALL_SUCCESS = "TAKEDOWN_ALL_SUCCESS";
export const TAKEDOWN_ALL_FAILURE = "TAKEDOWN_ALL_FAILURE";


export const TAKEDOWN_MULTI_CONTENT = "TAKEDOWN_MULTI_CONTENT";
export const TAKEDOWN_MULTI_CONTENT_LOADING = "TAKEDOWN_MULTI_CONTENT_LOADING";

export const TAKEDOWN_MULTI_CONTENT_SUCCESS = "TAKEDOWN_MULTI_CONTENT_SUCCESS";
export const TAKEDOWN_MULTI_CONTENT_FAILURE = "TAKEDOWN_MULTI_CONTENT_FAILURE";


export const GET_ADDED_ASSET_TAKEDOWN_REQUEST =
  "GET_ADDED_ASSET_TAKEDOWN_REQUEST";
export const GET_ADDED_ASSET_TAKEDOWN_REQUEST_SUCCESS =
  "GET_ADDED_ASSET_TAKEDOWN_REQUEST_SUCCESS";
export const GET_ADDED_ASSET_TAKEDOWN_REQUEST_FAILURE =
  "GET_ADDED_ASSET_TAKEDOWN_REQUEST_FAILURE";

export const START_TAKEDOWN_PROCESS = "START_TAKEDOWN_PROCESS";
export const START_TAKEDOWN_PROCESS_SUCCESS = "START_TAKEDOWN_PROCESS_SUCCESS";
export const START_TAKEDOWN_PROCESS_FAILURE = "START_TAKEDOWN_PROCESS_FAILURE";

export const SEND_REPLY = "SEND_REPLY";
export const SEND_REPLY_SUCCESS = "SEND_REPLY_SUCCESS";
export const SEND_REPLY_FAILURE = "SEND_REPLY_FAILURE";

export const ADD_DMCA_ORDER_DETAILS = "ADD_DMCA_ORDER_DETAILS";
export const ADD_DMCA_ORDER_DETAILS_SUCCESS = "ADD_DMCA_ORDER_DETAILS_SUCCESS";
export const ADD_DMCA_ORDER_DETAILS_FAILURE = "ADD_DMCA_ORDER_DETAILS_FAILURE";

export const RESET_DMCA_ERROR = "RESET_DMCA_ERROR";

export const START_LOADING_RESP = "START_LOADING_RESP";
