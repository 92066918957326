import { put, all, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import API from "../../Axios/Axios";

function* getAlertSaga({ payload }) {
  if (payload === 1)
    yield put({
      type: actionTypes.GET_ALERTS_LOADING,
    });
  try {
    const { data } = yield API.get(`/alerts/?page=${payload}`);
    if (data.success) {
      yield put({
        type: actionTypes.GET_ALERTS_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ALERTS_FAILURE,
      payload: error.message,
    });
  }
}

function* alertSaga() {
  yield all([yield takeLatest(actionTypes.GET_ALERTS, getAlertSaga)]);
}

export default alertSaga;
