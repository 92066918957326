import * as actionTypes from "./actionTypes";

const initialState = {
  error: null,
  loading: false,
  matches: [],
  errorMessage: "",
  scanCompleted: false,
  faces: [],
  uploadMatches: [],
  searches: [],
  errorTitle: "",
  isSubscriptionInActive: false,
  count: null,
  exactMatches: null,
  progressLoader: false,
  progressLoaderValue: 0,
  bulkSaveLoading: false,
};

const matches = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MATCHES_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
        isSubscriptionInActive: false,
      };
    case actionTypes.START_PROGRESS_LOADER:
      return {
        ...state,
        progressLoader: true,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        bulkSaveLoading: true,
      };

    case actionTypes.STOP_PROGRESS_LOADER:
      return {
        ...state,
        progressLoader: false,
      };

    case actionTypes.SET_PROGRESS_VALUE:
      return {
        ...state,
        progressLoaderValue: action.value,
      };

    // case actionTypes.GET_MATCHES_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     uploadMatches: action.payload,
    //     scanCompleted: true,
    //     faces: [],
    //   };
    // case actionTypes.GET_MATCHES_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: true,
    //     errorMessage: action.payload,
    //     scanCompleted: true,
    //     uploadMatches: [],
    //   };
    case actionTypes.GET_DASHBOARD_MATCHES_SUCCESS:
      return {
        ...state,
        loading: false,
        matches: action.payload,
        scanCompleted: true,
      };

    case actionTypes.GET_DASHBOARD_MATCHES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        scanCompleted: true,
        matches: [],
      };

    case actionTypes.GET_FACES_SUCCESS:
      // case actionTypes.GET_FACES_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        faces: action.payload,
        scanCompleted: true,
      };
    case actionTypes.GET_FACES_FAILURE:
      // case actionTypes.GET_FACES_DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        scanCompleted: true,
        faces: [],
      };

    case actionTypes.GET_DASHBOARD_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        searches: action.payload,
        exactMatches: action.exactMatches,
      };

    case actionTypes.GET_DASHBOARD_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        searches: [],
        isSubscriptionInActive: action.isSubscriptionInActive || false,
      };

    case actionTypes.DELETE_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        searches: state.searches.filter((e) => e.face !== action.payload.face),
        matches: state.matches.filter((e) => e.face !== action.payload.face),
      };

    case actionTypes.DELETE_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case actionTypes.SAVE_MATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        searches: state.searches.filter((e) => e.face !== action.payload.face),
      };

    case actionTypes.SAVE_MATCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case actionTypes.BULK_SAVE_MATCHES_SUCCESS:
      return {
        ...state,
        bulkSaveLoading: false,
        searches: state.searches.filter((e) => e.face !== action.payload.face),
      };

    case actionTypes.BULK_SAVE_MATCHES_FAILED:
      return {
        ...state,
        bulkSaveLoading: false,
        error: true,
        errorMessage: action.payload,
      };

      case actionTypes.ARCHIVE_SEARCH_SUCCESS:
     
        return {
          ...state,
          bulkSaveLoading: false,
          searches: state.searches.filter((e) => e.face !== action.payload[0].face),
        };
  
      case actionTypes.ARCHIVE_SEARCH_FAILURE:
        return {
          ...state,
          bulkSaveLoading: false,
          error: true,
          errorMessage: action.payload,
        };

    case actionTypes.SEARCH_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        count: action.count,
      };

    case actionTypes.SEARCH_ASSET_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        searches: [],
        isSubscriptionInActive: action.isSubscriptionInActive || false,
        errorTitle: action.title,
      };

    case actionTypes.CLEAR_MATCH_ERRORS:
      return {
        ...state,
        error: false,
        errorTitle: "",
        errorMessage: "",
      };
    default:
      return state;
  }
};

export default matches;
