import { Grid, MenuItem, Select, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  customInputContainer: {
    marginTop: "5px",
    marginBottom: "10px",
  },
  label: {
    ...theme.typography.body18,
    fontWeight: 500,
    color: theme.palette.black,
    marginBottom: "10px",
    "& span": {
      ...theme.typography.body16,
      color: theme.palette.grey2,
    },

    [theme.breakpoints.down("sm")]: {
      ...theme.typography.body18,
      "& span": {
        fontSize: "14px",
      },
    },
  },

  inputBox: {
    width: "100%",

    ...theme.typography.body16,
    color: theme.palette.black,
    "& .MuiInputBase-root": {
      background: theme.palette.lightGreen2,
    },
    "& input": {
      padding: "6px 10px",
      fontSize: "14px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& input::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .MuiFormHelperText-root": {
      textAlign: "left",
      fontSize: "0.8rem",
      lineHeight: "1.66",
      marginTop: "3px",
      marginLeft:"2px"
    },
  },
  select: {
    width: "100%",
    ...theme.typography.body16,
    color: theme.palette.black,
    background: theme.palette.lightGreen2,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiSelect-outlined": {
      padding: "14px 20px !important",
    },
  },
}));

const CustomEmailInput = ({ select, options, label, optional, ...rest }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems="center"
      className={classes.customInputContainer}
    >
      <label className={classes.label}>
        {label}
        {optional && <span> (optional)</span>}
      </label>

      {select ? (
        <Select className={classes.select} {...rest}>
          {options.map((option, key) => (
            <MenuItem value={option} key={key}>
              {option}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <TextField
          variant="outlined"
          {...rest}
          className={classes.inputBox}
          sx={{
            "& input": {
              background: "white",
              padding: "6px 10px ",
              borderRadius: "6px",
              border: "1px solid #e9e1e1",
              fontSize: "14px",
            },
          }}
        />
      )}
    </Grid>
  );
};

export default CustomEmailInput;
