import { makeStyles } from "@mui/styles";
import { MdOutlineLanguage } from "react-icons/md";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import placeholder from "../../Assets/Images/loti-placeholder.jpg";
import placeholderRemoved from "../../Assets/Images/loti-removed.jpg";


import CustomButton from "../UI/CustomButton.jsx";
import { startOwnUrlTakedownAction } from "../../Store/OwnUrl/actions";
import TakeDownConfirmationDialog from "../Common/ConfirmationDialog.jsx";

const useStyles = makeStyles((theme) => ({
  addOwnUrlCardContainer: {
    background: theme.palette.white,
    boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    padding: "14px 20px",
    "& h3": {
      textAlign: "center",
      color: theme.palette.lightGreen,
    },
  },

  body: {
    position: "relative",
  },

  userImg: {
    height: "300px",
    [theme.breakpoints.up(1700)]: {
      height: "400px",
    },
    width: "100%",
    // objectFit: "contain",
  },
  websiteContainer: {
    position: "absolute",
    bottom: "0px",
    padding: "10px",
    width: "-webkit-fill-available",
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)",
  },
  website: {
    ...theme.typography.body16,
    color: theme.palette.white,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  globe: {
    marginRight: "5px",
    fontSize: "20px ",
  },
  button: {
    marginTop: "10px !important",
  },
  buttonsContainer: {
    marginTop: "20px",
    "& button": {
      width: "100%",
      background: theme.palette.white,
      color: theme.palette.black,
      textTransform: "none",
      padding: "12px",
      border: `1px solid ${theme.palette.grey3}`,
      boxShadow: "none",
      "&:hover": {
        background: theme.palette.white,
      },
    },
    "& h3": {
      textAlign: "center",
      color: theme.palette.lightGreen,
    },
  },
}));

const AddOwnUrlCard = ({
  image,
  domainData,
  status,
  takedownStatus ,
  handleWebsiteOpen,
  id,
  getOwnUrlsAction,
  setIsSubmit,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const statusColor = (data) => {
    const color =
      data === "Under Review"
        ? "#daa520"
        : data === "URL Rejected"
        ? "#dc143c"
        : "#408266";

    return color;
  };

  const successCallback = () => {
    dispatch(getOwnUrlsAction());
  };

  const handleSubmitData = () => {
    setIsSubmit(true);
    const data = {
      domain: domainData,
      own_url_id: id,
    };
    dispatch(startOwnUrlTakedownAction(data, successCallback));
  };

  // const statusColor = () => {
  //   switch (status) {
  //     case "Under Review":
  //       return "#EBA311";
  //     case "URL Approved":
  //       return "#408266";
  //     case "URL Rejected":
  //       return "#F44336";
  //     case "Takedown Requested":
  //       return "#787878";
  //     default:
  //       return "#408266";
  //   }
  // };

  return (
    <div
      className={classes.addOwnUrlCardContainer}
      style={{
        backgroundColor: takedownStatus === "completed" ? "#CCCCCC" : "",
      }}
    >
      <TakeDownConfirmationDialog
        open={showConfirmation}
        heading={"Takedown Request"}
        body={"Are you sure, you want to start the takedown process"}
        confirmButtonName="Yes, Sure"
        cancelButtonName="Cancel"
        onClose={() => setShowConfirmation(false)}
        handleConfirmation={handleSubmitData}
      />

      <div className={classes.body}>
        <img
          src={ takedownStatus==="completed" ?placeholderRemoved: image  }
          alt="user-img"
          className={classes.userImg}
          onError={(e) => {
            e.currentTarget.src =  takedownStatus==="completed" ?placeholderRemoved: placeholder;
          }}
          referrerPolicy="no-referrer"
        />
        <div className={classes.websiteContainer}>
          <div className={classes.website} onClick={() => handleWebsiteOpen()}>
            <MdOutlineLanguage className={classes.globe} /> {domainData}
          </div>
        </div>
      </div>
      <div>
        {takedownStatus === "completed" ? (
          <h3 style={{ margin: "7px 0px", color: "black" }}>
            Takedown Completed
          </h3>
        ) : status === "URL Approved" ? (
          <div style={{ marginTop: "5px" }}>
            <CustomButton
              yellow
              variant="contained"
              onClick={() => setShowConfirmation(true)}
            >
              Start Takedown Process
            </CustomButton>
          </div>
        ) : (
          <h3 style={{ margin: "7px 0px", color: statusColor(status) }}>
            {status}
          </h3>
        )}
      </div>
    </div>
  );
};

export default AddOwnUrlCard;
