import { makeStyles } from "@mui/styles";
import { MdOutlineLanguage, MdAdd } from "react-icons/md";
import React from "react";
// import Tooltip from "@mui/material/Tooltip";
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CustomButton from "../UI/CustomButton.jsx";
import placeholder from "../../Assets/Images/loti-placeholder.jpg";

const useStyles = makeStyles((theme) => ({
  matchesCardContainer: {
    background: theme.palette.white,
    boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    padding: "14px 20px",
  },
  headContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  head: {
    ...theme.typography.heading18,
    fontWeight: 700,
    margin: 0,
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
  },
  body: {
    position: "relative",
  },
  helpIcon: {
    fontSize: "20px",
    color: theme.palette.lightGreen,
  },
  moreIcon: {
    fontSize: "30px",
    color: theme.palette.black,
  },
  userImg: {
    height: "300px",
    [theme.breakpoints.up(1700)]: {
      height: "400px",
    },
    width: "100%",
    objectFit: "cover",
    objectPosition: "top",
  },
  websiteContainer: {
    position: "absolute",
    bottom: "0px",
    padding: "10px",
    width: "-webkit-fill-available",
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)",
    display: "flex",
    justifyContent: "space-between", // Added to distribute contents horizontally
    alignItems: "center",
  },
  website: {
    ...theme.typography.body16,
    color: theme.palette.white,
    cursor: "pointer",
  },
  sourceType: {
    ...theme.typography.body16,
    color: theme.palette.white,
    marginLeft: "10px", // Added to create spacing between website and sourceType
  },
  globe: {
    marginRight: "3px",
    fontSize: "20px ",
    verticalAlign: "bottom",
  },
  buttonsContainer: {
    marginTop: "20px",
    "& button": {
      width: "100%",
      background: theme.palette.white,
      color: theme.palette.black,
      textTransform: "none",
      padding: "12px",
      border: `1px solid ${theme.palette.grey3}`,
      boxShadow: "none",
      "&:hover": {
        background: theme.palette.white,
      },
    },
    "& h3": {
      textAlign: "center",
      color: theme.palette.lightGreen,
    },
  },
  btnRow1: {
    display: "flex",
    marginBottom: "10px",
    "& button:first-child": {
      marginRight: "10px",
    },
  },
  selectedCard: {
    border: `3px solid ${theme.palette.lightGreen}`,
  },
  card: {
    border: `3px solid ${theme.palette.white}`,
  },
  checkbox: {
    color: theme.palette.lightGreen,
    "& .MuiSvgIcon-root": {
      fontSize: "1.8rem",
      color: theme.palette.yellow,
    },
    "&.Mui-checked": {
      color: theme.palette.yellow,
    },
  },
}));

const MatchesCard = ({
  image,
  faceSignedUrl,
  website,
  openModal,
  onTakeDown,
  distance,
  handleDelete,
  takedownId,
  sourceType,
  isSelecting,
  setIsSelecting,
  onSelect,
  isSelected,
  takdownStatus,
  subscriptionsRedux,
}) => {
  const [totalImageRetries, settotalImageRetries] = React.useState(0);
  const classes = useStyles();
  const getMatchType = () => {
    if (distance <= 100) return "100% confident";
    if (distance <= 200) return "95% confident";
    if (distance <= 300) return "90% confident";
    if (distance <= 400) return "80% confident";
    if (distance <= 500) return "75% confident";
    if (distance <= 600) return "70% confident";
    if (distance <= 700) return "65% confident";

    return "60% confident";
  };

  const setDefaultURL = (ev) => {
    // ev.currentTarget.src = faceSignedUrl;
    // ev.currentTarget.onError = null;
    if (totalImageRetries === 0) {
      ev.currentTarget.src = faceSignedUrl;
      ev.currentTarget.onError = null;
      settotalImageRetries(1);
    } else ev.currentTarget.src = placeholder;
  };

  const handleCheckboxChange = () => {
    onSelect();
  };

  function handleCardClick() {
    if (!isSelecting) {
      setIsSelecting(true);
    }
    onSelect();
  }
  return (
    <div
      className={`${classes.matchesCardContainer} ${isSelected ? classes.selectedCard : classes.card
        }`}
    >
      <div
        className={classes.headContainer}
        onClick={takedownId ? null : subscriptionsRedux.checkSubscription ? handleCardClick : null}
      >
        <h3 className={classes.head}>{getMatchType()}</h3>
        {subscriptionsRedux.checkSubscription && (
          <div className={classes.head}>
            <Checkbox
              onClick={handleCardClick}
              checked={isSelected}
              onChange={handleCheckboxChange}
              className={classes.checkbox}
              disabled={takedownId}
              style={{ visibility: takedownId ? "hidden" : "" }}
            />
          </div>
        )}
      </div>
      <div
        className={classes.body}
        onClick={takedownId ? null : subscriptionsRedux.checkSubscription ? handleCardClick : null}
      >
        <img
          referrerPolicy="no-referrer"
          src={takdownStatus === "completed" ? faceSignedUrl : image}
          alt="user-img"
          className={classes.userImg}
          style={{
            filter: takdownStatus === "completed" ? "grayscale(100%)" : "none",
          }}
          onError={setDefaultURL}
          loading="lazy"
        />
        <div className={classes.websiteContainer}>
          <div className={classes.website} onClick={() => openModal()}>
            <MdOutlineLanguage className={classes.globe} /> {website}
          </div>
          <div className={classes.sourceType}>{sourceType}</div>
        </div>
      </div>
      <div className={classes.buttonsContainer}>
        {takdownStatus === "completed" ? (
          <h3 style={{ color: "black" }}>Takedown Completed.</h3>
        ) : takedownId ? (
          <h3>Takedown requested.</h3>
        ) : (
          <div className={classes.btnRow1}>
            {!isSelecting && (
              <>
                <CustomButton yellow variant="contained" onClick={onTakeDown}>
                  <MdAdd />
                  Takedown
                </CustomButton>
                <Button variant="contained" onClick={handleDelete}>
                  Not me
                </Button>
              </>
            )}
          </div>
        )}
      </div>
      {/* {isSelecting ? (
        <Checkbox
          onClick={handleCardClick}
          checked={isSelected}
          onChange={handleCheckboxChange}
          className={classes.checkbox}
        />
      ) : null} */}
    </div>
  );
};

export default MatchesCard;
