import * as actionTypes from "./actionTypes";

export const getFacesAction = (formData, successCallback, failCallback) => ({
  type: actionTypes.GET_FACES,
  formData,
  successCallback,
  failCallback,
});

export const getDashBoardMatches = () => ({
  type: actionTypes.GET_DASHBOARD_MATCHES,
});

export const getDashBoardSearch = (
  onboarding,
  token,
  successCallback,
  failCallback
) => ({
  type: actionTypes.GET_DASHBOARD_SEARCH,
  onboarding,
  token,
  successCallback,
  failCallback,
});

export const saveMatchAction = (payload, fromOnboarding) => ({
  type: actionTypes.SAVE_MATCH,
  payload,
  fromOnboarding,
});

export const bulkSaveMatchAction = (payload,successCallback) => ({
  type: actionTypes.BULK_SAVE_MATCHES,
  payload,
  successCallback
});
export const bulkArchiveSearchAction = (payload,successCallback) => ({
  type: actionTypes.ARCHIVE_SEARCH,
  payload,
  successCallback
});

export const searchAssetAction = (
  id,
  successCallback,
  failCallback,
  onboarding,
  token
) => ({
  type: actionTypes.SEARCH_ASSET,
  id,
  successCallback,
  failCallback,
  onboarding,
  token,
});

export const deleteSearchAction = (payload, fromOnboarding) => ({
  type: actionTypes.DELETE_SEARCH,
  payload,
  fromOnboarding,
});

export const clearMatchErrorAction = () => ({
  type: actionTypes.CLEAR_MATCH_ERRORS,
});

export const startProgressLoader = () => ({
  type: actionTypes.START_PROGRESS_LOADER,
});

export const stopProgressLoader = () => ({
  type: actionTypes.STOP_PROGRESS_LOADER,
});

export const setProgressValue = (value) => ({
  type: actionTypes.SET_PROGRESS_VALUE,
  value
});

// export const getFacesDashboardAction = (
//   formData,
//   successCallback,
//   failCallback
// ) => ({
//   type: actionTypes.GET_FACES_DASHBOARD,
//   formData,
//   successCallback,
//   failCallback,
// });

// export const getMatchesAction = (
//   reqId,
//   token,
//   successCallback,
//   failCallback
// ) => ({
//   type: actionTypes.GET_MATCHES,
//   reqId,
//   token,
//   successCallback,
//   failCallback,
// });
