import { put, all, takeLatest } from "redux-saga/effects";

import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import API from "../../Axios/Axios";

function* getSubscriptionPlansSaga() {
  try {
    yield put({ type: actionTypes.SET_SUBSCRIPTIONS_LOADING });
    const { data } = yield API.get("/subscription/plans");
    if (data.success) {
      yield put({
        type: actionTypes.GET_SUBSCRIPTION_PLANS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_SUBSCRIPTION_PLANS_FAILURE,
        payload: "Unable to load plans",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_SUBSCRIPTION_PLANS_FAILURE,
      payload: error?.response?.data?.message || "Unable to load plans",
    });
  }
}

function* getActiveSubscriptionSaga() {
  try {
    yield put({ type: actionTypes.SET_SUBSCRIPTIONS_LOADING });
    const { data } = yield API.get("/subscription/active");
    if (data.success) {
      yield put({
        type: actionTypes.GET_ACTIVE_SUBSCRIPTION_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_ACTIVE_SUBSCRIPTION_FAILURE,
        payload: "No active subscription",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ACTIVE_SUBSCRIPTION_FAILURE,
      payload: "No active subscription",
    });
  }
}

function* getBillsSaga() {
  try {
    yield put({ type: actionTypes.SET_SUBSCRIPTIONS_LOADING });
    const { data } = yield API.get("/subscription/billing");
    if (data.success) {
      yield put({
        type: actionTypes.GET_BILLS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_BILLS_FAILURE,
        payload: "Unable to fetch Bills",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_BILLS_FAILURE,
      payload: "Unable to fetch Bills",
    });
  }
}

function* upgradeSubscriptionSaga({ planData, successCallback }) {
  try {
    yield put({ type: actionTypes.SET_SUBSCRIPTIONS_LOADING });
    const { data } = yield API.post("/subscription/update", planData);
    if (data.success) {
      yield put({
        type: actionTypes.UPGRADE_SUBSCRIPTION_SUCCESS,
        // payload: data.data,
      });
      if (successCallback) {
        successCallback();
      }
      yield put({ type: actionTypes.GET_ACTIVE_SUBSCRIPTION });
      toast.success("Your subscription is updated successfully.");
    } else {
      yield put({
        type: actionTypes.UPGRADE_SUBSCRIPTION_FAILURE,
        payload: "Unable to subscribe",
      });
      toast.error("Unable to update the subscription.");
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPGRADE_SUBSCRIPTION_FAILURE,
      payload: error?.response?.data?.message || "Unable to subscribe.",
    });
    toast.error("Unable to update the subscription.");
  }
}

function* cancelSubscriptionSaga() {
  try {
    yield put({ type: actionTypes.SET_SUBSCRIPTIONS_LOADING });
    const { data } = yield API.delete("/subscription/cancel");
    if (data.success) {
      yield put({
        type: actionTypes.CANCEL_SUBSCRIPTION_SUCCESS,
        // payload: data.data,
      });
      yield put({
        type: actionTypes.GET_ACTIVE_SUBSCRIPTION,
      });
    } else {
      yield put({
        type: actionTypes.CANCEL_SUBSCRIPTION_FAILURE,
        payload: "Unable to cancel subscription.",
      });
      toast.error("Unable to cancel subscription.");
    }
  } catch (error) {
    yield put({
      type: actionTypes.CANCEL_SUBSCRIPTION_FAILURE,
      payload:
        error?.response?.data?.message || "Unable to cancel subscription.",
    });
    toast.error("Unable to cancel subscription.");
  }
}

function* subscriptionSaga() {
  yield all([
    yield takeLatest(
      actionTypes.GET_SUBSCRIPTION_PLANS,
      getSubscriptionPlansSaga
    ),
    yield takeLatest(
      actionTypes.GET_ACTIVE_SUBSCRIPTION,
      getActiveSubscriptionSaga
    ),
    yield takeLatest(actionTypes.GET_BILLS, getBillsSaga),
    yield takeLatest(actionTypes.UPGRADE_SUBSCRIPTION, upgradeSubscriptionSaga),
    yield takeLatest(actionTypes.CANCEL_SUBSCRIPTION, cancelSubscriptionSaga),
  ]);
}

export default subscriptionSaga;
