import { Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import DoneIcon from "@mui/icons-material/Done";
import React from "react";
import Radio from "@mui/material/Radio";

import { capitalizeWords } from "../../Helpers/functions";
import { plans } from "../../constants";

const useStyles = makeStyles((theme) => ({
  greenBox: {
    paddingBottom: "40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "350px",
    fontFamily:
      "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",
    [theme.breakpoints.down("sm")]: {
      // padding: "25px 10px",
      width: "80%",
    },

    "& h1": {
      textAlign: "center",
      ...theme.typography.heading28,
      fontFamily:
        "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",
      margin: 0,
      [theme.breakpoints.down("lg")]: {
        fontSize: "23px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "27px",
      },
    },
    "& p": {
      textAlign: "center",
      margin: 0,
      marginTop: "10px",
      ...theme.typography.body16,
      color: "#737373",
      fontFamily:
        "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",
      [theme.breakpoints.down("lg")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px",
      },
    },
  },
  plansCard: {
    width: "100%",
    height: "50%",
    marginTop: "7%",
    padding: "20px",
    borderRadius: "12px",
    background: "white",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 3px",
  },
  innerCard: {
    border: "1px solid",
    borderColor: "#e5e5e5",
    fontFamily:
      "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",
    borderRadius: "12px",
    // margin: "10px",
    padding: "10px",
    position: "relative",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      padding: "4px",
      // margin: "8px",
    },
  },

  listPoints: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    gap: 5,
    color: "#737373",
  },
  nextButton: {
    fontSize: "14px !important",
    color: "white !important",
    height: "40px !important",
    backgroundColor: "#528068 !important",
    width: "100% !important",
    borderRadius: "6px !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    fontWeight: "500 !important",
    fontFamily:
      "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",
    "&:hover": {
      background: theme.palette.darkGreen,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "13px !important",

      height: "auto !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
      marginRight: "0px !important",
      // maxWidth: "240px !important",
      height: "40px !important",
    },
  },
  rateBox: {
    marginTop: "5px",
    "& p": {
      color: theme.palette.darkGreen,
      ...theme.typography.heading44,
      "& span": {
        ...theme.typography.body20,
        textTransform: "uppercase",
        letterSpacing: "0.19em",
        color: theme.palette.black,
        [theme.breakpoints.down("sm")]: {
          fontSize: "14px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "34px",
      },
    },
  },
  button: {
    marginTop: "20px !important",
    height: "60px !important",
    width: "300px ",
  },
  sideStrip1: {
    height: "300px",
    background: theme.palette.lightGreen,
    width: "30px",
    position: "absolute",
    top: "70px",
    left: "-30px",
    [theme.breakpoints.down("sm")]: {
      left: "-10px",
      width: "10px",
    },
  },
  sideStrip2: {
    height: "300px",
    background: theme.palette.lightGreen,
    width: "30px",
    position: "absolute",
    bottom: "100px",
    right: "-30px",
    [theme.breakpoints.down("sm")]: {
      right: "-10px",
      width: "10px",
    },
  },
  box2Inactive: {
    opacity: "0.29",
    "& h1": {
      color: theme.palette.darkGreen,
    },
  },
  box2: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: "50px",
    marginBottom: "150px",
    border: "1px solid rgba(64, 130, 102,0.29)",
    padding: "50px  40px",

    "& h1": {
      fontSize: "55px",
      textAlign: "center",
      fontWeight: "700",
      width: "60%",
      margin: "0",
      [theme.breakpoints.down("lg")]: {
        width: "80%",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },

    "& p": {
      ...theme.typography.body24,
      margin: 0,
      textAlign: "center",
      marginTop: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "50px  10px",
      "& h1": {
        fontSize: "36px",
      },
    },
  },
  collapseContainer: {
    marginTop: "20px",
    width: "100%",
  },
  collapseContainerHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h2": {
      fontSize: "32px",
      lineHeight: "34px",
      fontWeight: "700",
      margin: 0,
    },
    "& svg": {
      fontSize: "36px",
    },
  },
  line: {
    borderBottom: "1px solid #D9D9D9",
    marginTop: "10px",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "20px",
    borderBottom: `1px solid ${theme.palette.grey7}`,
    "& p": {
      ...theme.typography.body16,
      margin: 0,
    },
  },

  totalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    "& h2": {
      ...theme.typography.heading24,
      fontWeight: 700,
      fontFamily:
        "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",
    },
  },

  fullScreenLoaderContainer: {
    position: "fixed",
    top: "0px",
    left: "0px",
    zIndex: "10",
    height: "100vh",
    width: "100vw",
    background: theme.palette.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const StepFormNew = ({
  handleNext,
  planDetails,
  planApiData,
  planDetailsState,
  setPlanDetailsState,
}) => {
  const classes = useStyles();
  const [error, setError] = React.useState(false);

  const handleFunc = () => {
    if (planDetailsState === undefined) {
      setError(true);
    } else {
      setError(false);
      handleNext();
    }
  };

  const getPrice = (data) => {
    const result = planApiData.find((item) => item.identifier === data);
    const amount = `$ ${result.price_in_usd.toFixed(2)} per month`;
    return amount;
  };

  return (
    <div className={classes.greenBox}>
      <h1>Loti can get those images removed.</h1>
      <p>
        Select a plan that fits your needs, we can monitor your identity or go a
        step further and have it removed.
      </p>
      <div className={classes.plansCard}>
        <div>
          {plans?.map((item, index) => (
            <div
              className={classes.innerCard}
              key={index}
              onClick={() => setPlanDetailsState(item)}
              style={{
                borderColor: planDetailsState === item ? "green" : "#C7C6C1",
                borderWidth: planDetailsState === item ? 2.8 : 1.8,
                marginTop: (index === 1 || index === 2) && "15px",
              }}
            >
              <Radio
                checked={planDetailsState === item}
                onChange={() => setPlanDetailsState(item)}
                value={item}
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
                color="success"
                sx={{ position: "absolute", top: "4px", right: "4px" }}
              />
              <Box
                sx={{
                  padding: { xs: "7px 22px", sm: "3px 22px " },
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: 600,
                  fontSize: {
                    xs: "15px",
                    sm: "14px",
                    md: "14px",
                  },
                }}
              >
                {capitalizeWords(item.name)}
                {item === planDetails && (
                  <Box
                    sx={{
                      background: "orange",
                      color: "white",
                      padding: "5px",
                      fontSize: { xs: "0.8em", sm: "0.7em" },
                      borderRadius: "5px",
                      marginRight: { xs: "25px", sm: "20px" },
                    }}
                  >
                    Recommended
                  </Box>
                )}
              </Box>
              <div>
                {item.points.map((point, i) => (
                  <Box
                    key={i}
                    className={classes.listPoints}
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "14px",
                      },
                    }}
                  >
                    <DoneIcon color="success" sx={{ fontSize: 17 }} />
                    {point}
                  </Box>
                ))}
                <Box
                  sx={{
                    padding: { xs: "10px 22px", sm: "10px 22px " },
                    fontWeight: 600,
                    fontSize: {
                      xs: "15px",
                      sm: "14px",
                      md: "14px",
                    },
                  }}
                >
                  {getPrice(item.name)}
                  {/* {item.name === planApiData.identifier&& `$ ${parseFloat(planApiData.price_in_usd).toFixed(2)} per month`}    */}
                </Box>
              </div>
            </div>
          ))}
        </div>
        {error && (
          <Box
            sx={{
              textAlign: "center",
              color: "red",
              fontSize: {
                xs: "12px",
                sm: "11px",
                md: "12px",
              },
              margin: { xs: "10px 3px", sm: "10px 0px" },
            }}
          >
            {" "}
            Please select one option
          </Box>
        )}
        <Button
          variant="contained"
          className={classes.nextButton}
          onClick={handleFunc}
          style={{ marginTop: "15px" }}
        >
          Continue to payment
        </Button>
        <Box
          sx={{
            textAlign: "center",
            color: "#737373",
            fontSize: {
              xs: "12px",
              sm: "11px",
              md: "12px",
            },
            margin: { xs: "10px 0px", sm: "10px 5px" },
          }}
        >
          You will be redirected to our payment provider to complete your
          purchase
        </Box>
      </div>
    </div>
  );
};

export default StepFormNew;
