import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getFacesAction } from "../../Store/Matches/actions";
import ImageScan from "../../Assets/Images/image_scan_red.gif";


const useStyles = makeStyles((theme) => ({
  stepUploadContainer: {
    width: "32%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      width: "40%",
    },
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    "& h2": {
      textAlign: "center",
      ...theme.typography.heading28,
      margin: 0,
      marginBottom: "10px",
      [theme.breakpoints.down(1350)]: {
        fontSize: "24.5px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "25px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "23px",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "15px",
        fontSize: "27px",
        lineHeight: "26px",
      },
    },
    "& p": {
      textAlign: "center",
      ...theme.typography.body24,
      lineHeight: "22px",
      color: "#737373 !important",
      fontSize: "16px",
      margin: 0,
      marginBottom: "5px",
      padding: "5px",
      [theme.breakpoints.down("lg")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "10px",
        fontSize: "15px",
        lineHeight: "20px",
      },
    },
  },

  imageLoading: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
    width: "80%",
    height: "70%",
  },

  uppertext: {
    textAlign: "center",
    fontSize: "12px",
    marginBottom: "25px",
    color: "#737373",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10x",
      fontSize: "11px",
    },
  },

  uploadContainer: {
    width: "100%",
  },
  uploadWrap: {
    margin: "16px",
    border: "1px solid #dfdcdc",
    borderRadius: "5px",
    height: "200px",
    [theme.breakpoints.down("lg")]: {
      height: "170px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
    cursor: "pointer",
    background: theme.palette.lightGreen2,
  },
  inner: {
    // padding: "15px",
    background: "white",
  },
  input: {
    display: "none",
  },
  text: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    background: "#F5F5F5 !important",
    "& h3": {
      ...theme.typography.body24,
      // color: theme.palette.lightGreen,
      fontSize: "14px",
      fontWeight: 500,
      [theme.breakpoints.down("md")]: {
        ...theme.typography.body24,
        fontSize: "14px",
      },
      [theme.breakpoints.down("sm")]: {
        ...theme.typography.body24,
        fontSize: "14px",
      },
    },
    "& svg": {
      fontSize: "24px",
      // color: theme.palette.lightGreen,
      marginRight: "5px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "& h2": {
      marginTop: "20px",
      marginBottom: "30px",
    },
  },
  nextButton: {
    fontSize: "20px !important",
    marginTop: "15px !important",
    color: "white !important",
    height: "auto !important",
    backgroundColor: "#408266 !important",
    width: "100% !important",
    boxShadow: "none !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
      marginRight: "0px !important",
      // maxWidth: "240px !important",
      height: "auto !important",
    },
  },
}));

const StepUpload = ({ setProgress, onNext, setUploadedImage }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [image, setImage] = React.useState("");
  // const [error, setError] = React.useState(false);

  const { loading } = useSelector((state) => state.matches);

  const onUpload = (event) => {
    const supportedFileTypes = ["jpeg", "jpg", "png", "webg", "heic"];
    const imageUrl = URL.createObjectURL(event.target.files[0]);
    setImage(imageUrl);
    const filename = event.target.files[0].name;
    const fileType = filename.substring(
      filename.lastIndexOf(".") + 1,
      filename.length
    );
    if (!supportedFileTypes.includes(fileType.toLowerCase())) {
      toast.error("File not supported.");
      setImage("");
      return;
    }
    setUploadedImage(event.target.files[0]);
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    dispatch(
      getFacesAction(
        formData,
        () => {
          onNext(); // earlier did on next button
          // Cookies.set('cookiesImage', 'someValue', { expires: 7 }); // Expires in 7 days

        },
        () => {
          toast.error(
            "We couldn't find your face. Please upload another image."
          );
          setProgress({ key: 2, matchFound: false });
          setImage("");
        }
      )
    );
  };

  // const handleNext = () => {
  //   if (image) {
  //     setError(false);
  //     onNext();
  //   } else setError(true);
  // };

  return (
    <div
      className={classes.stepUploadContainer}
      style={{
        fontFamily:
          "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji",
      }}
    >
      {loading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h2
            style={{
              fontFamily:
                "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji",
            }}
          >
            Uploading your photo...
          </h2>
          <p
            style={{
              fontFamily:
                "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji",
            }}
          >
            Please wait while we scan your photo
          </p>
          <div
            className={classes.imageLoading}
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 3px",
              background: "white",
              borderRadius: "12px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: { xs: "200px", sm: "170px", md: "100%" },
                position: "relative",
              }}
            >
              <img
                src={image}
                style={{
                  width: "100%",
                  height: "100%",
                  filter: "grayscale(100%)",
                  borderRadius: "12px",
                  objectFit: "cover",
                }}
              />
              <img
                src={ImageScan}
                style={{
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
              />
            </Box>

            <Box
              sx={{
                textAlign: "center",
                color: "#737373",
                // margin: "20px 20px",

                fontSize: { xs: "11px", sm: "12px", md: "12.5px" },
                marginTop: "10px",
              }}
            >
              {" "}
              Phone selfies are the perfect sized image.
            </Box>
          </div>
        </div>
      ) : !image ? (
        <>
          <h2
            style={{
              fontFamily:
                "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji",
            }}
          >
            Upload a forward facing profile photo
          </h2>
          <p
            style={{
              fontFamily:
                "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji",
            }}
          >
            Profile images will cover 98% of potential matches. Phone selfies
            are the perfect sized image.
          </p>
          <div className={classes.uppertext}>
            <span> Images are encrypted and never shared with anyone.</span>
          </div>
          <div
            style={{
              background: "white",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 3px",
              width: "100%",
              borderRadius: "12px",
            }}
          >
            <div className={classes.uploadContainer}>
              <input
                className={classes.input}
                type="file"
                accept="image/*"
                id="image-upload"
                name="myImage"
                onChange={onUpload}
              />
              <label htmlFor="image-upload">
                <div className={classes.inner}>
                  <div className={classes.uploadWrap}>
                    <div
                      className={classes.text}
                      style={{
                        fontFamily:
                          "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji",
                      }}
                    >
                      <MdOutlineFileUpload />
                      <h3>Upload photo</h3>
                    </div>
                  </div>

                  {/* <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "0px 20px",
                    }}
                  >
                    <CustomButton
                      // yellow={!inverseButton}
                      onClick={handleNext}
                      className={classes.nextButton}
                    >
                      Next
                    </CustomButton>
                  </Box> */}
                  {/* {error && (
                    <Box
                      sx={{
                        color: "red",
                        fontSize: { xs: "11px", sm: "12px", md: "12px" },
                        textAlign: "center",
                        marginTop: "10px",
                      }}
                    >
                      You must upload an image to continue.
                    </Box>
                  )} */}
                  <Box
                    sx={{
                      textAlign: "center",
                      color: "#737373",
                      margin: "15px 10px",

                      fontSize: { xs: "12px", sm: "12px", md: "12.5px" },
                    }}
                  >
                    Phone selfies are the perfect sized image.
                  </Box>
                </div>
              </label>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h2
            style={{
              fontFamily:
                "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji",
            }}
          >
            Have we scanned the correct face
          </h2>
          <p
            style={{
              fontFamily:
                "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji",
            }}
          >
            Click next to confirm {"we've"} found the correct face.
          </p>
          <div
            className={classes.imageLoading}
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              background: "white",
              fontFamily:
                "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: { xs: "120px", sm: "170px", md: "200px" },
                position: "relative",
              }}
            >
              <img
                src={image}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  borderRadius: "8px",
                }}
              />
            </Box>
            {/* <CustomButton
              // yellow={!inverseButton}
              onClick={handleNext}
              className={classes.nextButton}
            >
              Next
            </CustomButton> */}

            <Box
              sx={{
                textAlign: "center",
                color: "#737373",
                marginTop: "20px",

                fontSize: { xs: "11px", sm: "13px" },
              }}
            >
              {" "}
              Phone selfies are the perfect sized image.
            </Box>
          </div>
        </div>
      )}
    </div>
  );
};

export default StepUpload;
