import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import React from "react";
import CustomPopup from "../UI/CustomPopup.jsx";
import CustomButton from "../UI/CustomButton.jsx";
import { cancelSubscriptionAction } from "../../Store/Subscriptions/actions";

const useStyles = makeStyles((theme) => ({
  cancelPlanContainer: {
    "& .MuiPaper-root": {
      padding: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiPaper-root": {
        padding: "40px 0",
      },
    },
  },
  dialogHead: {
    maxWidth: "300px",
    ...theme.typography.heading24,
    fontWeight: "700",
    margin: 0,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.heading20,
    },
  },
  keepButton: {
    marginTop: "14px !important",
    fontSize: "18px !important",
    fontWeight: "400 !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
    },
  },
  cancelButton: {
    fontSize: "18px !important",
    fontWeight: "400 !important",
    marginTop: "7px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
    },
  },
}));

const CancelPlan = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const
  const onCLick = () => {
    dispatch(cancelSubscriptionAction());
  };

  return (
    <CustomPopup
      open={Boolean(open)}
      onClose={onClose}
      className={classes.cancelPlanContainer}
    >
      <h2 className={classes.dialogHead}>
        Are you sure you want to cancel subscription?
      </h2>

      <CustomButton yellow className={classes.keepButton} onClick={onClose}>
        Keep Plan
      </CustomButton>
      <CustomButton className={classes.cancelButton} onClick={onCLick}>
        Cancel Subscription
      </CustomButton>
    </CustomPopup>
  );
};

export default CancelPlan;
