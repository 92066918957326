import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box';
import { stopProgressLoader } from "../../Store/Matches/actions";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress size={100} variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 1
   */
  value: PropTypes.number.isRequired,
};


export default function CircularStatic() {
  const [progress, setProgress] = React.useState(0);
  const {  progressLoaderValue } = useSelector((state) => state.matches);
  const dispatch = useDispatch();


  React.useEffect(() => {
    if(progress>=100){
        dispatch(stopProgressLoader());
    }
    return () => {};
  }, [progress]);

  React.useEffect(() => {
    setProgress(progress+progressLoaderValue)
    return () => {};
  }, [progressLoaderValue]);

  return <CircularProgressWithLabel sx={{
    color: '#408266'
  }} value={progress} />;
}
