import { makeStyles } from "@mui/styles";
import React from "react";
import CustomButton from "../UI/CustomButton.jsx";
import CustomPopup from "../UI/CustomPopup.jsx";

const useStyles = makeStyles((theme) => ({
  websiteDialogContainer: {
    "& .MuiPaper-root": {
      padding: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiPaper-root": {
        margin: "10px",
      },
      "& .MuiBackdrop-root": {
        backdropFilter: "blur(45px)",
      },
    },
  },
  dialogHead: {
    ...theme.typography.heading24,
    fontWeight: "700",
    margin: 0,
    textAlign: "center",
    "& span": {
      color: theme.palette.yellow,
    },
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.heading20,
    },
  },
  dialogBodyFont: {
    ...theme.typography.body16,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.body14,
    },
  },
  continueButton: {
    fontSize: "18px !important",
    fontWeight: "400 !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
    },
  },
  cancelButton: {
    fontSize: "18px !important",
    fontWeight: "400 !important",
    marginTop: "7px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
    },
  },
}));

const WebsiteDialog = ({ open, onClose }) => {
  const classes = useStyles();

  const handleContinue = () => {
    window.open(open.page_url, "_blank").focus();
    onClose();
  };

  return (
    <CustomPopup
      open={Boolean(open)}
      onClose={onClose}
      className={classes.websiteDialogContainer}
    >
      <h2 className={classes.dialogHead}>
        You are about to leave to{" "}
        <span>
          <br />
          {open.domain}
        </span>
      </h2>
      <p className={classes.dialogBodyFont}>
        Are you sure you want to continue?
      </p>
      <CustomButton
        yellow
        className={classes.continueButton}
        onClick={handleContinue}
      >
        Continue
      </CustomButton>
      <CustomButton className={classes.cancelButton} onClick={onClose}>
        Cancel
      </CustomButton>
    </CustomPopup>
  );
};

export default WebsiteDialog;
