export const SET_OWN_URL_LOADING = "SET_OWN_URL_LOADING";

export const GET_OWN_URLS = "GET_OWN_URLS";
export const GET_OWN_URLS_SUCCESS = "GET_OWN_URLS_SUCCESS";
export const GET_OWN_URLS_FAILURE = "GET_OWN_URLS_FAILURE";

export const ADD_OWN_URL = "ADD_OWN_URL";
export const ADD_OWN_URL_SUCCESS = "ADD_OWN_URL_SUCCESS";
export const ADD_OWN_URL_FAILURE = "ADD_OWN_URL_FAILURE";

export const OWN_URL_TAKEDOWN = "OWN_URL_TAKEDOWN";
export const OWN_URL_TAKEDOWN_SUCCESS = "OWN_URL_TAKEDOWN_SUCCESS";
export const OWN_URL_TAKEDOWN_FAILURE = "OWN_URL_TAKEDOWN_FAILURE";