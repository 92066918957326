import * as actionTypes from "./actionTypes";

const initialState = {
  error: null,
  loading: false,
  token: "",
  userData: null,
  errorMessage: "",
  registerError:"",
  resendEmailSend: false,
  onboardingToken: null,
  isUserLogout: true,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AUTH_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.LOGIN_SUCCESS:
      // localStorage.setItem("token", action.payload);
      // if (action.query.userId) {
      //   window.location.href = `?userid=${action.query.userId}`;
      // } else {
      //   window.location.href = "/";
      // }
      window.location.href = "/";
      return {
        ...state,
        loading: false,
        token: action.payload,
        isUserLogout: false,
      };
    case actionTypes.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case actionTypes.VERIFICATION_PENDING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.REGISTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case actionTypes.VALIDATE_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case actionTypes.FORGOT_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.FORGOT_PASSWORD_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case actionTypes.RESEND_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        resendEmailSend: true,
      };

    case actionTypes.ONBOARD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        onboardingToken: action.payload,
      };
    case actionTypes.ONBOARD_USER_FAILURE:
      return {
        ...state,
        loading: false,
        onboardingToken: null,
        error: true,
        registerError: action.payload,
      };

    case actionTypes.ONBOARD_USER_STEP_2_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
      };

    case actionTypes.ONBOARD_USER_STEP_2_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case actionTypes.RESEND_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        resendEmailSend: false,
      };

    case actionTypes.LOGOUT_SUCCESS:
      localStorage.clear();
      window.location.href = "/";
      return {
        ...state,
        loading: false,
        isUserLogout: true,
      };

    case actionTypes.GET_PROMOTION_CODES_SUCCESS:
      return {
        ...state,
        promoCode: action.payload.data,
      };

    default:
      return state;
  }
};

export default auth;
